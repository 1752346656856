import firebase from "firebase/compat/app"
import "firebase/compat/auth";
import * as LOG from "src/util/SDayslogger"

const auth = firebase.auth()
LOG.debug(`use firebase emulator : ${process.env.REACT_APP_USE_FIREBASE_EMU}`)
if (process.env.REACT_APP_USE_FIREBASE_EMU && process.env.REACT_APP_USE_FIREBASE_EMU === "true") { 
  auth.useEmulator(`http://${process.env.REACT_APP_FIREBASE_EMU_HOST}:9099`)
}

export default auth