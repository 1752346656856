import { Column } from "react-table";
import { ProductActionsCell } from "./ProductActionCell";
import { ProductCustomHeader } from "./ProductCustomHeader";
import { ProductNameCell } from "./ProductNameCell";
import { ProductNumberOfVariant } from "./ProductNumberOfVariant";
import { ProductSelectionCell } from "./ProductSelectionCell";
import { ProductSelectionHeader } from "./ProductSelectionHeader";
import { ProductStatusCell } from "./ProductStatusCell";
import Product from "../../../../../../models/Product";

const productColumns: ReadonlyArray<Column<Product>> = [
  {
    Header: (props) => <ProductSelectionHeader tableProps={props} />,
    id: "selection",
    Cell: ({ ...props }) => (
      <ProductSelectionCell id={props.data[props.row.index].id} />
    ),
  },
  {
    Header: (props) => (
      <ProductCustomHeader
        tableProps={props}
        title="ProductList.Column.ProductName"
        className="min-w-125px"
      />
    ),
    id: "name",
    Cell: ({ ...props }) => (
      <ProductNameCell
        name={props.data[props.row.index].name ?? "-"}
        id={props.data[props.row.index].id}
        stdPrice={props.data[props.row.index].stdPrice}
        imgURL={props.data[props.row.index].coverImg}
      />
    ),
  },
  {
    Header: (props) => (
      <ProductCustomHeader
        tableProps={props}
        title="ProductList.Column.Variant"
        className="min-w-125px"
      />
    ),
    id: "variant",
    Cell: ({ ...props }) => (
      <ProductNumberOfVariant
        numberOfVariant={props.data[props.row.index].variantLength}
      />
    ),
  },
  {
    Header: (props) => (
      <ProductCustomHeader
        tableProps={props}
        title="ProductList.Column.Status"
        className="min-w-125px"
      />
    ),
    id: "status",
    Cell: ({ ...props }) => (
      <ProductStatusCell
        id={props.data[props.row.index].id}
        status={props.data[props.row.index].status}
      />
    ),
  },
  {
    Header: (props) => (
      <ProductCustomHeader
        tableProps={props}
        title="Common.Actions"
        className="text-center min-w-125px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <ProductActionsCell
        id={props.data[props.row.index].id}
        indexId={props.row.index}
        numberOfVariant={props.data[props.row.index].variantLength}
        lastStep={props.data[props.row.index].lastStep}
      />
    ),
  },
];

export { productColumns };
