import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { endOfDay, format, startOfDay, startOfMonth } from "date-fns";
import { getTemplateLanguages } from "../../../../../../db/serviceTemplate";
import Language from "../../../../../models/Language";
import MessagePreview from "./MessagePreviewModal";

type PreviewTemplateModal = {
  isOpen?: boolean;
  onClose?: () => void;
  idTemplate?: string;
};

const PreviewTemplateModal: FC<PreviewTemplateModal> = ({
  isOpen,
  onClose,
  idTemplate,
}) => {
  const { t } = useTranslation();
  const [template, setTemplate] = useState<Language>();
  const [templateLanguage, setTemplateLanguage] = useState<Language[]>();

  useEffect(() => {
    getTemplateLanguages(String(idTemplate)).then((listLanguage) => {
      const newLanguage = listLanguage.map((language) => {
        return language as Language;
      });
      setTemplateLanguage(newLanguage);
    });

    templateLanguage?.map((language, idx) => {
      if (idx === 0) {
        setTemplate(language);
        return 0;
      }
    });
  }, [templateLanguage, idTemplate]);

  return (
    <>
      {isOpen && (
        <Modal
          animation
          centered
          show={isOpen}
          // size="lg"
          scrollable={false}
          onHide={() => onClose && onClose()}
        >
          <Modal.Header closeButton className="bg-light">
            <Modal.Title data-testid="modal-title">
              {t("Campaign.Title.Preview")}
              {t("Template.Title.TemplateContent")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light w-100">
            <MessagePreview template={template} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PreviewTemplateModal;
