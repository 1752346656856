import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InitialState, {FirebaseMessageEvent} from "./EventRedux";
import { NewDataCallback } from "src/api/firebase";

const initialState: InitialState = {
    messageEventListeners: []
}

const EventSlice = createSlice({
    name: FirebaseMessageEvent,
    initialState: initialState,
    reducers: {
        addNewMessageListener: (state, action: PayloadAction<NewDataCallback>) => {
            const exist = state.messageEventListeners.find((listener) => listener===action.payload)
            if (!exist){
                state.messageEventListeners.push(action.payload)
            }
        },
        removeNewMessageListener: (state, action: PayloadAction<NewDataCallback>) => {
            const index = state.messageEventListeners.indexOf(action.payload)
            if (index!==-1)
                state.messageEventListeners.splice(index, 1)
        },

    }
})

export const {addNewMessageListener, removeNewMessageListener} = EventSlice.actions

export default EventSlice.reducer

