import db from "../../db";
import { createRef } from "../../db/connection";
import Message from "../../app/models/Message";
import firebase from 'firebase/compat/app'
import * as Log from "src/util/SDayslogger"

export const subsDRMessage = (
  collabsId: string,
  roomsId: string,
  started: Date
) => {
  const startedTime = firebase.firestore.Timestamp.fromDate(started);
  // const collabRef = createRef("collaborations", collabsId);
  const roomRef = db.collection("collaborations").doc(collabsId).collection("rooms").doc(roomsId);
  Log.info(collabsId, 'collabsId SUDRMESS');
  Log.info(roomsId, 'roomsId SUDRMESS');
  Log.info(startedTime, 'startedTime SUDRMESS');
  Log.info(roomRef, 'roomRef SUDRMESS');

  return (
    db
      .collection("messages")
      .where("room", "==", roomRef)
      .where("updatedAt", ">", startedTime)
      .onSnapshot(
        async (querySnapshot) => {
          //Get Push Notification Message
          querySnapshot.forEach(async (doc) => {
            const NotifMessage = doc.data() as Message;
            if (NotifMessage && NotifMessage.status && NotifMessage.status !== "") {
              // if ( NotifMessage && NotifMessage.status && NotifMessage.status !== "" && NotifMessage.status !== MessageStatus.submitted) {
              // console.log("Notify DeliVery Report Update Data Sent : "+NotifMessage.status+" - "+NotifMessage.textContent);
              // console.log("Notify DeliVery Report Update Data Submitted : "+NotifMessage.status+" - "+NotifMessage.submittedAt);
              // console.log("Notify DeliVery Report Update Data Delivered : "+NotifMessage.status+" - "+NotifMessage.deliveredAt);
              // console.log("Notify DeliVery Report Update Data Read : "+NotifMessage.status+" - "+NotifMessage.readAt);
              NotifMessage.id = doc.id;
              // Triggered event Delivery Report Notification
              window.dispatchEvent(new CustomEvent("DRNotif", {
                detail: {
                  //Event Data Props
                  id: NotifMessage.id,
                  status: NotifMessage.status,
                  message: NotifMessage
                }
              }));
            }
          });
        })
  );
};

export const getByID = async (messagesID: any) => {
  if (!messagesID) {
    return null;
  }
  return await db
    .collection("messages")
    .doc(messagesID)
    .get()
    .then((doc: any) => {
      if (doc.exists) {
        return { id: doc.id, ...doc.data() };
      } else {
        // doc.data() will be undefined in this case
        Log.warning("No such document!");
        return null;
      }
    })
    .catch((error: any) => {
      Log.error("Error getMessageByID : " + error);
      return undefined
    });
};
