import * as account from "../db/serviceAccount";
import Account from "../app/models/Account";
import Company from "../app/models/Company";
import { UserCompanies } from "../app/models/User";
import * as lc from "../app/modules/localstorage/index";
import firebase from "firebase/compat/app";
import * as Log from "src/util/SDayslogger"

export const fetchAccountByCompanyAndChannel = (companyid: string, channel: string) => {
  const dataAccount: Account[] = lc.getItemLC(lc.LCName.AccountList + companyid + "_" + channel);
  if (dataAccount != null) {
    Log.debug(`dataAccount available in local storage, companyid ${companyid};channel ${channel}`);
    return Promise.all(dataAccount);
  } 
  
  Log.debug(`dataAccount with (companyid ${companyid};channel ${channel}) not available in local storage, do query`);
  return account.getAccountByCompanyAndChannel(companyid, channel).then(async (accounts) => {

    const newAccount = await Promise.all(
      accounts.map(async (account) => {
        Log.debug(`dataAccount map account.company.id: ${account.company.id}, companyid: ${companyid}, account.type: ${account.type}, channel: ${channel}`);
        if (account.company.id === companyid && account.type === channel) {
          const company = await account.company?.get();
          if (company !== undefined) {
            account.companyModel = company.data() as Company;
          }
        }
        return account;
      })
    );
    lc.setItemLC(lc.LCName.Account + companyid + "_" + channel, newAccount);
    return newAccount;
  });
  
};

export const fetchByChannel = async (channel: string) => {
  const client = lc.getItemLC(lc.LCName.Client);
  const companyAccess = lc.getItemLC(lc.LCName.CompanyList) as UserCompanies[];
  return account.getAccessibleAccount(client.id, companyAccess, channel).then((accounts) => accounts);
};

export const fetchByClientChannel = (clientID: any) => {
  return account
    .getByClient(clientID)
    .then((accounts) => {
      return accounts;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchWAByClientChannel = (clientID: any) => {
  return account
    .getWAByClient(clientID)
    .then((accounts) => {
      return accounts;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchCompanyMarketplaceAccounts = async (companyid: string, channels: string[]) => {
  return await account.getCompanyAccounts(companyid, channels);
};

export const getAccountByCompanyAndMarketplace = async (companyid: string, marketplace: string) => {
  Log.debug("getAccountByCompanyAndMarketplace", companyid, marketplace);
  return await account.getAccountByCompanyAndChannel(companyid, marketplace).then((account) => {
    Log.debug("accounts : ", account);
    return account;
  });
};
