import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import db from "src/db";
import * as lc from "../../../../../app/modules/localstorage/index";

type ReprintModalProps = {
  orderIDs: string[];
  onClose: () => void;
};

const ReprintModal: React.FC<ReprintModalProps> = ({ orderIDs, onClose }) => {
  const { t } = useTranslation();
  const [printerUrl, setPrinterUrl] = useState<string | null>(null);
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  /**
   * Fetches the printer URL from Firestore, stores it in local storage, and sets it in component state.
   */
  const fetchPrinterUrl = async () => {
    try {
      const url = await db
        .collection("clients")
        .doc(lc.getClientId())
        .collection("api")
        .doc("push")
        .get()
        .then((doc) => doc.data()?.printer?.url);

      if (url) {
        localStorage.setItem("printerUrl", url);
        setPrinterUrl(url);
      }
    } catch (error) {
      console.error("Error fetching printer URL:", error);
    }
  };

  // Fetch printer URL on component mount
  useEffect(() => {
    const storedUrl = localStorage.getItem("printerUrl");

    if (storedUrl) {
      setPrinterUrl(storedUrl);
    } else {
      fetchPrinterUrl();
    }
  }, []);

  /**
   * Handles the reprint operation for a given order ID.
   * Sets the loading state during the reprint process and logs the response or error.
   * @param orderId - The ID of the order to be reprinted.
   */
  const handleReprint = async (orderId: string) => {
    // Set loading state to true for the specific orderId
    setLoadingStates((prev) => ({ ...prev, [orderId]: true }));
    setSuccessMessage(null); // Reset success message on new reprint

    try {
      // Send a POST request to the reprint API endpoint with the order ID
      const response = await axios.post(`${printerUrl}/api/reprint`, {
        orderId: orderId,
      });

      // Log the response data from the reprint API
      console.log(response.data);
      if (response?.status === 200) {
        // Set success message with response?.data?.message
        setSuccessMessage(response?.data?.message);
      }
    } catch (error) {
      // Log any errors encountered during the reprint process
      console.error("Error reprinting order:", error);
    } finally {
      // Reset the loading state to false after the operation is complete
      setLoadingStates((prev) => ({ ...prev, [orderId]: false }));
    }
  };

  return (
    <>
      {/* Modal Backdrop */}
      <div
        className="modal-backdrop show"
        data-testid="reprint-order-modal"
      ></div>

      {/* Modal Content */}
      <div className="modal show d-block">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title">{t("Order.List.Reprint")}</h5>
            </div>

            {/* Modal Body */}
            <div className="modal-body">
              {/* Success Message */}
              {successMessage && (
                <div className="alert alert-success text-center">
                  {successMessage}
                </div>
              )}

              {/* Empty State */}
              {!orderIDs || orderIDs.length === 0 ? (
                <div className="alert alert-info text-center">
                  {t("Order.List.Empty")}
                </div>
              ) : (
                <div
                  className="position-relative"
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {/* Order List */}
                  <ul
                    className="list-group"
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                  >
                    {orderIDs.map((orderID, index) => (
                      <React.Fragment key={index}>
                        <li
                          className="list-group-item d-flex justify-content-start align-items-center"
                          style={{
                            border: "none",
                            padding: "10px 15px",
                          }}
                        >
                          <span
                            className="text-sm"
                            style={{ fontSize: "9.5pt", marginRight: "auto" }}
                          >
                            {orderID}
                          </span>
                          <button
                            onClick={() => handleReprint(orderID)}
                            className="btn btn-primary ms-2"
                            disabled={loadingStates[orderID] || !printerUrl}
                          >
                            {loadingStates[orderID] ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              t("Value.Pack.Reprint")
                            )}
                          </button>
                        </li>
                        {index < orderIDs.length - 1 && (
                          <hr
                            style={{
                              margin: "0",
                              borderTop: "3px solid #e0e0e0",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Modal Footer */}
            <div className="modal-footer">
              <button onClick={onClose} className="btn btn-primary">
                {t("Common.Button.Cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReprintModal;
