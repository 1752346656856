/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../resources/helpers";
import { RootState } from "../../../setup/redux/store";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuButton } from "./AsideMenuButton";
import { useNavigate } from "react-router-dom";
import { UserRoles } from "../../models/User";
import { getItemLC } from "../../modules/localstorage";

export function AsideMenuProfile() {
  const { t } = useTranslation();
  const userData = getItemLC("UserData");
  const countUnreadMessages: number = useSelector(
    (state: RootState) => state.Chat.countTotalUnreadMessages
  );
  const countAllChatUnreadMessages: number = useSelector(
    (state: RootState) => state.Chat.allChatCountTotalUnreadMessages
  );
  const chatState: string = useSelector(
    (state: RootState) => state.Chat.chatState
  );
  const countUserUnreadMessages: number = useSelector(
    (state: RootState) => state.Chat.countTotalUserUnreadMessages
  );
  const countCustomerInQueue: number = useSelector(
    (state: RootState) => state.CIQ.countCIQ
  );
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [userUnreadMessages, setUserUnreadMessages] = useState<number>(0);
  const [customerInQueue, setCustomerInQueue] = useState<number>(0);

  const nav = useNavigate();

  useEffect(() => {
    setUnreadMessages(countUnreadMessages);
    setUserUnreadMessages(countUserUnreadMessages);
    setCustomerInQueue(countCustomerInQueue);
  }, [countUnreadMessages, countCustomerInQueue, countUserUnreadMessages]);

  const backtoMainClickHandler = () => {
    console.log("masuk di profileClickHandler");
    const asideMenuMain = document.getElementById("aside-menu-main");
    asideMenuMain?.classList.remove("d-none");

    //remove display none for menu profile component
    const asideMenuProfile = document.getElementById("aside-menu-setting");
    asideMenuProfile?.classList.add("d-none");

    // const activeTab = document.getElementsByClassName("active");
    nav("/dashboard/");

    // const dashboardPage = document.getElementById("AM_DASHBOARD");
    // dashboardPage?.classList.add("active");
  };

  // console.log("Ini Adalah BB COunt  -> "+countCustomerInQueue);
  return (
    <div
      id="aside-menu-setting"
      className="d-flex flex-column justify-content-between h-100 d-none"
    >
      <div>
        <AsideMenuButton
          bootstrapIcon="bi bi-arrow-left"
          title={t("HeaderBar.Button.Settings")}
          id="AM_PBP"
          onClick={() => {
            backtoMainClickHandler();
          }}
        />
        <AsideMenuItemWithSub
          to="#"
          title={t("WABAAccount.Menu.WhatsAppBusines")}
          bootstrapIcon="bi bi-whatsapp"
        >
          <AsideMenuItem
            to="/setting/waba-account/create/sign-up"
            title={t("WABAAccount.Submenu.AddWhatsAppBusinesAccount")}
            id="WABA_ADD"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/setting/waba-account/list"
            title={t("WABAAccount.Submenu.WhatsAppBusinesAccountList")}
            id="WABA_LIST"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to="#"
          title={t("Marketplace.Menu.Marketplace")}
          bootstrapIcon="bi bi-shop-window"
        >
          <AsideMenuItem
            to="/setting/marketplace/marketplace-list"
            title={t("Marketplace.List")}
            hasBullet={true}
          />
          <AsideMenuItem
            to="#"
            title={t("Marketplace.Shipment")}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to="/setting/api"
          title={t("API.Setting.Menu")}
          bootstrapIcon="bi bi-code-slash"
          bbcolor="dark"
        />
        {userData && userData.role === UserRoles.admin && (
          <AsideMenuItem
            to="/setting/user-management"
            // title={t("Campaign.Column.TemplateLanguage")}
            title={t("UserManagement.Title")}
            id="user-management"
            bootstrapIcon="bi bi-people-fill"
          />
        )}
      </div>
    </div>
  );
}
