import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { sendRequestProduct } from "../../../../../../../api/server/products";
import { ID, toAbsoluteUrl } from "../../../../../../../resources/helpers";
import ModalViewStatusMarketplace from "../../../../../../modules/modal/modalViewStatusMarketplace";
import { Loading } from "../../../../../../modules/util/Loading";
import { useQueryResponseData } from "../../core/QueryResponseProvider";
import { useProductEdit } from "./ProductActionCell";
import * as Log from "src/util/SDayslogger"

type Props = {
  id?: ID;
  status?: string;
};

type InformationCompanyAndMarketplaceProps = {
  company?: any;
  marketplaces?: any;
  productID?: any;
};

const initialMarketplace = {
  shopee: false,
  tokopedia: false,
};

const isMarketplaceEnabled = (key: string, marketplace: any) => {
  return marketplace[key];
};

const toggleMarketplace = (key: string, marketplace: any) => {
  marketplace[key] = !marketplace[key];
  return marketplace;
};

const ProductStatusCell: FC<Props> = ({ id, status }) => {
  const { t } = useTranslation();
  const products = useQueryResponseData();
  const [data, setData] = useState<any>();
  const [dataSuccess, setDataSuccess] = useState<any[]>([]);
  const [dataFailed, setDataFailed] = useState<any[]>([]);
  const [dataPending, setDataPending] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const editProduct = useProductEdit();
  //const dataSuccess:any[] = [];
  // const dataFailed:any[] = [];
  let isShowMarketplace: boolean = false;
  if (status !== "drafted" && status !== "imported") {
    isShowMarketplace = true;
  }
  const getDataProduct = (id: ID) => {
    const getData = products.find((product: any) => {
      return product.id === id;
    });
    return getData;
  };

  // useEffect(() => {
  //   setData(getDataProduct());
  // }, []);

  useEffect(() => {
    const selectedData = getDataProduct(id);
    
    if (selectedData) {
      setData(selectedData);

      let success: any[] = [];
      let failed: any[] = [];
      let pending: any[] = [];
      selectedData?.marketplaceCategory?.map((marketplace: any) => {
        const getMarketplaceProductID = marketplace.marketplaceProductID;
        const jsonResponse = marketplace.responseJSON;
        if (!jsonResponse) {
          pending.push({ account: marketplace.account.id });
        } else {
          if (getMarketplaceProductID) {
            success.push({ account: marketplace.account.id });
            // setDataSuccess([...dataSuccess, {account: data.account.id}])
          } else {
            failed.push({ account: marketplace.account.id });
            // setDataFailed([...dataFailed, {account: data.account.id}])
          }
        }
      });
      setDataFailed(failed);
      setDataSuccess(success);
      setDataPending(pending);
      Log.debug(`useEffect selected product
      product sku ${selectedData.sku}
      product name ${selectedData.name}
      data success #${success.length} failed #${failed?.length ?? 0} pending #${pending?.length}`);
    }
  }, [id, products]);

  return (
    <>
      {isLoading && <Loading />}

      {status === "imported" ? (
        <div className="d-flex text-center justify-content-start text-capitalize">
          <div>{status}</div>
          {data && data.brandModel && !data.brandModel.marketplaceRegistered && (
            <OverlayTrigger
              overlay={<Tooltip>Warning: Product brand not registered</Tooltip>}
            >
              <span
                onClick={async () => {
                  setIsLoading(true);
                  await editProduct(id, data.lastStep, "?focus=brand");
                }}
                className="bi bi-exclamation-circle-fill ms-3 cursor-pointer text-warning"
              />
            </OverlayTrigger>
          )}
        </div>
      ) : (
        <div
          className="text-gray-650 mb-1 cursor-pointer"
          style={{ textTransform: "capitalize" }}
          data-bs-toggle={isShowMarketplace ? "modal" : ""}
          data-bs-target={isShowMarketplace ? "#modalViewStatus-" + id : "#"}
          onClick={async () => {
            if (isShowMarketplace) {
              const getData = getDataProduct(id);
              if (getData) {
                setData(getData);
              }
            }
          }}
        >
          {status === "drafted"
            ? status
            : `${dataSuccess.length} ${t(
                "Common.Modal.Column.Contents.Status.Success"
              )} / ${dataFailed.length} ${t(
                "Common.Modal.Column.Contents.Status.Failed"
              )} / ${dataPending.length} ${t("Campaign.Info.PENDING")}`}
        </div>
      )}

      {isShowMarketplace && (
        <ModalViewStatusMarketplace
          id={"modalViewStatus-" + id}
          title={"Product Status Detail"}
          body={<ViewMarketplaceProduct key={id} data={data} />}
          confirmOption={`${t("Alert.Back.Button.Save")}`}
          cancelOption={`${t("Alert.Button.Cancel")}`}
        />
      )}
    </>
  );
};

// const requestProduct = actions.requestCreateProduct(
//   findAccount[0].id,
//   newProductID,
//   useruid
// );

const ViewMarketplaceProduct = ({ data }: any) => {
  let itemID = data?.marketplaceCategory?.marketplaceProductID;
  // let status = !itemID;// === undefined ? false : true;
  let marketplaces = data?.marketplaceCategory;
  let companies = data?.companies;
  let productID = data?.id;
  // let cek = data?.brand.brandName;
  Log.debug("data isi nya", data);

  return (
    <div className="d-flex flex-column gap-4">
      {companies?.map((company: any) => (
        <InformationCompanyAndMarketplace
          key={`key-${company.id}`}
          company={company}
          marketplaces={marketplaces}
          productID={productID}
        />
      ))}
    </div>
  );
};

const InformationCompanyAndMarketplace: FC<
  InformationCompanyAndMarketplaceProps
> = ({ company, marketplaces, productID }) => {
  const { t } = useTranslation();

  const [requesting, setRequesting] = useState<boolean>(false);
  const [marketplaceChecked, setMarketplaceChecked] = useState<any[]>([]);
  const [checkedCount, setCheckedCount] = useState<number>(0);

  /**
   *
   * @param account
   * @param product
   * @param user
   * @author barox
   */
  const handleRetry = (account: string, product: string, user: string) => {
    console.log("masuk ke retry request product", account, product, user);
    sendRequestProduct(account, product, user);
    setRequesting(true);
  };

  const handleRetrySelected = (marketplaceSelected: any[]) => {
    console.log("masuk ke retry selected request product", marketplaceSelected);
    marketplaceSelected.forEach((request) => {
      sendRequestProduct(request.account, request.product, request.user);
    });
    setRequesting(true);
  };

  const isRequesting = requesting === true;
  useEffect(() => {
    console.log("use effect modal status");
    setTimeout(() => setRequesting(false), 3000);
  }, [isRequesting]);

  const handleCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setCheckedCount(checkedCount + 1);
    } else {
      setCheckedCount(checkedCount - 1);
      setMarketplaceChecked(marketplaceChecked.slice(0, -2));
    }
  };

  return (
    <div className="w-100 border border-line p-3">
      <div className="d-flex flex-row w-100 p-0 align-items-center justify-content-between">
        <div className="px-5 h3">{company.company.id}</div>
        <div>
          <button
            className="btn btn-light btn-active-light-primary btn-sm"
            disabled={marketplaceChecked.length < 1}
            onClick={() => {
              handleRetrySelected(marketplaceChecked);
            }}
          >
            <span className="px-1">{`${t(
              "Common.Modal.Button.RetrySelected"
            )}`}</span>
            <span className="bi bi-arrow-clockwise"></span>
          </button>
        </div>
      </div>
      <table className="w-100">
        <thead>
          <tr className="w-100 text-center">
            <th className="w-25">
              {t("Common.Modal.Column.Header.Marketplace")}
            </th>
            <th className="w-25">{t("ProductList.Column.Status")}</th>
            <th className="w-25">
              {t("Common.Modal.Column.Header.Information")}
            </th>
            <th className="w-25">{t("Common.Modal.Column.Header.Action")}</th>
          </tr>
        </thead>
        <tbody>
          {marketplaces?.map((marketplace: any) => {
            console.log("marketplace : ", marketplace);
            return (
              marketplace?.company?.id === company?.company?.id && (
                <tr key={`marketplace-${marketplace.id}`}>
                  <td
                    className="d-flex gap-2 align-items-center cursor-pointer"
                    key={marketplace.id}
                  >
                    <input
                      id={`checkbox-${marketplace?.id}`}
                      type="checkbox"
                      disabled={marketplace.marketplaceProductID !== undefined}
                      checked={isMarketplaceEnabled(
                        marketplace?.id,
                        marketplaceChecked
                      )}
                      onChange={(e: any) => {
                        let newDataSetter: any = {};
                        // const newData = toggleMarketplace(marketplace?.id, marketplaceChecked)
                        newDataSetter = {
                          account: marketplace.account.id,
                          product: productID,
                          user: marketplace.createdBy.id,
                        };
                        marketplaceChecked.push(newDataSetter);
                        // setMarketplaceChecked([...marketplaceChecked, newDataSetter])
                        handleCheckboxChange(e);
                      }}
                    />
                    <img
                      className="h-20px"
                      src={toAbsoluteUrl(`/media/logos/marketplace/shopee.png`)}
                      alt={"shopee.png"}
                    />
                    <span className="d-block text-capitalize">
                      {marketplace.marketplace}
                    </span>
                  </td>

                  <td className="text-center">
                    {marketplace.responseJSON ? (
                      <span
                        className={clsx(
                          `badge ${
                            marketplace.marketplaceProductID !== undefined
                              ? "badge-light-success"
                              : "badge-light-danger"
                          }`
                        )}
                      >
                        {`${
                          marketplace.marketplaceProductID !== undefined
                            ? t("Common.Modal.Column.Contents.Status.Success")
                            : t("Common.Modal.Column.Contents.Status.Failed")
                        }`}
                      </span>
                    ) : (
                      <span className={clsx(`badge badge-light-warning`)}>
                        {t("Campaign.Info.PENDING")}
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    {marketplace.responseJSON ? marketplace.responseJSON : "-"}
                  </td>
                  <td className="text-center py-0">
                    <button
                      className="btn btn-light btn-active-light-primary btn-sm"
                      disabled={
                        marketplace.marketplaceProductID ||
                        (!marketplace.responseJSON &&
                          !marketplace.marketplaceProductID)
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleRetry(
                          marketplace.account.id,
                          productID,
                          marketplace.createdBy.id
                        )
                      }
                    >
                      <span className="px-1">{`${t(
                        "Common.Modal.Column.Button.Retry"
                      )}`}</span>
                      {requesting && <i className="fas fa-sync fa-spin"></i>}
                      {!requesting && (
                        <span className="bi bi-arrow-clockwise"></span>
                      )}
                    </button>
                  </td>
                </tr>
              )
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export { ProductStatusCell };
