import clsx from "clsx";
import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import * as Chat from "../../../../actions/chat";
import { toAbsoluteUrl, useDebounce } from "../../../../resources/helpers";
import { KTSVG } from "../../../../resources/helpers/components/KTSVG";
import { RootState } from "../../../../setup/redux/store";
import "../../../../styles/css/chat.scss";
import "../../../../styles/css/color.css";
import Customer from "../../../models/Customer";
import * as chat from "../../../modules/chat/redux/ChatSlice";
import * as ReduxContact from "../../../modules/contact/redux/ContactSlice";
import { Loading } from "../../../modules/util/Loading";
import { ContactEditModal } from "../../contact-management/contact-list/contact-edit-modal/ContactEditModal";
import ChatInner from "./ChatInner";
import ContactListModal from "./ContactListModal";
import * as chatUtil from "./chat-util/ChatUtil";
import ContactActionModal from "./modals/ContactAction";
import * as lc from "../../../modules/localstorage/index";
import { realtimedb } from "../../../../db";
import ChatList from "./ChatList";
import { DocumentSnapshot } from "firebase-admin/firestore";
import { Collaboration } from "src/app/models/Chat";
import { ChatType } from "src/app/modules/chat/feature";
import * as Log from "src/util/SDayslogger"

const ChatWrapper: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  let { contactData } = useSelector((state: RootState) => state.Contact);
  let {
    selectedChat,
    selectedRoom,
    selectedCollaboration,
    allChatSelectedChat,
    allChatSelectedRoom,
    allChatSelectedCollaboration,
    chatState,
  } = useSelector((state: RootState) => state.Chat);

  let collaboration =
    chatState === ChatType.mychat
      ? selectedCollaboration
      : allChatSelectedCollaboration;
  let chatSelected =
    chatState === ChatType.mychat ? selectedChat : allChatSelectedChat;
  let roomSelected =
    chatState === ChatType.mychat ? selectedRoom : allChatSelectedRoom;
  let roomsModel = collaboration?.roomsModel?.filter(
    (room: any) => room?.status === "handled"
  );
  let customer_ref = collaboration?.customer;
  let defaultAvatarName = "default-avatar";

  let chatTitle = "";
  if (contactData) {
    if (contactData.firstName && contactData.firstName !== "") {
      chatTitle = contactData?.firstName;
      if (contactData.lastName && contactData.lastName !== "") {
        chatTitle = `${contactData.firstName} ${contactData.lastName}`;
      }
    } else {
      chatTitle = `+${contactData?.phoneNumber}`;
    }
  }

  Log.debug(`ChatWrapper `, chatTitle, contactData);
  let container = document.getElementById("container-button");
  let buttons = container?.querySelectorAll(".btn");
  let bgColorClassActive = "bg-light-success";
  let textColorClassActive = "text-success";
  let bgColorClassInActive = "bg-secondary";
  let textColorClassInActive = "text-muted";

  const [dropdownHB, setDropdownHB] = useState<boolean>(false);
  const [mobileSearch, setMobileSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [notifRoom, setNotifRoom] = useState<any>({});
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [addContactModalOpen, setAddContactModalOpen] =
    useState<boolean>(false);
  const [isAddExistingContactModal, setAddExistingContactModalOpen] =
    useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 150);

  // function dijalankan ketika membuka chat dengan klik chat item di list
  const chatOpenHandler = useCallback(
    (selected_collabs: Collaboration) => {
      if (selected_collabs) {
        if (chatState === ChatType.allchat) {
          dispatch(chat.setAllChatSelectedChat(selected_collabs?.id));
          dispatch(chat.setAllChatSelectedCollaboration(selected_collabs));
          if (selected_collabs?.roomsModel) {
            const matchingChat = selected_collabs?.roomsModel?.find(
              (chat) => chat?.id === roomSelected
            );
            if (matchingChat) {
              dispatch(chat.setAllChatSelectedRoom(roomSelected));
            } else {
              dispatch(
                chat.setAllChatSelectedRoom(selected_collabs?.roomsModel[0]?.id)
              );
            }
          }
          return;
        } else {
          dispatch(chat.setSelectedChat(selected_collabs?.id));
          dispatch(chat.setSelectedCollaboration(selected_collabs));
          if (selected_collabs?.roomsModel) {
            const matchingChat = selected_collabs?.roomsModel?.find(
              (chat) => chat?.id === roomSelected
            );
            if (matchingChat) {
              dispatch(chat.setSelectedRoom(roomSelected));
            } else {
              dispatch(
                chat.setSelectedRoom(selected_collabs?.roomsModel[0]?.id)
              );
            }
          }
          return;
        }
      }
    },
    [chatSelected, roomSelected, chatState]
  );

  // function dijalanan ketika membalas chat atau send message di all chat
  const replyAllChatHandler = useCallback((selected_collabs: Collaboration) => {
    if (selected_collabs) {
      dispatch(chat.setCurrentChatState(ChatType.mychat));
      dispatch(chat.setSelectedCollaboration(selected_collabs));
      dispatch(chat.setSelectedChat(selected_collabs?.id));
      if (selected_collabs?.roomsModel) {
        dispatch(chat.setSelectedRoom(selected_collabs?.roomsModel[0].id));
      }
    }
  }, []);

  // funtcion dijalankan ketika klik button back di view mobile
  const backToChatList = () => {
    dispatch(chat.setAllChatSelectedChat(""));
    dispatch(chat.setAllChatSelectedRoom(""));
    dispatch(chat.setAllChatSelectedCollaboration(undefined));
    dispatch(chat.setSelectedChat(""));
    dispatch(chat.setSelectedRoom(""));
    dispatch(chat.setSelectedCollaboration(undefined));
  };

  // function dijalankan ketika klik tab my chat
  const clickMyChatButton = () => {
    setSearchTerm("");
    dispatch(chat.setCurrentChatState(ChatType.mychat));
    dispatch(chat.setAllChatSelectedChat(""));
    dispatch(chat.setAllChatSelectedRoom(""));
    dispatch(chat.setAllChatSelectedCollaboration(undefined));
    setMobileSearch(false);
  };

  // function dijalankan ketika klik tab all chat
  const clickAllChatButton = () => {
    setSearchTerm("");
    dispatch(chat.setCurrentChatState(ChatType.allchat));
    dispatch(chat.setSelectedChat(""));
    dispatch(chat.setSelectedRoom(""));
    dispatch(chat.setSelectedCollaboration(undefined));
    setMobileSearch(false);
  };

  // function dijalanan ketika save contact
  const handleSaveContact = (contactId?: string) => {
    if (!contactId) {
      return;
    }
    updateSelectedCollabCustomer(collaboration, contactId);
  };

  // function digunakan untuk update data customer di collaboration setelah disave
  const updateSelectedCollabCustomer = async (
    collab?: Collaboration,
    contactId?: string
  ) => {
    if (!collab || !contactId) {
      return;
    }

    setIsLoadingRequest(true);

    await Chat.updateCollaborationCustomer(collab, contactId);

    // const collaboration = await Chat.fetchCollaborationByID(collabId);
    // if (collaboration) {
    //   setCollabs(collaboration);
    // }
    setIsLoadingRequest(false);
  };

  // function dijalankan ketika list tab room melebihi container dan perlu di hide
  const getHiddenButtons = () => {
    let hiddenButtons = [] as any;

    buttons?.forEach((button) => {
      const rect = button.getBoundingClientRect();

      if (container) {
        if (
          rect.left < container?.getBoundingClientRect().left ||
          rect.right > container?.getBoundingClientRect().right
        ) {
          hiddenButtons.push({
            id: button.id,
            buttonText: button?.textContent?.trim(),
          });
        }
      }
    });

    return hiddenButtons;
  };

  // component untuk button add contact mobile view
  const MenuAction = () => {
    return (
      <>
        <button
          data-testid="dropdownMenuAction"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="btn border border-0 p-0 m-0"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen053.svg"
            className="svg-icon-1 m-0"
          />
        </button>

        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li>
            <button
              data-testid="action-addToContacts"
              className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#ContactActionModal"
            >
              {t("HC.Button.AddToContacts")}
            </button>
          </li>
        </ul>
        <ContactActionModal
          addToExisting={() => {
            setAddExistingContactModalOpen((prev) => !prev);
          }}
          createNewContact={() => {
            setAddContactModalOpen((prev) => !prev);
          }}
        />
      </>
    );
  };

  // useeffect digunakan untuk reset redux setiap kali meninggalkan page handled customer
  // useEffect(() => {
  //   dispatch(chat.setAllChatSelectedChat(""));
  //   dispatch(chat.setAllChatSelectedRoom(""));
  //   dispatch(chat.setAllChatSelectedCollaboration(undefined));
  //   dispatch(chat.setSelectedChat(""));
  //   dispatch(chat.setSelectedRoom(""));
  //   dispatch(chat.setSelectedCollaboration(undefined));
  //   dispatch(chat.setCurrentChatState(ChatType.mychat));
  // }, [location, window.location.href]);

  // useEffect digunakan untuk set data contact di redux
  useEffect(() => {
    if (collaboration && collaboration?.customerModel) {
      if (contactData?.id !== collaboration?.customerModel?.id) {
        dispatch(ReduxContact.setContactData(collaboration?.customerModel));
      }
      if (!contactData) {
        dispatch(ReduxContact.setContactData(collaboration?.customerModel));
      }
    }
    if (collaboration && !collaboration.customerModel) {
      dispatch(ReduxContact.setContactData({} as Customer));
    }
  }, [collaboration]);

  // useeffect digunakan untuk counter bubble by room tab
  useEffect(() => {
    if (!collaboration || !collaboration.id) return;

    const path = `/collaborations/${collaboration.id}`;
    const dataRef = realtimedb.ref(path);

    // Fungsi untuk menghitung pesan yang belum dibaca
    const countNotifByRoom = () => {
      const handleData = (snapshot: any) => {
        const data = snapshot.val();
        if (data) {
          setNotifRoom(data);
        }
      };

      // Menambahkan listener
      dataRef.on("value", handleData);

      // Mengembalikan fungsi untuk menghentikan listener
      return () => dataRef.off("value", handleData);
    };

    // Memanggil countNotifByRoom dan mendapatkan fungsi pembersihan
    const cleanup = countNotifByRoom();

    // Mengembalikan fungsi pembersihan saat komponen di-unmount atau collaboration berubah
    return cleanup;
  }, [collaboration]);

  if (!isTabletOrMobile) {
    //desktop
    return (
      <>
        <div
          className="card d-flex flex-column flex-lg-row h-100 w-100"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8.125px",
            overflow: "hidden",
          }}
        >
          {/*** Chat List (Sebelah Kiri) */}
          <div
            className="flex-column flex-lg-row mb-10 mb-lg-0 col-4"
            style={{
              borderStyle: "solid",
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderBottomWidth: 0,
              borderRightWidth: "0.1px",
              borderColor: "rgba(161, 165, 183, 0.5)",
            }}
          >
            <div
              className="card card-flush"
              style={{ height: "100%", backgroundColor: "white" }}
            >
              {/* header chat list  */}
              <div
                className="d-flex flex-column"
                style={{
                  height: "100px",
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: "0.1px",
                  borderColor: "rgba(161, 165, 183, 0.5)",
                }}
              >
                {/* chat search  */}
                <div className="p-0 m-2 pb-0 mb-0" id="kt_chat_contacts_header">
                  <form
                    className="w-100 m-0 p-0 position-relative"
                    autoComplete="off"
                  >
                    <div className="position-relative p-0 m-0">
                      <KTSVG
                        path="/media/icons/duotune/general/gen021.svg"
                        className="svg-icon-4 svg-icon-lg-4 svg-icon-gray-500 position-absolute top-50 start-0 translate-middle-y ms-2"
                      />
                      <input
                        id="search-chat"
                        type="text"
                        className="form-control form-control-solid p-4 m-0 bg-light-secondary ps-9"
                        name="search"
                        placeholder={t("HC.Input.Search")}
                        onChange={(e) => {
                          const searchCustomer = e.target.value;
                          setSearchTerm(searchCustomer);
                        }}
                        value={searchTerm}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.keyCode === 13) {
                            e.preventDefault();
                            return false;
                          }
                        }}
                        style={{ height: "100%" }} // Set height to 100% to match container
                      />
                    </div>
                  </form>
                </div>

                {/* chat tab  */}
                <div
                  className="d-flex flex-row align-items-center justify-content-start m-3 pb-2 mb-0 col-gap-1"
                  style={{
                    width: "auto",
                  }}
                >
                  <div
                    onClick={clickMyChatButton}
                    className={`cursor-pointer rounded-pill d-flex flex-row align-items-center justify-content-center py-2 px-4 ${
                      chatState === ChatType.mychat
                        ? bgColorClassActive
                        : bgColorClassInActive
                    }`}
                    style={{
                      borderStyle: "solid",
                      borderWidth: "0.5px",
                      borderColor:
                        chatState === ChatType.mychat ? "#50CD89" : "#A1A5B7",
                    }}
                  >
                    <p
                      className={`p-0 m-0 fw-bolder ${
                        chatState === ChatType.mychat
                          ? textColorClassActive
                          : textColorClassInActive
                      }`}
                    >
                      {t("HC.Button.MyChats")}
                    </p>
                  </div>
                  <div
                    onClick={clickAllChatButton}
                    className={`cursor-pointer rounded-pill d-flex flex-row align-items-center justify-content-center py-2 px-4 ${
                      chatState === ChatType.allchat
                        ? bgColorClassActive
                        : bgColorClassInActive
                    }`}
                    style={{
                      borderStyle: "solid",
                      borderWidth: "0.5px",
                      borderColor:
                        chatState === ChatType.allchat ? "#50CD89" : "#A1A5B7",
                    }}
                  >
                    <p
                      className={`p-0 m-0 fw-bolder ${
                        chatState === ChatType.allchat
                          ? textColorClassActive
                          : textColorClassInActive
                      }`}
                    >
                      {t("HC.Button.AllChats")}
                    </p>
                  </div>
                </div>
              </div>

              {/* body chat list */}
              <ChatList
                type={chatState}
                filter={searchTerm}
                onChatOpen={chatOpenHandler}
              />
            </div>
          </div>

          {/*** Chat Inner Message (Sebelah Kanan) */}
          {collaboration && chatSelected ? (
            <div
              className="w-100 d-flex flex-column align-items-center justify-content-between"
              id="kt_chat_messenger"
            >
              {/* Header Inner */}
              <div
                id="kt_chat_messenger_header"
                className="d-flex flex-column p-3 m-0 w-100"
                style={{
                  height: "100px",
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: "0.1px",
                  borderColor: "rgba(161, 165, 183, 0.5)",
                }}
              >
                <div className="col-12 p-0 m-0">
                  <div className="d-flex justify-content-between p-0 m-0 w-100">
                    <div className="d-flex flex-row h-auto p-0 m-0">
                      <span
                        className="symbol symbol-35px symbol-circle d-flex align-items-center py-0 my-0"
                        style={{ marginRight: "5px", paddingTop: 0 }}
                      >
                        {collaboration && (
                          <img
                            alt={
                              collaboration?.customerModel
                                ? `${collaboration?.customerModel.firstName} ${collaboration?.customerModel.lastName}`
                                : `${defaultAvatarName}`
                            }
                            src={chatUtil.avatarUrl(
                              collaboration?.customerModel
                            )}
                          />
                        )}
                      </span>
                      {collaboration && (
                        <div className="h-auto d-flex justify-content-center flex-column me-1">
                          {contactData && contactData?.id && (
                            <Link
                              to={`/contact/contact-detail/overview/${contactData?.id}`}
                              state={{
                                id: contactData.id,
                                name:
                                  contactData.firstName! +
                                  (contactData.lastName
                                    ? " " + contactData.lastName
                                    : ""),
                              }}
                              className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1 h-auto"
                            >
                              {chatTitle}
                            </Link>
                          )}

                          {(!contactData || !contactData.id) && (
                            <div className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1 h-auto">
                              {collaboration.profileName ??
                                collaboration.phoneNumber}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="card-toolbar p-0">
                      {collaboration && (
                        <div className="me-1">
                          {contactData && contactData?.id ? (
                            <Link
                              className="btn btn-primary p-2"
                              to={`/contact/contact-detail/settings/${contactData?.id}`}
                              state={{
                                id: contactData.id,
                                name:
                                  contactData.firstName! +
                                  (contactData.lastName
                                    ? " " + contactData.lastName
                                    : ""),
                              }}
                            >
                              <span>
                                <i className="bi bi-pencil-fill me-1"></i>
                                {t("HC.Button.EditContact")}
                              </span>
                            </Link>
                          ) : (
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-primary p-2 dropdown-toggle"
                                data-bs-toggle="dropdown"
                                data-bs-display="static"
                                aria-expanded="false"
                              >
                                <span>
                                  <i className="bi bi-pencil-fill me-1"></i>
                                  {t("HC.Button.AddToContacts")}
                                </span>
                              </button>
                              <ul className="dropdown-menu dropdown-menu-lg-end">
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      setAddContactModalOpen((prev) => !prev);
                                    }}
                                  >
                                    {t("HC.Button.AddToContacts.New")}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      setAddExistingContactModalOpen(
                                        (prev) => !prev
                                      );
                                    }}
                                  >
                                    {t("HC.Button.AddToContacts.Existing")}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {collaboration && roomsModel?.length !== 0 && (
                    <div className="m-0 p-0 pt-1 col-12 w-95 d-flex flex-row align-items-center justify-content-between">
                      <div
                        id="container-button"
                        className="d-flex flex-row p-0 m-0 pt-2 w-[95%]"
                        style={{
                          overflowX: "auto",
                        }}
                      >
                        {roomsModel?.map((item: any) => {
                          Log.debug("room ", item)
                          let channel = item?.accountData?.type;
                          let bubble = notifRoom[channel]
                            ? notifRoom[channel].unreadMessages
                            : 0;
                          if (
                            item?.accountsModel !== null &&
                            item.accountData
                          ) {
                            return (
                              <button
                                id={item.id}
                                className={
                                  "rounded-pill d-flex align-items-center justify-content-start m-0 py-2 px-3 me-2 position-relative"
                                }
                                style={{
                                  backgroundColor:
                                    item.id === roomSelected
                                      ? "#dff5ff"
                                      : "#e4e6ef",
                                  border:
                                    item.id === roomSelected
                                      ? "0.5px solid #009ef7"
                                      : "0.5px solid #a1a5b7",
                                  color:
                                    item.id === roomSelected
                                      ? "#009ef7"
                                      : "#a1a5b7",
                                  fontWeight: 600,
                                }}
                                key={item.id}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  Log.debug(`room ${chatState} selected id ${item?.id}`)
                                  if (chatState === ChatType.mychat) {
                                    dispatch(chat.setSelectedRoom(item?.id));
                                  } else {
                                    dispatch(
                                      chat.setAllChatSelectedRoom(item?.id)
                                    );
                                  }
                                }}
                              >
                                {bubble > 0 && (
                                  <p
                                    className="badge text-white p-0 m-0 position-absolute"
                                    style={{
                                      top: "-5px",
                                      right: "-5px",
                                      minWidth: "20px",
                                      minHeight: "20px",
                                      lineHeight: "20px",
                                      borderRadius: "50%",
                                      textAlign: "center",
                                      backgroundColor: "#ff0000",
                                      color: "#ffffff",
                                      zIndex: 300,
                                    }}
                                  >
                                    {bubble}
                                  </p>
                                )}
                                <img
                                  className="me-1 my-0 py-0"
                                  src={toAbsoluteUrl(
                                    `/media/icons/channel/${
                                      item?.accountData?.type === "shopee"
                                        ? "shopee-icon"
                                        : item?.accountData?.type ===
                                          "tokopedia"
                                        ? "tokped-icon"
                                        : item?.accountData?.type
                                    }.png`
                                  )}
                                  alt={(() => {
                                    const accountType = item?.accountData?.type;
                                    if (accountType === "shopee") {
                                      return "shopee-icon";
                                    } else if (accountType === "tokopedia") {
                                      return "tokped-icon";
                                    } else {
                                      return accountType;
                                    }
                                  })()}
                                  height={20}
                                />
                                <p
                                  className="py-0 my-0 mx-0 text-truncate fs-6"
                                  style={{
                                    maxWidth: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.accountData?.name
                                    ? item?.accountData?.name
                                    : item?.accountData?.businessName
                                    ? item?.accountData?.businessName
                                    : item?.accountData?.type}
                                </p>
                              </button>
                            );
                          }
                        })}
                      </div>
                      {getHiddenButtons().length !== 0 && (
                        <div className="w-[5%] align-self-center relative m-0 p-0 align-items-center justify-content-center dropdown">
                          <button
                            className="btn btn-transperent mx-1 m-0 p-0 dropdown-toggle"
                            onClick={() => {
                              setDropdownHB(!dropdownHB);
                            }}
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="bi bi-three-dots-vertical fs-2 text-black m-0 p-0"></i>
                          </button>
                          {dropdownHB === true && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "200px",
                                backgroundColor: "white",
                                border: "0.5px solid gray",
                                borderRadius: "10px",
                                zIndex: 200,
                                right: 0,
                              }}
                              className="dropdown-menu"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDropdownHB(!dropdownHB);
                              }}
                            >
                              {getHiddenButtons()?.map((items: any) => {
                                return (
                                  <p className="dropdown-item text-black">
                                    {items.buttonText}
                                  </p>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* Body Inner */}
              {chatState === ChatType.mychat && (
                <ChatInner
                  type={chatState}
                  customer={
                    contactData ? contactData : collaboration?.customerModel
                  }
                  collaborations={collaboration}
                />
              )}
              {chatState === ChatType.allchat && (
                <ChatInner
                  type={chatState}
                  customer={
                    contactData ? contactData : collaboration?.customerModel
                  }
                  collaborations={collaboration}
                  handleAllChat={replyAllChatHandler}
                />
              )}
            </div>
          ) : (
            <div className="d-flex flex-column row-gap-5 h-100 w-100 p-0 m-0 align-items-center justify-content-center">
              <img
                width={150}
                height={150}
                src={toAbsoluteUrl(
                  "/media/illustrations/sketchy-1/AssetPwEntry.png"
                )}
                alt="icon"
              />
              <div className="d-flex flex-column align-items-center justify-content-center">
                <p className="text-muted fs-2 fw-bolder p-0 m-0">7Days Chat</p>
                <p className="text-muted fs-6 p-0 m-0 w-75 text-center pt-2">
                  Handle send and receive messages from all customer on other
                  marketplaces.
                </p>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center fw-bolder">
                <p className="text-muted fs-6 p-0 m-0 text-center pt-2">
                  ⓘ please select chat to start handle chat
                </p>
              </div>
            </div>
          )}
        </div>

        {addContactModalOpen && (
          <ContactEditModal
            phoneNumber={collaboration?.phoneNumber}
            onClose={() => setAddContactModalOpen(false)}
            onSave={handleSaveContact}
          />
        )}

        {isAddExistingContactModal && (
          <ContactListModal
            phoneNumber={collaboration?.phoneNumber}
            onClose={() => setAddExistingContactModalOpen(false)}
          />
        )}

        {isLoadingRequest ? <Loading /> : null}
      </>
    );
  } else {
    // mobile
    return (
      <>
        <div
          className="card d-flex flex-column w-100"
          style={{
            backgroundColor: "#fff",
            borderRadius: "8.125px",
            height: "86vh",
            overflow: "hidden",
          }}
        >
          {collaboration && chatSelected ? (
            // chat inner message
            <div
              className="w-100 h-100 d-flex flex-column align-items-center justify-content-between"
              id="kt_chat_messenger"
              style={{
                overflowY: "scroll",
              }}
            >
              {/* Header Inner */}
              <div
                id="kt_chat_messenger_header"
                className="d-flex flex-column p-2 m-0 w-100"
                style={{
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: "0.1px",
                  borderColor: "rgba(161, 165, 183, 0.5)",
                }}
              >
                <div className="w-100 d-flex flex-column p-0 m-0">
                  <div className="d-flex flex-row align-items-center justify-content-between p-0 m-0 w-100 gap-2">
                    <svg
                      onClick={backToChatList}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 15 15"
                    >
                      <path
                        fill="#009ef7"
                        d="M6.707 2.293a1 1 0 0 0-1.414 0l-4.5 4.5a1 1 0 0 0 0 1.414l4.5 4.5a1 1 0 0 0 1.414-1.414L4 8.5h9.5a1 1 0 0 0 0-2H4l2.707-2.707a1 1 0 0 0 0-1.414Z"
                      />
                    </svg>
                    <div
                      className="d-flex flex-row h-auto p-0 m-0"
                      style={{ width: "85%" }}
                    >
                      <span
                        className="symbol symbol-35px symbol-circle d-flex align-items-center py-0 my-0"
                        style={{ marginRight: "5px", paddingTop: 0 }}
                      >
                        {collaboration && (
                          <img
                            alt={
                              collaboration?.customerModel
                                ? `${collaboration?.customerModel.firstName} ${collaboration?.customerModel.lastName}`
                                : `${defaultAvatarName}`
                            }
                            src={chatUtil.avatarUrl(
                              collaboration?.customerModel
                            )}
                          />
                        )}
                      </span>
                      {collaboration && (
                        <div className="h-auto d-flex justify-content-center flex-column me-1">
                          {contactData && contactData.id && (
                            <Link
                              to={`/contact/contact-detail/overview/${contactData?.id}`}
                              state={{
                                id: contactData.id,
                                name:
                                  contactData.firstName! +
                                  (contactData.lastName
                                    ? " " + contactData.lastName
                                    : ""),
                              }}
                              className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1 h-auto"
                            >
                              {chatTitle}
                            </Link>
                          )}

                          {(!contactData || !contactData.id) && (
                            <div className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1 h-auto">
                              {collaboration.profileName ??
                                collaboration.phoneNumber}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <MenuAction />
                    {/* <div className="card-toolbar p-0">
                        {collaboration && (
                          <div className="me-n3">
                            {contactData && contactData.id && (
                              <Link
                                className="btn btn-primary"
                                to={`/contact/contact-detail/settings/${contactData?.id}`}
                                state={{
                                  id: contactData.id,
                                  name:
                                    contactData.firstName! +
                                    (contactData.lastName
                                      ? " " + contactData.lastName
                                      : ""),
                                }}
                              >
                                {contactData.firstName !== undefined ? (
                                  <span>
                                    <i className="bi bi-pencil-fill fs-4 me-2"></i>
                                    {t("HC.Button.EditContact")}
                                  </span>
                                ) : (
                                  <span>
                                    <i className="bi bi-pencil-fill"></i>
                                    {t("HC.Button.AddToContacts")}
                                  </span>
                                )}
                              </Link>
                            )}

                            {(!contactData || !contactData.id) && (
                              <div className="btn-group me-3">
                                <button
                                  type="button"
                                  className="btn btn-primary p-2 dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  data-bs-display="static"
                                  aria-expanded="false"
                                >
                                  <span>
                                    <i className="bi bi-pencil-fill"></i>
                                    {t("HC.Button.AddToContacts")}
                                  </span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-lg-end">
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() => {
                                        setAddContactModalOpen((prev) => !prev);
                                      }}
                                    >
                                      {t("HC.Button.AddToContacts.New")}
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() => {
                                        setAddExistingContactModalOpen(
                                          (prev) => !prev
                                        );
                                      }}
                                    >
                                      {t("HC.Button.AddToContacts.Existing")}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        )}
                      </div> */}
                  </div>
                  {collaboration && roomsModel?.length !== 0 && (
                    <div className="m-0 p-0 pt-2 col-12 w-95 d-flex flex-row align-items-center justify-content-between">
                      <div
                        id="container-button"
                        className="d-flex flex-row p-0 m-0 w-[95%]"
                        style={{
                          overflow: "auto",
                        }}
                      >
                        {roomsModel?.map((item: any) => {
                          if (
                            item?.accountsModel !== null &&
                            item.accountData
                          ) {
                            return (
                              <button
                                id={item.id}
                                className={
                                  "rounded-pill d-flex align-items-center justify-content-start m-0 py-1 px-3 me-2"
                                }
                                style={{
                                  backgroundColor:
                                    item.id === roomSelected
                                      ? "#dff5ff"
                                      : "#e4e6ef",
                                  border:
                                    item.id === roomSelected
                                      ? "0.5px solid #009ef7"
                                      : "0.5px solid #a1a5b7",
                                  color:
                                    item.id === roomSelected
                                      ? "#009ef7"
                                      : "#a1a5b7",
                                  fontWeight: 600,
                                }}
                                key={item.id}
                                onClick={async () => {
                                  await chatOpenHandler(
                                    collaboration as Collaboration
                                  );
                                }}
                              >
                                <img
                                  className="me-1 my-0 py-0"
                                  src={toAbsoluteUrl(
                                    `/media/icons/channel/${
                                      item?.accountData?.type === "shopee"
                                        ? "shopee-icon"
                                        : item?.accountData?.type ===
                                          "tokopedia"
                                        ? "tokped-icon"
                                        : item?.accountData?.type
                                    }.png`
                                  )}
                                  alt={(() => {
                                    const accountType = item?.accountData?.type;
                                    if (accountType === "shopee") {
                                      return "shopee-icon";
                                    } else if (accountType === "tokopedia") {
                                      return "tokped-icon";
                                    } else {
                                      return accountType;
                                    }
                                  })()}
                                  height={20}
                                />
                                <p
                                  className="py-0 my-0 mx-0 text-truncate fs-6"
                                  style={{
                                    maxWidth: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.accountData?.name
                                    ? item?.accountData?.name
                                    : item?.accountData?.businessName
                                    ? item?.accountData?.businessName
                                    : item?.accountData?.type}
                                </p>
                              </button>
                            );
                          }
                        })}
                      </div>
                      {getHiddenButtons().length !== 0 && (
                        <div className="w-[5%] align-self-center relative m-0 p-0 align-items-center justify-content-center dropdown">
                          <button
                            className="btn btn-transperent mx-1 m-0 p-0 dropdown-toggle"
                            onClick={() => {
                              setDropdownHB(!dropdownHB);
                            }}
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="bi bi-three-dots-vertical fs-2 text-black m-0 p-0"></i>
                          </button>
                          {dropdownHB === true && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "200px",
                                backgroundColor: "white",
                                border: "0.5px solid gray",
                                borderRadius: "10px",
                                zIndex: 200,
                                right: 0,
                              }}
                              className="dropdown-menu"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDropdownHB(!dropdownHB);
                              }}
                            >
                              {getHiddenButtons()?.map((items: any) => {
                                return (
                                  <p className="dropdown-item text-black">
                                    {items.buttonText}
                                  </p>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* Body Inner */}
              {chatState === ChatType.mychat && (
                <ChatInner
                  type={chatState}
                  customer={
                    contactData ? contactData : collaboration?.customerModel
                  }
                  collaborations={collaboration}
                />
              )}
              {chatState === ChatType.allchat && (
                <ChatInner
                  type={chatState}
                  customer={
                    contactData ? contactData : collaboration?.customerModel
                  }
                  collaborations={collaboration}
                  handleAllChat={replyAllChatHandler}
                />
              )}
            </div>
          ) : (
            // chat list message
            <div
              className="d-flex flex-column w-100 h-100"
              style={{
                overflowY: "scroll",
              }}
            >
              {/* header chat list  */}
              <div
                className="d-flex flex-column"
                style={{
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: "0.1px",
                  borderColor: "rgba(161, 165, 183, 0.5)",
                }}
              >
                {/* chat search  */}
                <div className="p-0 m-0 mx-3" id="kt_chat_contacts_header">
                  {mobileSearch && (
                    <form
                      className="w-100 m-0 p-0 position-relative"
                      autoComplete="off"
                    >
                      <div className="position-relative p-0 m-0 mt-2">
                        <input
                          id="search-chat"
                          type="text"
                          className="form-control form-control-solid p-4 m-0 bg-light-secondary ps-4"
                          name="search"
                          placeholder={t("HC.Input.Search")}
                          onChange={(e) => {
                            const searchCustomer = e.target.value;
                            setSearchTerm(searchCustomer);
                          }}
                          value={searchTerm}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.keyCode === 13) {
                              e.preventDefault();
                              return false;
                            }
                          }}
                          style={{ height: "100%", borderRadius: 200 }} // Set height to 100% to match container
                        />
                      </div>
                    </form>
                  )}
                </div>

                {/* chat tab  */}
                <div className="d-flex flex-row align-items-center justify-content-between mx-3">
                  <div className="d-flex flex-row align-items-center justify-content-start my-2 col-gap-1">
                    <div
                      onClick={clickMyChatButton}
                      className={`cursor-pointer rounded-pill d-flex flex-row align-items-center justify-content-center py-2 px-4 ${
                        chatState === ChatType.mychat
                          ? bgColorClassActive
                          : bgColorClassInActive
                      }`}
                      style={{
                        borderStyle: "solid",
                        borderWidth: "0.5px",
                        borderColor:
                          chatState === ChatType.mychat ? "#50CD89" : "#A1A5B7",
                      }}
                    >
                      <p
                        className={`p-0 m-0 fw-bolder ${
                          chatState === ChatType.mychat
                            ? textColorClassActive
                            : textColorClassInActive
                        }`}
                      >
                        {t("HC.Button.MyChats")}
                      </p>
                    </div>
                    <div
                      onClick={clickAllChatButton}
                      className={`cursor-pointer rounded-pill d-flex flex-row align-items-center justify-content-center py-2 px-4 ${
                        chatState === ChatType.allchat
                          ? bgColorClassActive
                          : bgColorClassInActive
                      }`}
                      style={{
                        borderStyle: "solid",
                        borderWidth: "0.5px",
                        borderColor:
                          chatState === ChatType.allchat
                            ? "#50CD89"
                            : "#A1A5B7",
                      }}
                    >
                      <p
                        className={`p-0 m-0 fw-bolder ${
                          chatState === ChatType.allchat
                            ? textColorClassActive
                            : textColorClassInActive
                        }`}
                      >
                        {t("HC.Button.AllChats")}
                      </p>
                    </div>
                  </div>
                  <svg
                    onClick={() => {
                      setMobileSearch(!mobileSearch);
                    }}
                    className="me-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 42 42"
                  >
                    <path
                      fill="#a1a5b7"
                      d="M1 17.838c0 8.747 7.131 15.827 15.94 15.827c8.796 0 15.938-7.08 15.938-15.827S25.736 2 16.94 2C8.131 2 1 9.091 1 17.838zm5.051 0c0-5.979 4.868-10.817 10.89-10.817c6.01 0 10.888 4.839 10.888 10.817c0 5.979-4.878 10.818-10.888 10.818c-6.022 0-10.89-4.84-10.89-10.818zm22.111 14.523l6.855 7.809c1.104 1.102 1.816 1.111 2.938 0l2.201-2.181c1.082-1.081 1.149-1.778 0-2.921l-7.896-6.775l-4.098 4.068z"
                    />
                  </svg>
                </div>
              </div>

              {/* body chat list */}
              <ChatList
                type={chatState}
                filter={searchTerm}
                onChatOpen={chatOpenHandler}
              />
            </div>
          )}
        </div>

        {addContactModalOpen && (
          <ContactEditModal
            phoneNumber={collaboration?.phoneNumber}
            onClose={() => setAddContactModalOpen(false)}
            onSave={handleSaveContact}
          />
        )}

        {isAddExistingContactModal && (
          <ContactListModal
            phoneNumber={collaboration?.phoneNumber}
            onClose={() => setAddExistingContactModalOpen(false)}
          />
        )}

        {isLoadingRequest ? <Loading /> : null}
      </>
    );
  }
};

export default ChatWrapper;
