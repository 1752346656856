import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
// import App from './App';
import { AppRoutes } from "./app/routes/AppRoutes";
import reportWebVitals from "./reportWebVitals";
import "./setup/translations/i18next";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./resources/assets/sass/style.scss";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "../src/setup/redux/store";
import { SENTRY_RELEASE } from "./Config";
import GlobalContextProvider from "./context/globalContext";
import { ToastContainer } from "react-toastify";
import * as Log from "src/util/SDayslogger"

// firebases.firestore();

Sentry.setContext("User", {
  name: "Bryan Test",
  noHP: "081XXXXXXXXX",
  session: "abcakrlqwrhiqwrhietheittiuewityeiwytiw",
});

//https://71472c638ab145dd8e1d8ed5ff042d87@o1218588.ingest.sentry.io/6360592
Sentry.init({
  // dsn: "https://71472c638ab145dd8e1d8ed5ff042d87@o1218588.ingest.sentry.io/6360592",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  autoSessionTracking: true,
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        title: "Sepertinya anda sedang mengalami kendala.",
        subtitle: "Tim kami akan segera menangani.",
        subtitle2:
          "Sampaikan kendala atau masalah yang anda alami di bawah ini.",
        labelName: "Nama",
        labelComments: "Apa kendala anda?",
        labelClose: "Tutup",
        labelSubmit: "Kirim",
      });
    }
    return event;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // release: 'Testing-Sentry@',
  release: SENTRY_RELEASE,
});

axios.defaults.timeout = parseInt(
  process.env.REACT_APP_REQUEST_TIMEOUT ?? "5000"
);

const debug: boolean =
  process.env.REACT_APP_DEBUG !== undefined &&
  process.env.REACT_APP_DEBUG.toString().toLowerCase() === "true";

  Log.debug(`Env DEBUG ${process.env.REACT_APP_DEBUG} DEBUG: ${debug}`);

if (process.env.NODE_ENV !== "development" && !debug) {
  console.log(`log removed`);
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

export const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <GlobalContextProvider>
      <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AppRoutes />
              <ToastContainer
                bodyStyle={{ fontFamily: "Poppins", fontWeight: 400 }}
              />
            </PersistGate>
          </Provider>
        </Suspense>
      </React.StrictMode>
    </GlobalContextProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();