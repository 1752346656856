import clsx from "clsx";
import { format } from "date-fns";
import firebase from "firebase/compat/app";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import * as server from "../../../../api/server/templateMessage";
import { Timestamp, storage } from "../../../../db";
import { createRef } from "../../../../db/connection";
import * as servCampaign from "../../../../db/serviceCampaign";
import * as servTemplate from "../../../../db/serviceTemplate";
import Loading from "../../../../resources/helpers/components/Loading";
import { RootState } from "../../../../setup/redux/store";
import "../../../../styles/css/campaign.scss";
import * as Log from "../../../../util/SDayslogger";
import { validateURL } from "../../../../util/validation";
import Account from "../../../models/Account";
import Campaign, {
  CampaignData,
  Language as CampaignLanguage,
  RepetitionConfig,
  ScheduleConfig,
  campaignState,
} from "../../../models/Campaign";
import Company from "../../../models/Company";
import TemplateLanguage from "../../../models/Language";
import Template, { templateState } from "../../../models/Template";
import { User } from "../../../models/User";
import * as ReduxCampaignList from "../../../modules/campaign/list/CampaignListSlice";
import * as ReduxCampaign from "../../../modules/campaign/redux/CampaignSlice";
import * as ReduxCampaignTarget from "../../../modules/campaign/target/redux/CampaignTargetSlice";
import * as lc from "../../../modules/localstorage/index";
import ModalConfirmation from "../../../modules/modal/modalConfirmation";
import ModalConfirmationMoveToOtherPage from "../../../modules/modal/modalConfirmationMoveToOtherPage";
import * as ReduxTemplate from "../../../modules/template/redux/TemplateSlice";
import { PageLink, PageTitle } from "../../core/PageData";
import CampaignChannel from "./component/CampaignChannel";
import CampaignMobileIcon from "./component/CampaignMobileIcon";
import CampaignSubmission from "./component/CampaignSubmission";
import CampaignTarget from "./component/CampaignTarget";
import CampaignTemplate from "./component/CampaignTemplate";
import CampaignFailover from "./component/CampaignFailover";
import CampaignFailoverss from "./component/CampaignFailoverRewrite";

// STEP
// Create Campaign
// Step 1 => Choose Channel, Choose Sender, Choose saved or new template
// Step 2 => Select template, select header, complete the param
// Step 3 => Add Target, Download or Import target,

const CampaignEditor = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  // Button Ref
  const saveButtonRef = useRef<HTMLButtonElement | null>(null);
  const prevButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const campaignTitleRef = useRef<HTMLInputElement>(null);
  // Campaign Title
  const [newCampaignTitle, setNewCampaignTitle] = useState<string>(
    `${t("Campaign.Title.UntitledCampaign")}`
  );
  const currentCampaignTitle = useSelector(
    (state: RootState) => state.Campaign.newCampaignName
  );
  const [isInsertTitle, setIsInsertTitle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCampaignExist, setIsCampaignExist] = useState(false);
  const [isClickSave, setIsClickSave] = useState(false);
  const [isButtonNextDisabled, setIsButtonNextDisabled] =
    useState<boolean>(false);
  const [logindex, setLogindex] = useState<number>(0);
  console.log(isButtonNextDisabled, "ISBUTTON_NEXT_DISABLED");

  // Campaign Data
  // const [dataCampaign, setDataCampaign] = useState<CampaignData>({});
  // const dataCampaign = useRef<CampaignData | null>(null);
  const dataCampaign = useSelector(
    (state: RootState) => state.Campaign.campaignData
  );

  Log.debug(dataCampaign, "data campaign cx RDX SACHA");
  let campaign = useSelector(
    (state: RootState) => state.Campaign.selectedCampaign
  );
  let campaignID = useSelector(
    (state: RootState) => state.Campaign.selectedCampaignID
  );
  let templateID = useSelector((state: RootState) => state.Campaign.templateID);
  let campaignTargetCount = useSelector(
    (state: RootState) => state.CampaignTarget.previewDataCount
  );
  let campaignTargetPreviewData = useSelector(
    (state: RootState) => state.CampaignTarget.previewData
  );
  Log.debug(campaignTargetPreviewData, "isinya apa cx");
  let targetData: string = useSelector(
    (state: RootState) => state.CampaignTarget.targetData
  );

  Log.debug("TARGET DATA PREVIEW", campaignTargetPreviewData);
  Log.debug("TARGET DATA", targetData);
  // Edit Campaign
  const campaignList = useSelector(
    (state: RootState) => state.CampaignList.listCampaign
  );
  const reduxCampaignState = useSelector(
    (state: RootState) => state.Campaign.campaignState
  );
  const reduxCampaignData = useSelector(
    (state: RootState) => state.Campaign.campaignData
  );
  const reduxCampaignNewTemplate = useSelector(
    (state: RootState) => state.Campaign.newTemplate
  );
  let selectedCampaign = useSelector(
    (state: RootState) => state.Campaign.selectedCampaign
  );

  //Redux Campaign
  let RCAccountID = useSelector((state: RootState) => state.Campaign.accountID);
  let RCClientID = useSelector((state: RootState) => state.Campaign.clientID);
  let RCSelectedTemplatedBody = useSelector(
    (state: RootState) => state.Campaign.selectedTemplateLanguage?.body
  );
  let RCFailover = useSelector(
    (state: RootState) => state.Campaign.selectedTemplateLanguage?.failover
  );
  let RCDFailover = useSelector(
    (state: RootState) => state.Campaign.campaignData?.failover
  );
  let RCButtons = useSelector(
    (state: RootState) => state.Campaign.templateButtons
  );
  let RCIsCampaignDynamicURLValid = useSelector(
    (state: RootState) => state.Campaign.isCampaignDynamicURLValid
  );
  const isLoadingCampaign = useSelector(
    (state: RootState) => state.Campaign.isLoading
  );

  //Redux Template
  let isCreateTemplateFinish = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateFinish
  );
  Log.debug(isCreateTemplateFinish, "ctf oppa");
  let isBackFromTemplate = useSelector(
    (state: RootState) => state.Campaign.backFromTemplate
  );
  Log.debug(isBackFromTemplate, "ibft oppa");
  let RTTemplateID = useSelector(
    (state: RootState) => state.Template.templateID
  );
  let RTBodyParams = useSelector(
    (state: RootState) => state.Template.templateBodyParams
  );
  let RTHeaderParams = useSelector(
    (state: RootState) => state.Template.templateHeaderParams
  );
  let RTButton = useSelector(
    (state: RootState) => state.Template.templateButton
  );
  let RTLanguageID = useSelector(
    (state: RootState) => state.Template.templateLanguageID
  );
  let RTGeneratedLanguage = useSelector(
    (state: RootState) => state.Template.generatedLanguage
  );
  let RTGeneratedTemplate = useSelector(
    (state: RootState) => state.Template.generatedTemplate
  );
  let RTTargetPreviewData = useSelector(
    (state: RootState) => state.Template.campaignTargetPreviewData
  );

  let RTFileURL = useSelector((state: RootState) => state.Campaign.fileURL);

  // for Request Message
  const user: User = lc.getItemLC("User");

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Campaign.Title.Campaign"),
      path: "/campaign/create",
      isSeparator: false,
      isActive: false,
    },

    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  Log.debug(isButtonNextDisabled, "button disable dsbl");

  useEffect(() => {
    Log.debug(
      `check iscreate template finis :${isCreateTemplateFinish} - ${reduxCampaignState}`
    );
    if (RCClientID === "") {
      const client = lc.getItemLC(lc.LCName.Client);
      dispatch(ReduxCampaign.setClientID(client?.id));
      Log.debug(`CAMPAIGN - Check Client ID in use effect: ${client?.id}`);
    }
    if (reduxCampaignState === campaignState.New) {
      dispatch(ReduxCampaign.setTemplateID(RTTemplateID));
      dispatch(ReduxCampaign.setTemplateBodyParams(RTBodyParams));
      dispatch(ReduxCampaign.setTemplateHeaderParams(RTHeaderParams));
      dispatch(
        ReduxCampaign.setCampaignButtons(
          RCButtons && RCButtons.length > 0 ? RCButtons : RTButton
        )
      );
      dispatch(ReduxCampaign.setTemplateLanguageID(RTLanguageID));
      dispatch(ReduxCampaign.setSelectedTemplateLanguage(RTGeneratedLanguage));
      if (currentCampaignTitle) {
        setNewCampaignTitle(currentCampaignTitle);
      }
      if (RTFileURL && RTFileURL !== "") {
        dispatch(
          ReduxCampaign.setTemplateHeaderParams([RTGeneratedLanguage.header])
        );
      }
      Log.debug(
        "Isi dari campaignTargetPreviewData dari create template: " +
          JSON.stringify(RTTargetPreviewData)
      );
      // if(currentCampaignTitle){
      //   setNewCampaignTitle(currentCampaignTitle);
      // }
      if (
        reduxCampaignState === campaignState.New &&
        isCreateTemplateFinish &&
        !isBackFromTemplate
      ) {
        setCurrentStep(3);
        onCurrentStepChange(3);
      }
    }
    Log.debug("KELUAR USEEFFECT 207 FLO");
  }, []);

  // Campaign Data on Each Step
  const onPrevNextClicked = async (direction: "prev" | "next") => {
    Log.debug("MASUK onPrevNextClicked FLO");

    Log.debug(`${direction} clicked before: ${currentStep}`);
    let nextStep;
    // document.getElementById(`nextStep`)?.classList.add("disabled");

    //process
    if (direction === "prev") {
      Log.debug(
        "Current step : " +
          currentStep +
          " > back to step : " +
          (currentStep - 1)
      );
      if (currentStep === 1) {
        Log.debug("First Step Page");
        nav("/campaign");
      } else if (currentStep === 2) {
        Log.debug("Second Step Page");
      } else if (currentStep === 3) {
        Log.debug("Third Step Page");
        // if (
        //   reduxCampaignState === campaignState.New &&
        //   isCreateTemplateFinish
        // ) {
        //   console.log(currentStep, "currd")
        //   console.log("Masuk Ke Prev After Create campaign New Template");
        //   nav("/campaign/create");
        //   return;
        // }

        document.getElementById(`nextStep`)?.classList.remove("disabled");
      } else if (currentStep === 4) {
        Log.debug("Fourth Step Page");
        // document.getElementById(`nextStep`)?.classList.remove("disabled");
      } else if (currentStep === 5) {
        Log.debug("Fourth Step Page");
        document.getElementById(`nextStep`)?.classList.remove("disabled");
      }

      if (currentStep > 1) {
        nextStep = -1;
        setCurrentStep(currentStep + nextStep);
        onCurrentStepChange(currentStep + nextStep);
        Log.debug(currentStep, nextStep, "b currd");
      }
    } else {
      nextButtonRef.current?.setAttribute("data-kt-indicator", "on");
      Log.debug("Current Step >>> " + currentStep);

      // await setCampaignStatus();

      if (currentStep === 5) {
        Log.debug(`Page 5`);
      } else if (currentStep === 1) {
        if (
          reduxCampaignState &&
          reduxCampaignState !== "" &&
          reduxCampaignState !== campaignState.Save
        ) {
          /**
           * Process Redux Template
           */
          dispatch(ReduxTemplate.setAccountsID([RCAccountID]));
          // dispatch(ReduxTemplate.setTemplateCompaniesID([RCCompanyID]));
          dispatch(
            ReduxTemplate.setTemplateWABussinessID(
              dataCampaign.whatsappBusinessAccount_ID
            )
          );
          dispatch(
            ReduxTemplate.setTemplateWAAccessToken(dataCampaign.access_token)
          );
          dispatch(
            ReduxTemplate.setTemplateState(templateState.campaigncreated)
          );
          dispatch(
            ReduxCampaign.setCampaignButtons(RCButtons ? RCButtons : [])
          );
          dispatch(ReduxCampaign.setBackFromTemplate(false));
          /**
           * Process Redux Template
           */
          nav("/template/create");
          return;
        } else {
          nextStep = 1;
          setCurrentStep(currentStep + nextStep);
          onCurrentStepChange(currentStep + nextStep);
        }
      } else if (currentStep === 2) {
        Log.debug(`masuk current step 2`);
        if (dataCampaign.headerMedia) {
          // Upload Header File to Storage
          const newMediaURL = await uploadHeaderMedia(
            dataCampaign.headerMedia,
            dataCampaign.header
          );

          if (newMediaURL && newMediaURL !== "") {
            // New Obj
            let newHeaderObj = {
              ...dataCampaign.header,
              headerURL: newMediaURL,
            };
            // console.log(`Check onCampaign Changes >> onPrevNext - CampaignEditor`)
            onCampaignChanges([
              { key: "header", value: newHeaderObj },
              { key: "headerStatus", value: "UPLOADED" },
              { key: "headerMediaURL", value: newMediaURL },
            ]);

            // Set to Redux
            let arrNewHeaderParam: any[] = [];
            arrNewHeaderParam.push(newHeaderObj);
            dispatch(ReduxCampaign.setTemplateHeaderParams(arrNewHeaderParam));
          }
        }
        console.log(
          "RCIsCampaignDynamicURLValid : ",
          RCIsCampaignDynamicURLValid
        );
        //jika masih tidak valid
        if (!RCIsCampaignDynamicURLValid) {
          const getSpinner = document.getElementById("nextStep");
          Log.debug(`getSpinner : `, getSpinner);
          if (getSpinner) {
            setTimeout(() => {
              getSpinner.removeAttribute("data-kt-indicator");
            }, 200);
          }
          return;
        }
        //check if have url dynamic and error then return
        if (RCButtons.length > 0) {
          const textArea = document.getElementById(
            `dynamicValue`
          ) as HTMLTextAreaElement;
          const findDynamicURLButton = RCButtons.find(
            (btn: any) => btn.type === "URL" && btn.urlType === "DYNAMIC"
          );
          Log.debug(`check button url`);
          if (
            findDynamicURLButton &&
            findDynamicURLButton.urlDynamic &&
            findDynamicURLButton.url
          ) {
            const isValidURL: boolean = validateURL(findDynamicURLButton.url);
            Log.debug(`1. CHECK button url dynamic `, findDynamicURLButton);
            const utm = findDynamicURLButton.urlDynamic.replace("{{1}}", "");
            Log.debug(`1. CHECK UTM `, utm);
            Log.debug(`1. CHECK textArea?.value `, textArea?.value);
            Log.debug(`1. CHECK isValidURL `, isValidURL);
        
            Log.debug(
              `1. CHECK textArea?.value === utm `,
              textArea?.value.trim() === utm
            );
            if (
              !isValidURL ||
              findDynamicURLButton.dynamicValue === "" ||
              textArea?.value.trim() === utm
            ) {
              Log.warning(`1. CHECK set redux error`);
              dispatch(ReduxCampaign.setIsCampaignDynamicURLValid(false));
              return;
            }
          }
        }
        nextStep = 1;
        setCurrentStep(currentStep + nextStep);
        onCurrentStepChange(currentStep + nextStep);
      } else {
        await onNewCampaignNewTemplate();
        nextStep = 1;
        setCurrentStep(currentStep + nextStep);
        onCurrentStepChange(currentStep + nextStep);
      }
      nextButtonRef.current?.removeAttribute("data-kt-indicator");
    }
    document.getElementById(`step_${currentStep}`)?.classList.add("disabled");
    document.getElementById("nextStep")?.classList.remove("disabled");
    Log.debug("KELUAR setCampaignStatus FLO");
  };

  const setCampaignStatus = async () => {
    console.log("MASUK setCampaignStatus FLO");

    console.log(`Check status campaign: 1-0 
    campaign data >> ${JSON.stringify(dataCampaign)}
    campaign status >> ${dataCampaign.createStatus}`);

    // Set Campaign Status based on Submission Type
    if (
      dataCampaign?.campaignSchedule?.toString().toLowerCase() === "sendnow"
    ) {
      console.log(`Check status campaign: 1-1 
      campaign data >> ${JSON.stringify(dataCampaign)}
      campaign status >> ${dataCampaign.createStatus}`);
      await onCampaignChanges([{ key: "createStatus", value: "COMPLETE" }]);
    }
    if (
      dataCampaign?.campaignSchedule?.toString().toLowerCase() === "scheduled"
    ) {
      console.log(`Check status campaign: 1-2 
      campaign data >> ${JSON.stringify(dataCampaign)}
      campaign status >> ${dataCampaign.createStatus}`);
      await onCampaignChanges([{ key: "createStatus", value: "SCHEDULED" }]);
    }
    console.log("KELUAR setCampaignStatus FLO");
  };

  const submitCampaignHandler = async () => {
    console.log("MASUK submitCampaignHandler FLO");

    // Submit Indicator
    submitButtonRef.current?.setAttribute("data-kt-indicator", "on");
    submitButtonRef.current?.setAttribute("disabled", "");
    // New Template
    await onNewCampaignNewTemplate();

    console.log(`Check onCampaign Changes >> onPrevNext - CampaignEditor`);
    console.log(`Check status campaign: 1-3 
    campaign data >> ${JSON.stringify(dataCampaign)}
    campaign status >> ${dataCampaign.createStatus}`);

    // Save Campaign
    const idCampaign = await onNewCampaignUpdate();
    console.log(`Campaign Submit Handler 
    campignID from redux ${campaignID}
    campignID from process onNewCampaignUpdate ${idCampaign}
    `);

    if (idCampaign) {
      // console.log(`Masuk process send blast ${idCampaign}`);
      await servCampaign.updateCampaignById(idCampaign, {
        campaignLanguage: i18n.language,
      });

      // Schedule Process
      if (
        idCampaign &&
        dataCampaign.campaignSchedule &&
        dataCampaign.campaignSchedule.toString().toLowerCase() === "scheduled"
      ) {
        console.log("masuk blast schedule on edit");
        console.log("DATA_SCHEDULE_CAMPAIGN_SAVE", dataCampaign.schedule);

        await servCampaign.createCampaignSchedule(
          idCampaign,
          dataCampaign.schedule
        );
        await servCampaign.updateCampaignById(idCampaign, {
          campaignLanguage: i18n.language,
          status: "SCHEDULED",
        });
        await servCampaign.addInitialMessageOnCampaign(
          idCampaign,
          campaignTargetPreviewData,
          RCSelectedTemplatedBody,
          "SCHEDULED"
        );
        //set target
        await servCampaign.updateCountTarget(
          idCampaign,
          campaignTargetPreviewData.length
        );
        console.log("masuk blast schedule on edit");
      }

      // Send Now Process
      else if (
        idCampaign &&
        dataCampaign.campaignSchedule &&
        dataCampaign.campaignSchedule.toString().toLowerCase() === "sendnow"
      ) {
        // Send Request Message to Server
        let accountId = "";
        let companyId = "";
        if (isCreateTemplateFinish) {
          // accountId = RTAccountID;
          // companyId = RTCompanyID;
        } else {
          // if (!campaignID) {
          accountId = dataCampaign.accountId?.toString();
          companyId = dataCampaign.companyId?.toString();
          // }
          // else {
          //   if (selectedCampaign.account && selectedCampaign.company) {
          //     accountId = selectedCampaign.account?.id
          //     companyId = selectedCampaign.company?.id
          //   }
          // }
        }

        console.log(`Check Request Parameter 
        uid >> ${user.uid},
        companyId >> ${companyId},
        accountId >> ${accountId},
        idCampaign >> ${idCampaign},
        i18n.language >> ${i18n.language}
        `);
        await servCampaign.addInitialMessageOnCampaign(
          idCampaign,
          campaignTargetPreviewData,
          RCSelectedTemplatedBody,
          "CREATED"
        );
        await servCampaign.updateCountTarget(
          idCampaign,
          campaignTargetPreviewData.length
        );

        const { responseCode, responseJson } =
          await server.sendRequestBlastMessage(
            user.uid!,
            companyId,
            accountId,
            idCampaign,
            i18n.language
            // (responseCode: string, responseJson: JSON) => {
            //   console.log(`Response send request
            //   >> response code ${responseCode}
            //   >> response code ${JSON.stringify(responseJson)}
            //   >> user.uid ${user.uid}
            //   >> response code ${JSON.stringify(companyId)},
            //   >> response code ${JSON.stringify(accountId)},
            //   >> response code ${JSON.stringify(idCampaign)},
            //   >> response code ${JSON.stringify(i18n.language)},
            //   `);
            //   if (responseJson) {
            //     // console.log(JSON.stringify(responseJson));
            //   }
            // }
          );
        // .then(async (response) => {
        //   console.log(`then response : ${JSON.stringify(response)}`);
        // success to send request
        // failed to send request
        // failed because template status
        // else

        /***
             * undefined toString createStatus when click button send now 
             * create campaign -> existing template/new template -> send now
             * variabel not used
              let newStatus = dataCampaign.createStatus.toString();
              console.log(`Send Request Message to Server
              >> response >> ${JSON.stringify(response)}
              >> status >> ${newStatus}
              `)
             */

        // Update status campaign to Processed
        // await servCampaign
        //   .updateCampaignById(idCampaign, { status: "PROCESSED" })
        //   .then(async (id) => {
        //     if (id) {
        //       console.log(`Campaign ${id} status Updated`);
        //     }
        //   });
        // })
        // .catch(function (error) {
        //   console.log("Error Send Request Message to Server: " + error);
        // });

        if (responseJson.resultCode != "BL10") {
          await servCampaign
            .updateCampaignById(idCampaign, { status: "PROCESSED" })
            .then(async (id) => {
              if (id) {
                console.log(`Campaign ${id} status Updated`);
              }
            });
        }
      }
      submitButtonRef.current?.removeAttribute("data-kt-indicator");
      submitButtonRef.current?.removeAttribute("disabled");
      clearAndExit();
      return idCampaign;
    }
    console.log("KELUAR submitCampaignHandler FLO");
  };

  const onNewCampaignNewTemplate = async () => {
    console.log("-- MASUK ONNEWCAMPAIGNNEWTEMPLATE SACHA FLO --");
    
    if (isCreateTemplateFinish) {
      console.log(
        `MASUK IF Check onCampaign Changes >> onNewCampaignNewTemplate - CampaignEditor SACHA`
      );
      if (RCDFailover !== undefined) {
        console.log("MASUK IF RCDFAILOVER !== undefined SACHA");
        
        let arrBodyParam = RTGeneratedLanguage?.failover?.bodyParam;
        console.log(arrBodyParam, "arrBodyParam SACHA");
        

        if (arrBodyParam !== undefined) {
          console.log("MASUK IF arrBodyParam !== undefined SACHA");
          const updatedFailover = { ...RTGeneratedLanguage.failover };
          console.log(updatedFailover, "updatedFailover SACHA");
          for (let index = 0; index < arrBodyParam.length; index++) {
            const element = arrBodyParam[index];
            console.log(element, "element SACHA");
            updatedFailover.message = updatedFailover.message.replace(
              new RegExp(`{{${element.paramName || element.paramValue}}}`, "g"),
              `{{${index + 1}}}`
            );
          }
          RTGeneratedLanguage = {
            ...RTGeneratedLanguage,
            failover: updatedFailover,
          };
          console.log(RTGeneratedLanguage, "RTGeneratedLanguage SACHA");
        }

        onCampaignChanges([
          { key: "clientId", value: RCClientID },
          { key: "templateId", value: RTTemplateID },
          { key: "template", value: RTGeneratedTemplate },
          { key: "language", value: RTGeneratedLanguage },
          { key: "bodyParam", value: reduxCampaignData.bodyParam },
          { key: "header", value: RTHeaderParams },
          { key: "failover", value: RCDFailover },
          { key: "templateState", value: campaignState.Save },
          { key: "campaignName", value: currentCampaignTitle },
          { key: "buttons", value: RCButtons },
        ]);
      } else {
        onCampaignChanges([
          { key: "clientId", value: RCClientID },
          { key: "templateId", value: RTTemplateID },
          { key: "template", value: RTGeneratedTemplate },
          { key: "language", value: RTGeneratedLanguage },
          { key: "bodyParam", value: reduxCampaignData.bodyParam },
          { key: "header", value: RTHeaderParams },
          { key: "templateState", value: campaignState.Save },
          { key: "campaignName", value: currentCampaignTitle },
          { key: "buttons", value: RCButtons },
        ]);
      }
    }
    console.log("-- MASUK ONNEWCAMPAIGNNEWTEMPLATE SACHA FLO --");
  };

  const uploadTargetFile = async (campaignId: string) => {
    console.log("MASUK uploadTargetFile FLO");

    let fileURL = "";
    let reportName =
      "CampaignTarget-" +
      currentCampaignTitle +
      "_" +
      format(new Date(), "d-MMM-yyyy[HHmmss]") +
      ".csv";

    // Upload to Storage
    if (!campaignId) {
      return;
    }
    console.log("Target Data : " + targetData);

    const targetFile = new Blob([targetData]);
    const metadata = {
      contentType: targetFile.type,
      fileName: reportName,
    };

    const task = storage
      .ref("campaigns/" + campaignId + "/target")
      .child(reportName)
      .put(targetFile, metadata);
    await task
      .then(async (snapshot) => {
        return storage
          .ref("campaigns/" + campaignId + "/target")
          .child(reportName)
          .getDownloadURL()
          .then((url) => {
            fileURL = url;
            console.log(`uploaded ${fileURL}`);
          });
      })
      .catch((error) => {
        console.log("error upload: " + error.message);
        alert(t("HC.Error.FailedUpload"));
      });

    // console.log(`after upload`)

    await servCampaign
      .updateCampaignById(campaignId, { targetFileURL: fileURL })
      .then(async (id) => {
        if (id) {
          // console.log(`Campaign ${id} Updated: targetFileURL`);
        }
      });

    onCampaignChanges([{ key: "targetFileURL", value: fileURL }]);
    console.log("KELUAR uploadTargetFile FLO");

  };

  const uploadHeaderMedia = async (headerFile: any, headerParam: any) => {
    console.log("MASUK uploadHeaderMedia FLO");
    let mediaURL = "";
    // let reportName = "CampaignTarget-" + currentCampaignTitle+"_"+format(new Date(), "d-MMM-yyyy[HHmmss]")+ ".csv";

    if (headerFile) {
      const headerFileName = headerParam.fileName.toString();

      const metadata = {
        contentType: headerParam.fileType.toString(),
        fileName: headerFileName,
      };

      const task = storage
        .ref(`media/${headerParam.mediaType}s`)
        .child(headerFileName)
        .put(headerFile, metadata);
      await task
        .then(async (snapshot) => {
          return storage
            .ref(`media/${headerParam.mediaType}s`)
            .child(headerFileName)
            .getDownloadURL()
            .then((url) => {
              mediaURL = url;
            });
        })
        .catch((error) => {

          Log.error("Upload Header Media: " + error)
          //alert Message exceeds the limit of the number of updates per days
          const failedTextAlert = document.getElementById(
            "failedProcessAlertNotif"
          ) as HTMLDivElement;
          failedTextAlert.innerHTML = t("HC.Error.FailedUpload");
          const buttonFailedAlert = document.getElementById(
            "triggerFailedProcess"
          );
          if (buttonFailedAlert) {
            buttonFailedAlert.click();
          }
        });
    }
    console.log("MASUK uploadHeaderMedia FLO");
    return mediaURL;
  };

  //some value of campaign changes
  const campaignDataChanges = async (params: { key: string; value: any }[]) => {
    console.log("MASUK campaignDataChanges FLO");
    console.log(
      `${logindex} ------------------>>> Campaign Data changes process start`
    );

    let data: CampaignData = {}; //dataCampaign;
    // console.log(`${logindex} ------------------>>> Campaign Data Campaign Current: `+JSON.stringify(dataCampaign));

    // let data:CampaignData =  Object.assign({}, dataCampaign);

    params.forEach((param) => {
      // data:CampaignData =  Object.assign(data, dataCampaign);
      data = { ...data, [param.key]: param.value };
      // setDataCampaign({...dataCampaign,[param.key]:param.value})
    });
    // Save to Redux
    console.log(data, "data rfid");
    console.log(reduxCampaignData, "redcamp rfid");
    dispatch(ReduxCampaign.addCampaignData(data));

    onCampaignDataChange();

    await setLogindex(logindex + 1);
    console.log("KELUAR campaignDataChanges FLO");
  };

  const onCampaignChanges = useCallback(campaignDataChanges, []);
  // console.log(onCampaignChanges, "oncamp guix")

  /**
   * CREATED - save changes dan belum terkirim,
   * COMPLETE - input sudah lengkap & sudah akan dikirim,
   * PROCESSED,
   */
  const updateCampaignCreateStatus = (value: any) => {
    console.log("MASUK updateCampaignCreateStatus FLO");
    console.log(
      `Check status campaign: 2-1 >> ${JSON.stringify(dataCampaign)}`
    );
    console.log(`Check status campaign: 2-2 >> ${value}`);

    if (!value) return "CREATED";

    let newValue = value.toString().toUpperCase();
    //TODO
    switch (value.toString().toUpperCase()) {
      case "CREATED":
        newValue = "CREATED";
        break;
      case "COMPLETE":
        newValue = "PROCESSED";
        break;
      case "SCHEDULED":
        newValue = "SCHEDULED";
        break;
    }

    console.log(`Check status campaign: 3 >> ${newValue}`);

    console.log("KELUAR updateCampaignCreateStatus FLO");
    return newValue;
  };

  const mapDataToObject = async (
    dataCampaign: CampaignData,
    callback: (
      campaignObj: Campaign,
      campaignLanguageObj: CampaignLanguage,
      templateObj: Template,
      languageObj: TemplateLanguage
    ) => void
  ) => {
    console.log("MASUK mapDataToObject FLO");
    let campaignObj: Campaign = {};
    let campaignLanguageObj: CampaignLanguage = {};
    let templateObj: Template = {};
    let languageObj: TemplateLanguage = {};

    console.log(
      `Masuk Map Data to Object Data Campaign ${JSON.stringify(dataCampaign)}`
    );
    console.log(`Check status campaign: 1 >> ${dataCampaign.createStatus}`);

    if (!dataCampaign.campaignName) {
      console.log("Campaign name is Undefined and Not Processing");
      setIsCampaignExist(true);
      return;
    } //else {

    campaignObj = {
      isActive: true,
      createdAt: campaignID ? selectedCampaign.createdAt : (campaign.createdAt ? campaign.createdAt : Timestamp.now()),
      updatedAt: Timestamp.now(),
      campaignName: !campaignID
        ? dataCampaign.campaignName.toString()
        : selectedCampaign.campaignName,
      status: await updateCampaignCreateStatus(dataCampaign.createStatus),
    };

    // console.log("Masuk Map Data to Object - Campaign Name : "+dataCampaign.campaignName);
    // console.log("Masuk Map Data to Object - CampaignID : "+campaignID);
    // console.log("Masuk Map Data to Object - Campaign Name : "+ dataCampaign.campaignSchedule);

    if (!campaignID) {
      campaignObj = { ...campaignObj, createdAt: Timestamp.now() };
    }

    if (dataCampaign.templateState) {
      campaignObj = {
        ...campaignObj,
        templateState:
          dataCampaign.templateState !== undefined || ""
            ? dataCampaign.templateState.toString()
            : reduxCampaignState,
      };
    }

    // console.log("Masuk Map Data to Object - templateState : "+dataCampaign.templateState);
    if (dataCampaign.clientId) {
      campaignObj = {
        ...campaignObj,
        client: createRef(
          "clients",
          dataCampaign.clientId !== undefined || ""
            ? dataCampaign.clientId.toString()
            : RCClientID
        ),
      };
    }

    // console.log("Masuk Map Data to Object - companyId : "+dataCampaign.companyId);

    if (dataCampaign.accountId) {
      campaignObj = {
        ...campaignObj,
        account: createRef(
          "account",
          dataCampaign.accountId !== undefined || ""
            ? dataCampaign.accountId.toString()
            : RCAccountID
        ),
      };
    }
    if (dataCampaign.companyId) {
      campaignObj = {
        ...campaignObj,
        company: createRef("company", dataCampaign.companyId.toString()),
      };
    }
    // console.log("Masuk Map Data to Object - accountId : "+dataCampaign.accountId);

    // if (dataCampaign.targetFileURL && dataCampaign.targetFileURL.toString() !== "") {
    //   campaignObj = {...campaignObj,
    //     targetFileURL: dataCampaign.targetFileURL.toString()}
    // }

    // console.log("Masuk Map Data to Object - targetFileURL : "+dataCampaign.targetFileURL);

    if (dataCampaign.campaignSchedule) {
      let schedule =
        dataCampaign.scheduledAt as any as firebase.firestore.Timestamp;
      campaignObj = {
        ...campaignObj,
        campaignSchedule: dataCampaign.campaignSchedule.toString(),
        scheduledAt: schedule,
      };
    }

    //save campaign schedule config and repetition
    if (
      dataCampaign.campaignSchedule &&
      dataCampaign.campaignSchedule.toString().toLowerCase() === "scheduled" &&
      dataCampaign.schedule
    ) {
      console.log(dataCampaign?.repeatConfig);
      console.log(
        `CHECK DATA SCHEDULE >>> ${JSON.stringify(dataCampaign.scheduleConfig)}`
      );
      const scheduleData: any = dataCampaign.schedule;
      const scheduleConfig: ScheduleConfig = {
        isRepeat:
          scheduleData.isRepeat !== undefined ? scheduleData.isRepeat : false,
      };

      if (scheduleData.isRepActive !== undefined) {
        scheduleConfig.isRepActive = scheduleData.isRepActive;
      } else {
        scheduleConfig.isRepActive = scheduleConfig.isRepeat;
      }

      if (scheduleData.repeatConfig) {
        scheduleConfig.repeatConfig = scheduleData.repeatConfig;
      }
      if (scheduleData.end) {
        scheduleConfig.end = scheduleData.end;
      }
      console.log(
        `CHECK DATA SCHEDULE CONFIG >>> ${JSON.stringify(scheduleConfig)}`
      );
      campaignObj = { ...campaignObj, scheduleConfig };
    }

    // console.log("Masuk Map Data to Object - campaignSchedule : "+dataCampaign.campaignSchedule);

    if (
      dataCampaign.targetFileURL &&
      dataCampaign.targetFileURL.toString() === ""
    ) {
      if (campaignID && selectedCampaign) {
        campaignObj = {
          ...campaignObj,
          targetFileURL: firebase.firestore.FieldValue.delete(),
        };
      }
    }

    if (dataCampaign.templateState) {
      let templateID: string = "";
      if (dataCampaign.templateId) {
        templateID = dataCampaign.templateId.toString();
      }
      if (templateID && templateID !== "") {
        // Saved Template, Get Data template
        const dataTemplate = (await servTemplate
          .get(templateID)
          .then(async (template) => {
            if (template) {
              return template as Template;
            }
          })) as Template;

        if (dataTemplate) {
          // Template Name
          if (dataTemplate.templateName) {
            campaignObj = {
              ...campaignObj,
              templateName: dataTemplate.templateName,
            };
          }

          const arrLanguages: any[] = [];
          let newObj = {
            templateID: templateID,
            templateName: dataTemplate.templateName
              ? dataTemplate.templateName
              : reduxCampaignNewTemplate.templateName
              ? reduxCampaignNewTemplate.templateName
              : "",
            templateCategory: dataTemplate.category
              ? dataTemplate.category
              : reduxCampaignNewTemplate.category
              ? reduxCampaignNewTemplate.category
              : "",
          };

          const resultTempLanguage = await servTemplate.getTemplateLanguages(
            templateID
          );

          // console.log(`Masuk Map Data to Object - resultTempLanguage ${JSON.stringify(resultTempLanguage)}`);

          const templateLanguage = resultTempLanguage;
          if (templateLanguage && templateLanguage.length > 0) {
            for (let index = 0; index < templateLanguage?.length; index++) {
              const element = templateLanguage[index];

              if (element) {
                arrLanguages.push({
                  templateLanguage: element.language,
                  templateStatus: element.status ? element.status : "",
                });
              }
            }

            if (arrLanguages.length > 0) {
              let obj = {};
              obj = { ...newObj, languages: arrLanguages };
              campaignObj = { ...campaignObj, template: obj };
            }
          }
        }
      }

      campaignLanguageObj = Object.fromEntries(
        Object.entries(dataCampaign).filter(
          ([key]) =>
            key === "bodyParam" ||
            key === "header" ||
            key === "buttons" ||
            key === "failover"
        )
      );

      if (reduxCampaignState === campaignState.New) {
        if (RTGeneratedLanguage && RTGeneratedLanguage.header) {
          campaignLanguageObj.header = RTGeneratedLanguage.header;
        }
        if (RTBodyParams) {
          campaignLanguageObj.bodyParam = RTBodyParams;
        }
        if (RCButtons) {
          campaignLanguageObj.buttons = RCButtons;
        }
        console.log(RCDFailover, "RCFAIL1");
        if (RCDFailover !== undefined) {
          console.log(RCDFailover, "RCFAIL2");
          campaignLanguageObj.failover = RCDFailover as any;
        }
      }

      // console.log(`Masuk Map Data to Object - bodyParam ${JSON.stringify(campaignLanguageObj.bodyParam)}`);
      // console.log(`Masuk Map Data to Object - headerParam ${JSON.stringify(campaignLanguageObj.header)}`);
    }

    if (reduxCampaignState === campaignState.New) {
      // console.log("Check hasil Campaign New");
      if (RTGeneratedTemplate && RTGeneratedLanguage) {
        templateObj = RTGeneratedTemplate;
        languageObj = RTGeneratedLanguage;
      }
    } else {
      if (dataCampaign.template) {
        templateObj = Object.fromEntries(
          Object.entries(dataCampaign.template).filter(
            ([key]) => !key.includes("languages")
          )
        );

        languageObj = Object.fromEntries(
          Object.entries(dataCampaign.template).filter(([key]) =>
            key.includes("languages")
          )
        ).languages;
      }
    }

    console.log(`CHECK campaign obj piko SACHA`, campaignObj);

    console.log(`Check Hasil : 
      campaign obj >> ${JSON.stringify(campaignObj)}
      campaign lang obj >> ${JSON.stringify(campaignLanguageObj)}
      template obj >> ${JSON.stringify(templateObj)}
      language obj >> ${JSON.stringify(languageObj)}
    `);

    callback(campaignObj, campaignLanguageObj, templateObj, languageObj);
    console.log("KELUAR mapDataToObject FLO");
  };

  // Change Campaign Title
  const changeTitle = async () => {
    console.log("MASUK changeTitle FLO");
    const title = campaignTitleRef?.current?.value;
    console.log(`title ${title}`);
    if (!title) {
    } else if (title === "") {
      // if (campaignID && selectedCampaign.campaignName) {
      //   setNewCampaignTitle(selectedCampaign.campaignName);
      //   dispatch(ReduxCampaign.setNewCampaignName(selectedCampaign.campaignName))
      //   onCampaignChanges([{key: "campaignName",value:selectedCampaign.campaignName}])
      // } else {
      //   setNewCampaignTitle(t("Campaign.Title.UntitledCampaign"));
      //   dispatch(ReduxCampaign.setNewCampaignName(t("Campaign.Title.UntitledCampaign")))
      //   onCampaignChanges([{key: "campaignName",value:t("Campaign.Title.UntitledCampaign")}])
      // }
      setNewCampaignTitle(t("Campaign.Title.UntitledCampaign"));
      dispatch(
        ReduxCampaign.setNewCampaignName(t("Campaign.Title.UntitledCampaign"))
      );
      onCampaignChanges([
        { key: "campaignName", value: t("Campaign.Title.UntitledCampaign") },
      ]);
    } else {
      setNewCampaignTitle(title);
      dispatch(ReduxCampaign.setNewCampaignName(title));
      onCampaignChanges([{ key: "campaignName", value: title }]);

      const existingCampaign = await servCampaign.getCampaignByName(
        title,
        campaignID,
        RCClientID
      );
      if (existingCampaign) {
        // && !campaignID) {
        setIsCampaignExist(true);
        document
          .getElementById(`saveChangeNewCampaign`)
          ?.classList.add("disabled");
      }
    }

    setIsInsertTitle(false);
    console.log("KELUAR changeTitle FLO");
  };

  //Save or update Data
  const onNewCampaignUpdate = async () => {
    console.log("--- MASUK ONNEWCAMPAIGNUPDATE SACHA FLO ---");

    // let dataCampaignCopy = { ...dataCampaign.current } ;
    let dataCampaignCopy = { ...dataCampaign } as any;
    let processedCampaignId: string = "";
    let campaignObj: Campaign = {};
    let campaignLanguageObj: CampaignLanguage = {};
    let templateObj: Template = {};
    let languageObj: TemplateLanguage = {};

    console.log(dataCampaignCopy, "dataCampaignCopy BEFORE SACHA");
    console.log(processedCampaignId, "processedCampaignId BEFORE SACHA");
    console.log(campaignObj, "campaignObj BEFORE SACHA");
    console.log(campaignLanguageObj, "campaignLanguageObj BEFORE SACHA");
    console.log(templateObj, "templateObj BEFORE SACHA");
    console.log(languageObj, "languageObj BEFORE SACHA");

    let arrBodyParam = dataCampaignCopy?.failover?.bodyParam;
    console.log(arrBodyParam, "arrBodyParam BEFORE SACHA");

    if (arrBodyParam !== undefined) {
      const updatedFailover = { ...dataCampaignCopy.failover };
      console.log(updatedFailover, "updatedFailover SACHA");
      
      for (let index = 0; index < arrBodyParam.length; index++) {
        const element = arrBodyParam[index];
        updatedFailover.message = updatedFailover.message.replace(
          new RegExp(`{{${element.paramName || element.paramValue}}}`, "g"),
          `{{${index + 1}}}`
        );
      }
      dataCampaignCopy = {
        ...dataCampaignCopy,
        failover: updatedFailover,
      };
    }

    console.log(dataCampaignCopy, "ini datacampaign after +1 piko SACHA");
    console.log(campaignLanguageObj, "ini camplang after +1 piko SACHA");
    console.log(campaignObj, "ini campoibj after +1 piko SACHA");
    console.log(languageObj, "ini languageobj after +1 piko SACHA");
    console.log(templateObj, "ini templateobj after +1 piko SACHA");
    // check if campaign name is same with i18n t("Campaign.Title.UntitledCampaign")
    if (
      dataCampaignCopy.campaignName &&
      dataCampaignCopy.campaignName.toString() ===
        t("Campaign.Title.UntitledCampaign")
    ) {
      console.log(`MASUK IF Campaign Name is Untitled Campaign SACHA`);
      setIsCampaignExist(true);
      submitButtonRef.current?.removeAttribute("data-kt-indicator");
      submitButtonRef.current?.removeAttribute("disabled");
      return;
    }

    const existingCampaign =
      dataCampaignCopy.campaignName &&
      (await servCampaign.getCampaignByName(
        dataCampaignCopy.campaignName.toString(),
        campaignID,
        RCClientID
      ));
    if (existingCampaign && !campaignID) {
      // && !campaignID) {
      console.log(`MASUK IF Campaign Exist with the Same Name SACHA`);

      setIsCampaignExist(true);
      submitButtonRef.current?.removeAttribute("data-kt-indicator");
      submitButtonRef.current?.removeAttribute("disabled");
      return;
      // document.getElementById(`saveChangeNewCampaign`)?.classList.add("disabled");
    }
    console.log(existingCampaign, "existingCampaign SACHA");

    console.log(`Campaign Header Check 1`);
    // console.log(`SAVE CHANGES BUTTON CLICKED >> ${JSON.stringify(dataCampaignCopy)}`);

    // Upload Header
    if (
      dataCampaignCopy.headerMedia !== undefined &&
      (dataCampaignCopy.headerStatus === undefined ||
        dataCampaignCopy.headerStatus.toString() === "")
    ) {
      console.log(`MASUK IF Campaign Header Check 2 SACHA`);
      // Upload Header File to Storage
      const uploadURL = await uploadHeaderMedia(
        dataCampaignCopy.headerMedia,
        dataCampaignCopy.header
      );

      if (uploadURL && uploadURL !== "") {
        console.log(`MASUK IF Campaign Header Check 3 SACHA`);
        /*
        console.log(`SAVE CHANGES BUTTON CLICKED 2 
        url >> ${uploadURL}
        data >> ${JSON.stringify(dataCampaign)}`);
        */
        // New Obj
        let newHeaderObj = { ...dataCampaignCopy.header, headerURL: uploadURL };
        // console.log(`Check onCampaign Changes >> uploadHeaderMedia - CampaignEditor`)
        onCampaignChanges([
          { key: "header", value: newHeaderObj },
          { key: "headerStatus", value: "UPLOADED" },
          { key: "headerMediaURL", value: uploadURL },
        ]);
        console.log(
          newHeaderObj,
          "newHeaderObj DI IF Campaign Header Check 3 SACHA"
        );
        dataCampaignCopy = {
          ...dataCampaignCopy,
          header: newHeaderObj,
          headerStatus: "UPLOADED" as any,
          headerMediaURL: uploadURL as any,
        };
        console.log(
          dataCampaignCopy,
          "dataCampaignCopy DI IF Campaign Header Check 3 SACHA"
        );

        // dataCampaignCopy = {...dataCampaignCopy,[headerStatus]:param.value}

        // Set to Redux
        let arrNewHeaderParam: any[] = [];
        arrNewHeaderParam.push(newHeaderObj);
        dispatch(ReduxCampaign.setTemplateHeaderParams(arrNewHeaderParam));
        console.log(
          arrNewHeaderParam,
          "arrNewHeaderParam DI IF Campaign Header Check 3 SACHA"
        );

        // console.log(`SAVE CHANGES BUTTON CLICKED 3
        // new obj for header >> ${JSON.stringify(newHeaderObj)}
        // media url >> ${uploadURL}
        // dataCampaign >> ${JSON.stringify(dataCampaignCopy)}
        // `);
      }

      // console.log(`Check Header URL >> ${JSON.stringify(dataCampaignCopy)}`);
    }

    // console.log("ON NEW CAMPAIGN UPDATE >>>> mapDataToObject >> "+JSON.stringify(dataCampaignCopy));
    // console.log("check language vita " + dataCampaignCopy.language);
    console.log("check dataCampaign irun " + JSON.stringify(dataCampaignCopy));

    if (!dataCampaignCopy.clientId) {
      console.log(`MASUK IF add client in campaign SACHA`);
      const clientId: any = RCClientID;
      dataCampaignCopy = { ...dataCampaignCopy, clientId: clientId };
    }

    await mapDataToObject(
      dataCampaignCopy,
      (campaign, campaignLanguage, template, language) => {
        campaignObj = campaign;
        campaignLanguageObj = campaignLanguage;
        templateObj = template;
        languageObj = language;
      }
    );

    // console.log(`Check Hasil 2 :
    //   campaign obj >> ${JSON.stringify(campaignObj)}
    //   campaign lang obj >> ${JSON.stringify(campaignLanguageObj)}
    //   template obj >> ${JSON.stringify(templateObj)}
    //   language obj >> ${JSON.stringify(languageObj)}
    // `);
    console.log(dataCampaignCopy, "dataCampaignCopy 1349 SACHA");

    console.log(`Check Log 1
    dataCampaign >> ${JSON.stringify(dataCampaignCopy)}
    `);
    // Edit Campaign
    if (campaignID && campaignID !== "") {
      console.log("MASUK IF campaignID not null SACHA");
      // Update Campaign Data
      console.log(`Check Log 2
      dataCampaign >> ${JSON.stringify(dataCampaignCopy)}
      campaign from Redux >> ${JSON.stringify(selectedCampaign)}
      hasil map ${JSON.stringify(campaignObj)}
        ${JSON.stringify(templateObj)}
        ${JSON.stringify(languageObj)}
      `);

      // Update Campaign
      await servCampaign
        .updateCampaignById(campaignID, campaignObj)
        .then(async (campaignId) => {
          if (campaignId) {
            await servCampaign.setCampaignSubCollection(
              campaignId,
              i18n.language,
              campaignLanguageObj
            );
            // console.log("Updated Campaign: "+campaignId);
            processedCampaignId = campaignId;
          }
        });
    } else {
      console.log("MASUK ELSE campaignID not null SACHA");
      // console.log(`Check Log 3
      // dataCampaign >> ${JSON.stringify(dataCampaignCopy)}
      // `);

      console.log(campaignLanguageObj, "___campaignLanguageOBJ");

      await servCampaign.createCampaign(campaignObj).then(async (newId) => {
        // Add SubCollection (Language and Body Param)
        if (newId) {
          // if (dataCampaignCopy.bodyParam || dataCampaignCopy.header) {
          // console.log(`Map data
          // >> campaign ID >> ${newId}
          // >> dataCampaign >> ${JSON.stringify(dataCampaignCopy)}
          // >> dataCampaign header >> ${JSON.stringify(dataCampaignCopy.header)}
          // >> dataCampaign bodyparam >> ${JSON.stringify(dataCampaignCopy.bodyParam)}
          // >> campaign language obj >> ${JSON.stringify(campaignLanguageObj)}
          // `);

          await servCampaign.setCampaignSubCollection(
            newId,
            i18n.language,
            campaignLanguageObj
          );
          // }
          dispatch(ReduxCampaign.setNewCampaignID(newId));
          processedCampaignId = newId;
        }
      });
    }

    console.log(`Check Log 4
    dataCampaign >> ${JSON.stringify(dataCampaignCopy)}
    `);

    // console.log(`Check Target Count and targetFileURL 2
    //   >> campaignTargetCount >> ${campaignTargetCount}
    //   >> targetFileURL >> ${dataCampaignCopy.targetFileURL}
    //   `);

    // Save Target File
    if (campaignTargetCount > 0) {
      console.log("MASUK IF campaignTargetCount more than 0 SACHA");
      console.log(`Check Log 5
      dataCampaign >> ${JSON.stringify(dataCampaignCopy)}
      `);
      // console.log(`Check Target Count and targetFileURL 2
      // >> campaignTargetCount >> ${campaignTargetCount}
      // >> targetFileURL >> ${dataCampaignCopy.targetFileURL}
      // `);

      // Upload File Target to Storage
      await uploadTargetFile(processedCampaignId);
    }

    if (processedCampaignId !== "") {
      console.log("MASUK IF processedCampaignId not null SACHA");
      console.log(`Check Log 6
      dataCampaign >> ${JSON.stringify(dataCampaignCopy)}
      `);
      console.log(`Processed Campaign >> ${processedCampaignId}`);
      return processedCampaignId;
    }
    console.log(`Check Log 7
    dataCampaign >> ${JSON.stringify(dataCampaignCopy)}
    `);
    console.log(dataCampaignCopy, "dataCampaignCopy END SACHA");
    console.log("--- KELUAR ONNEWCAMPAIGNUPDATE SACHA FLO ---");
  };

  const clearAndExit = () => {
    console.log("MASUK clearAndExit FLO");
    setIsLoading(false);

    //TODO: fix error on click

    // Move to Campaign List Page
    dispatch(ReduxCampaign.cleanReduxCampaign(true));
    dispatch(ReduxCampaignTarget.cleanReduxCampaignTarget(true));
    dispatch(ReduxCampaignTarget.setColumns([]));
    dispatch(ReduxCampaignList.cleanReduxCampaign(true));
    // Clear Rrdux Template
    dispatch(ReduxTemplate.cleanReduxTemplate(true));
    nav("/campaign/list");
    console.log("KELUAR clearAndExit FLO");
  };

  const { campaignId: paramCampaignId } = useParams();

  useEffect(() => {
    console.log("MASUK USEFFECT 1476 FLO");
    // Check Load Campaign / Creat Campaign
    let campaignId = campaignID;

    if (!campaignId) {
      if (currentCampaignTitle === "") {
        // console.log(`Check onCampaign Changes >> useEffect get campaignData - CampaignEditor ${newCampaignTitle}`)
        onCampaignChanges([{ key: "campaignName", value: newCampaignTitle }]);
        if (!isCreateTemplateFinish) {
          // console.log(`Check onCampaign Changes >> useEffect get campaignData - CampaignEditor dispatch`)
          dispatch(
            ReduxCampaign.setNewCampaignName(
              `${t("Campaign.Title.UntitledCampaign")}`
            )
          );
        }
      }
    }

    //query campaign -> set redux
    if (paramCampaignId && paramCampaignId !== "") {
      setIsLoading(true);
      console.log(`campaign id from link ${paramCampaignId}`);
      campaignId = paramCampaignId;
      dispatch(ReduxCampaign.setSelectedCampaignID(campaignId));

      console.log(`campaign load data ${paramCampaignId}`);
      const getCampaignData = async () => {
        if (campaignId !== "") {
          console.log(
            `getCampaignData 2 ${campaignId} ${
              campaign.id
            } campagin ${JSON.stringify(campaign)}`
          );
          if (!campaign || campaign.id !== campaignId) {
            // Get Campaign Data
            let selectedCampaign = campaignList.find((campaign: any) => {
              return campaign.id === campaignId;
            }) as Campaign;
            if (!selectedCampaign)
              selectedCampaign = (await servCampaign
                .getCampaignById(campaignId)
                .then((campaign) => {
                  console.log(`get campaign >> ${JSON.stringify(campaign)}`);

                  return campaign?.data;
                })
                .catch((error) =>
                  console.error(`Get campaign failed ${error}`)
                )) as Campaign;

            if (selectedCampaign.account) {
              const account = await selectedCampaign.account.get();
              selectedCampaign = {
                ...selectedCampaign,
                accountModel: { ...account.data(), id: account.id } as Account,
              };
              // data.accountModel = account.data();
            }
            if (selectedCampaign.company) {
              const company = await selectedCampaign.company.get();
              selectedCampaign = {
                ...selectedCampaign,
                companyModel: { ...company.data(), id: company.id } as Company,
              };
            }

            dispatch(
              ReduxCampaign.setAccountID(selectedCampaign.accountModel?.id)
            );
            dispatch(ReduxCampaign.setSelectedCampaign(selectedCampaign));
            return selectedCampaign;
          }
        }
      };

      getCampaignData()
        .then((campaign) => {
          console.log(`finally campaign loaded ${JSON.stringify(campaign)}`);

          if (campaign) {
            console.log(
              `finally campaign loaded 2 >> ${JSON.stringify(campaign)}`
            );

            let newCampaignData: any = {
              ...campaign,
              languages: i18n.language,
              createStatus: campaign.status,
            };

            // let loadedData:any = [
            //   {key: "languages", value:i18n.language},
            //   {key: "templateState", value:campaign.templateState},
            //   // {key: "companyId",value:campaign?.companyModel?.id}
            // ];
            if (campaign.campaignName) {
              newCampaignData = {
                ...newCampaignData,
                campaignName: campaign.campaignName,
              };
              // loadedData.push({key: "campaignName",value:campaign.campaignName});
              setNewCampaignTitle(campaign.campaignName);
              dispatch(ReduxCampaign.setNewCampaignName(campaign.campaignName));
            }
            // loadedData.push({key: "createStatus",value:campaign.status});
            if (campaign.targetFileURL) {
              newCampaignData = {
                ...newCampaignData,
                targetFileURL: campaign.targetFileURL,
              };
              // loadedData.push({key: "targetFileURL",value:campaign.targetFileURL})
            }
            if (campaign.template && campaign.template.templateID) {
              newCampaignData = {
                ...newCampaignData,
                templateId: campaign.template.templateID,
              };
              // loadedData.push({key: "templateId",value:campaign.template.templateID})
            }
            if (campaign.templateName) {
              newCampaignData = {
                ...newCampaignData,
                templateName: campaign.templateName,
              };
              // loadedData.push({key: "templateName",value:campaign.templateName})
            }
            if (campaign.template) {
              newCampaignData = {
                ...newCampaignData,
                template: campaign.template,
              };
              // loadedData.push({key: "template",value:campaign.template})
            }
            if (campaign.campaignSchedule) {
              newCampaignData = {
                ...newCampaignData,
                campaignSchedule: campaign.campaignSchedule,
              };
              // loadedData.push({key: "campaignSchedule",value:campaign.campaignSchedule})
            }
            // Channel, Company, Account
            if (campaign.company) {
              newCampaignData = {
                ...newCampaignData,
                companyId: campaign.company.id,
              };
              // loadedData.push({key: "companyId",value:campaign.company.id})
            }
            if (campaign.account) {
              newCampaignData = {
                ...newCampaignData,
                accountId: campaign.account.id,
              };
              // loadedData.push({key: "accountId",value:campaign.account.id})
            }
            if (campaign.accountModel) {
              const accountModel = campaign.accountModel;
              newCampaignData = {
                ...newCampaignData,
                channel: campaign.accountModel.type,
              };
              // loadedData.push({key:"channel",value:accountModel.type})
            }
            // onCampaignChanges(loadedData)
            console.log(dataCampaign, "reduxCampaignData before 1604 RCD");
            console.log(newCampaignData, "newCampaignData before 1604 RCD");
            dispatch(ReduxCampaign.setCampaignData(newCampaignData));
            console.log(dataCampaign, "reduxCampaignData 1604 RCD");
            onCampaignDataChange();

            // -------------- Campaign Data for Step 2
            // TemplateID, Header, HeaderParam, HeaderMedia

            let stepNumber = 4;
            if (
              campaign.status !== undefined &&
              campaign.campaignSchedule !== undefined
            ) {
              console.debug(`Punya rencana kirim`);
              stepNumber = 4;
            } else if (campaign.targetFileURL && campaign.template) {
              console.debug(`Punya target`);
              stepNumber = 4;
            } else if (!campaign.targetFileURL && campaign.template) {
              console.debug(`Punya template`);
              stepNumber = 3;
            } else if (campaign.templateState) {
              console.debug(`Punya state`);
              stepNumber = 2;
            } else {
              console.debug(`Punya judul`);
              stepNumber = 1;
            }

            setCurrentStep(stepNumber);
            onCurrentStepChange(stepNumber);
          }
        })
        .catch((error) => console.error(` get campaign error ${error}`))
        .finally(() => {
          setIsLoading(false);
        });
    }
    console.log("KELUAR USEFFECT 1476 FLO");
  }, []);

  // Company, Account, Channel
  // useEffect(() => {

  // }, [dataCampaign]);
  const onCampaignDataChange = () => {
    console.log("MASUK onCampaignDataChange FLO");
    console.log(
      "------------------>>> Campaign Data Final >>> " +
        JSON.stringify(dataCampaign)
    );
    console.log("use effect data campaign");
    console.log(`isClickSave : ${isClickSave}`);
    // dispatch(ReduxCampaign.setCampaignData(dataCampaign));
    // dispatch(ReduxCampaign.setAccountID(dataCampaign?.accountId));

    if (dataCampaign.template) {
      let jsonTemplate = dataCampaign.template;
      dispatch(
        ReduxCampaign.setNewTemplate(jsonTemplate as unknown as Template)
      );
    }

    if (dataCampaign.companyId && dataCampaign.companyId.toString() !== "") {
      document
        .getElementById(`saveChangeNewCampaign`)
        ?.classList.remove("disabled");
    }

    console.log("campaign data end final >> " + JSON.stringify(dataCampaign));
    console.log("KELUAR onCurrentStepChange FLO");
  };

  // Step and Data Changes
  // useEffect(() => {

  // }, [currentStep]);
  const onCurrentStepChange = (currentStep: number) => {
    console.log("MASUK onCurrentStepChange FLO");
    console.log(
      `Current Step Check: ${currentStep} - ${campaignID} - ${selectedCampaign.template}`
    );

    document
      .getElementById(`step_${currentStep}`)
      ?.classList.remove("disabled");
    document.getElementById(`step_${currentStep}`)?.click();
    document.getElementById(`step_${currentStep}`)?.classList.add("disabled");

    if (currentStep === 1) {
      console.log("CEK CURRENT STEP A1");
      if (campaignID && selectedCampaign.templateState) {
        document.getElementById("nextStep")?.classList.remove("disabled");
      }
    } else if (currentStep === 2) {
      console.log("CEK CURRENT STEP A2");
      if (campaignID && selectedCampaign.template) {
        document.getElementById("nextStep")?.classList.remove("disabled");
      }
      if (!campaignID && (!templateID || templateID === "")) {
        console.log("masuk sini kah currstep poc");
        document.getElementById("nextStep")?.classList.remove("disabled");
      }
    } else if (currentStep === 3) {
      console.log("CEK CURRENT STEP A3");
      if (campaignID && selectedCampaign.targetFileURL) {
        document.getElementById("nextStep")?.classList.remove("disabled");
      }
      if (campaignTargetCount === 0) {
        document.getElementById("nextStep")?.classList.add("disabled");
      }
      // dispatch(ReduxCampaignTarget.setPreviewData(campaignTargetPreviewData));
      console.log(
        "isi dari previewData: " + JSON.stringify(campaignTargetPreviewData)
      );
      let check: boolean = reduxCampaignState === campaignState.New;
      console.log("apakah true? " + check);
      console.log(
        "RTGeneratedLanguage >>> " + JSON.stringify(RTGeneratedLanguage)
      );
      console.log(RTBodyParams, "rtbod");
      console.log(RTGeneratedLanguage, "rtbod genlang");
      if (reduxCampaignState === campaignState.New) {
        console.log(
          `Check onCampaign Changes >> useEffect currentStep - CampaignEditor`
        );
        if (RCDFailover !== undefined) {
          onCampaignChanges([
            { key: "templateId", value: RTTemplateID },
            { key: "template", value: RTGeneratedTemplate },
            { key: "language", value: RTGeneratedLanguage },
            { key: "bodyParam", value: reduxCampaignData.bodyParam },
            { key: "header", value: RTHeaderParams },
            { key: "templateState", value: campaignState.New },
            { key: "buttons", value: RCButtons },
            { key: "failover", value: RCDFailover },
          ]);
        } else {
          onCampaignChanges([
            { key: "templateId", value: RTTemplateID },
            { key: "template", value: RTGeneratedTemplate },
            { key: "language", value: RTGeneratedLanguage },
            { key: "bodyParam", value: reduxCampaignData.bodyParam },
            { key: "header", value: RTHeaderParams },
            { key: "templateState", value: campaignState.New },
            { key: "buttons", value: RCButtons },
          ]);
        }
      }
    } else if (currentStep === 4) {
      console.log("CEK CURRENT STEP A4");
      if (campaignID && selectedCampaign.template) {
        document.getElementById("nextStep")?.classList.remove("disabled");
      }
      if (!campaignID && (!templateID || templateID === "")) {
        document.getElementById("nextStep")?.classList.add("disabled");
      }
      // if (dataCampaign.campaignSchedule) {
      //   document.getElementById("nextStep")?.classList.remove("disabled");
      //   document
      //     .getElementById("warning-schedule")
      //     ?.setAttribute("style", "display:none");
      // } else {
      //   document.getElementById("nextStep")?.classList.add("disabled");
      // }
    } else if (currentStep === 5) {
      console.log("CEK CURRENT STEP A5");
      if (dataCampaign.campaignSchedule) {
        document.getElementById("nextStep")?.classList.remove("disabled");
        document
          .getElementById("warning-schedule")
          ?.setAttribute("style", "display:none");
      } else {
        document.getElementById("nextStep")?.classList.add("disabled");
      }
    }
    console.log("KELUAR onCurrentStepChange FLO");
  };

  console.log(`Campaign Edit: ${JSON.stringify(selectedCampaign)}`);

  console.log(isButtonNextDisabled, "is disable cx");

  useEffect(() => {
    console.log("MASUK USEFFECT !RCIsCampaignDynamicURLValid FLO");
    
    //remove spinner
    const getSpinner = document.getElementById("nextStep");
    console.log(`getSpinner : `, getSpinner);
    if (!RCIsCampaignDynamicURLValid && getSpinner) {
      setTimeout(() => {
        getSpinner.removeAttribute("data-kt-indicator");
      }, 200);
    }
    console.log("KELUAR USEFFECT !RCIsCampaignDynamicURLValid FLO");
  }, [!RCIsCampaignDynamicURLValid]);

  console.log("CEK CURRENT STEP", {
    currentStep,
    campaignID,
    status: selectedCampaign.status,
    templateID,
  });

  console.log("DATA_SCHEDULE_CAMPAIGN", dataCampaign.schedule);
  console.log("CHECK BUTTON CONDITION", isButtonNextDisabled);
  // console.log(dataCampaign.campaignID, "CAMPAIGNID sacha");
  // console.log(dataCampaign.accountId, "ACCOUNTID sacha");
  // console.log(dataCampaign.companyId, "COMPANYID sacha");
  // console.log(dataCampaign.RCAccountID, "RCAACCOUNTID sacha");
  // ------------------ UI ------------------ //
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {t("Campaign.Button.CreateCampaign")}
      </PageTitle>
      {!isClickSave &&
        ((campaignID !== undefined &&
          campaignID !== "" &&
          selectedCampaign.status?.toUpperCase() !== "PROCESSED" &&
          selectedCampaign.status?.toUpperCase() !== "ON_REPETITION" &&
          selectedCampaign.status?.toUpperCase() !== "SCHEDULED") ||
          selectedCampaign.status?.toUpperCase() !== "CREATED") && (
          <ModalConfirmationMoveToOtherPage
            isDirty={
              //if button save is not disabled
              !document
                .getElementById("saveChangeNewCampaign")
                ?.classList.contains("disabled") && RCAccountID !== ""
            }
            path="/campaign/create"
            secondPath={currentStep === 5 ? `/campaign` : "/template/create"}
            onSave={async () => {
              await onNewCampaignUpdate();
              newCampaignTitle !== t("Campaign.Title.UntitledCampaign") &&
                clearAndExit();
            }}
            onUnsave={() => clearAndExit()}
          />
        )}
      <div className="d-flex flex-column flex-lg-column h-100">
        <ModalConfirmation
          id="finishCampaignModal"
          body={t("Campaign.Alert.ReadOnly")}
          title={t("Template.Title.Confirm")}
          onConfirm={() => {
            nav("/campaign/list");
          }}
          confirmOption={t("Campaign.Button.Return")}
          cancelOption={t("CD.Button.Cancel")}
        />

        {/* Create Campaign Header */}
        <div
          className={clsx(
            "card mb-1 mb-xl-2 p-3 d-flex space",
            isTabletOrMobile ? "flex-column" : "flex-row"
          )}
        >
          {isTabletOrMobile && <CampaignMobileIcon />}
          <div>
            {!isInsertTitle && (
              <div
                className={clsx(
                  "btn p-2 d-flex flex-row align-items-center",
                  isTabletOrMobile ? "mt-3" : ""
                )}
                onClick={() => {
                  setIsInsertTitle(true);
                  setIsCampaignExist(false);
                }}
                data-testid="campaign-name-title"
                role="campaign-name-title"
              >
                {/* Edit Campaign */}
                {campaignID && (
                  <h1 id="newCampaignTitle" className="mb-0">
                    {selectedCampaign.campaignName}
                  </h1>
                )}
                {/* New Campaign */}
                {!campaignID && newCampaignTitle !== "" && (
                  <h1 id="newCampaignTitle" className="mb-0">
                    {newCampaignTitle}
                  </h1>
                )}
                {!campaignID && newCampaignTitle === "" && (
                  <h1 id="newCampaignTitle" className="mb-0">
                    {t("Campaign.Title.UntitledCampaign")}
                  </h1>
                )}
                <h1 id="newCampaignTitle" style={{ display: "none" }}>
                  {currentCampaignTitle}
                </h1>
                <i
                  className="bi bi-pencil-fill fs-3 align-items-center ms-2 me-2"
                  // onClick={() => {setIsInsertTitle(true); setIsCampaignExist(false);}}
                ></i>
              </div>
            )}

            {isInsertTitle && (
              <div className="d-flex flex-row mb-3 mt-5">
                <input
                  ref={campaignTitleRef}
                  type="text"
                  className="form-control form-control-solid w-200px"
                  placeholder={t("Campaign.Info.UntitledCampaign")}
                  // onChange={(event) => onCampaignTitleChanges(event?.target.value)}
                  data-testid="input-campaign-name"
                  role="input-campaign-name"
                />
                <input
                  type="submit"
                  value="OK"
                  className="form-control form-control-solid w-50px ms-3"
                  onClick={changeTitle}
                  data-testid="submit-campaign-name"
                  role="submit-campaign-name"
                />
              </div>
            )}
            {!isCampaignExist &&
              newCampaignTitle === t("Campaign.Title.UntitledCampaign") && (
                <div className="p-2 pt-0 pb-1">
                  {t("Campaign.Info.UntitledCampaign")}
                </div>
              )}
            {isCampaignExist && (
              <div className="p-2 pt-0 text-danger">
                {t("Campaign.Error.UntitledCampaign")}
              </div>
            )}
          </div>
          {!isTabletOrMobile && (
            <div className="align-self-center">
              <button
                data-testid="btn-savechanges"
                // disabled={
                //   // New Campaign
                //   !campaignID &&
                //   (((!dataCampaign.accountId ||
                //     dataCampaign.accountId.toString() === "") &&
                //     (!dataCampaign.companyId ||
                //       dataCampaign.companyId.toString() === "")) ||
                //     RCAccountID === "")
                // }
                ref={saveButtonRef}
                id="saveChangeNewCampaign"
                type="button"
                className={clsx("btn btn-dark me-3")}
                onClick={async () => {
                  setIsClickSave(true);
                  setIsLoading(true);
                  await onNewCampaignNewTemplate();
                  await onNewCampaignUpdate();
                  setIsLoading(false);
                  newCampaignTitle !== t("Campaign.Title.UntitledCampaign") &&
                    clearAndExit();
                }}

                // data-kt-indicator="on"
              >
                <span className="indicator-label">
                  {t("CD.Button.SaveChanges")}
                </span>
                <span className="indicator-progress">
                  {t("CD.Button.SaveChanges")}...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                {/* {t("CD.Button.SaveChanges")} */}
              </button>
            </div>
          )}
        </div>

        {/* Create Campaign Step */}
        <div className={clsx("overflow-hidden card mb-1 mb-xl-2 p-3 h-100")}>
          <div
            className={clsx({ "w-100": isTabletOrMobile })}
            style={{
              height: "90%",
              overflowY: isTabletOrMobile ? "unset" : "scroll",
            }}
          >
            {/* Step Tab */}

            <ul
              className={clsx("nav nav-tabs nav-line-tabs mb-5 fs-6", {
                "d-none": isTabletOrMobile,
              })}
            >
              <li className="nav-item">
                <a
                  className="nav-link disabled"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  id="step_1"
                >
                  {t("Campaign.Tab.SetupCampaign")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link disabled"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  id="step_2"
                >
                  {t("Campaign.Tab.Content")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link disabled"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                  id="step_3"
                >
                  {t("Campaign.Tab.Target")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link disabled"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                  id="step_4"
                >
                  {t("Campaign.Tab.Failover")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link disabled"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_5"
                  id="step_5"
                >
                  {t("Campaign.Tab.SubmitCampaign")}
                </a>
              </li>
            </ul>
            {/* Step Details */}
            <div className="flex-lg-column-fluid tab-content" id="myTabContent">
              {/* Step 1 */}
              {isCreateTemplateFinish !== true && (
                <CampaignChannel
                  onChanges={onCampaignChanges}
                  campaign={selectedCampaign}
                />
              )}
              {/* <CampaignChannel onChanges={await onCampaignChanges} campaign={selectedCampaign}/> */}
              {/* Step 2 */}
              {/* {(isCreateTemplateFinish || !isBackFromTemplate) && ( */}
              <CampaignTemplate
                onChanges={onCampaignChanges}
                campaign={selectedCampaign}
                templateState={
                  selectedCampaign && selectedCampaign.templateState
                    ? selectedCampaign.templateState
                    : dataCampaign.templateState
                }
              />
              {/* )} */}
              {/* <CampaignTemplate onChanges={onCampaignChanges} campaign={selectedCampaign} templateState={dataCampaign.templateState}/> */}
              {/* Step 3 */}
              <CampaignTarget onChanges={onCampaignChanges} />
              {/* Step 4 */}
              <CampaignFailover
                onChanges={onCampaignChanges}
                validateInputHandler={(isValid) => {
                  console.log(isValid, "ISI ISVALID dzdz");
                  console.log(
                    isButtonNextDisabled,
                    "ISBUTTONNEXTDISABLED dzdz"
                  );

                  setIsButtonNextDisabled(!isValid);
                }}
              />
              {/* Step 5 */}
              <CampaignSubmission onChanges={onCampaignChanges} />
            </div>
            {isTabletOrMobile && (
              <div className="align-self-center">
                <button
                  disabled={
                    // New Campaign
                    !campaignID &&
                    (!dataCampaign.accountId ||
                      dataCampaign.accountId.toString() === "") &&
                    (!dataCampaign.companyId ||
                      dataCampaign.companyId.toString() === "")
                  }
                  ref={saveButtonRef}
                  id="saveChangeNewCampaign"
                  type="button"
                  className={clsx("btn btn-primary me-3")}
                  onClick={async () => {
                    setIsClickSave(true);
                    setIsLoading(true);
                    await onNewCampaignNewTemplate();
                    await onNewCampaignUpdate();
                    setIsLoading(false);
                    newCampaignTitle !== t("Campaign.Title.UntitledCampaign") &&
                      clearAndExit();
                  }}

                  // data-kt-indicator="on"
                >
                  <span className="indicator-label">
                    {t("CD.Button.SaveChanges")}
                  </span>
                  <span className="indicator-progress">
                    {t("CD.Button.SaveChanges")}...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                  {/* {t("CD.Button.SaveChanges")} */}
                </button>
              </div>
            )}
          </div>
          {/* <div className="d-flex space mb-5 mt-10"> */}
          <div
            className="d-flex space mb-5 mt-10"
            style={{ justifyContent: "space-between" }}
          >
            <button
              ref={prevButtonRef}
              id="prevStep"
              type="button"
              className="btn btn-primary"
              onClick={() => onPrevNextClicked("prev")}
              data-testid="back-button"
              disabled={
                isCreateTemplateFinish === true && currentStep === 2
                  ? true
                  : false
              }
            >
              {t("Campaign.Button.Back")}
            </button>

            {currentStep === 1 && (
              <button
                // disabled={
                //   // (currentStep === 3 && campaignTargetPreviewData.length === 0) ||
                //   // (currentStep === 2 && selectedCampaign.templateState?.length === 0) ||
                //   //   ? true
                //   //   : false ||
                //   //   !
                //   isButtonNextDisabled
                // }
                disabled={
                  // New Campaign
                  (!campaignID &&
                    (!dataCampaign.channel ||
                      dataCampaign.channel.toString() === "")) ||
                  !dataCampaign.companyId ||
                  dataCampaign.companyId.toString() === "" ||
                  !dataCampaign.templateState ||
                  dataCampaign.templateState.toString() === ""
                }
                ref={nextButtonRef}
                // id="nextStep"
                type="button"
                className="btn btn-primary"
                onClick={() => onPrevNextClicked("next")}
                data-testid="next-button"
              >
                <span className="indicator-label">
                  {t("Campaign.Button.Continue")}
                </span>
                <span className="indicator-progress">
                  {t("Campaign.Button.Continue")}
                  <span
                    className="spinner-border spinner-border-sm align-middle ms-2"
                    id="spinner"
                  ></span>
                </span>
              </button>
            )}
            {currentStep === 2 && (
              <button
                disabled={currentStep === 2 && templateID === ""}
                ref={nextButtonRef}
                id="nextStep"
                type="button"
                className="btn btn-primary"
                onClick={() => onPrevNextClicked("next")}
                data-testid="next-button"
              >
                <span className="indicator-label">
                  {t("Campaign.Button.Continue")}
                </span>
                <span className="indicator-progress">
                  {t("Campaign.Button.Continue")}
                  <span
                    className="spinner-border spinner-border-sm align-middle ms-2"
                    id="spinner"
                  ></span>
                </span>
              </button>
            )}
            {currentStep === 3 && (
              <button
                disabled={
                  currentStep === 3 && campaignTargetPreviewData.length === 0
                }
                ref={nextButtonRef}
                id="nextStep"
                type="button"
                className="btn btn-primary"
                onClick={() => onPrevNextClicked("next")}
                data-testid="next-button"
              >
                <span className="indicator-label">
                  {t("Campaign.Button.Continue")}
                </span>
                <span className="indicator-progress">
                  {t("Campaign.Button.Continue")}
                  <span
                    className="spinner-border spinner-border-sm align-middle ms-2"
                    id="spinner"
                  ></span>
                </span>
              </button>
            )}
            {currentStep === 4 && (
              <button
                disabled={
                  currentStep === 4 &&
                  dataCampaign.failover &&
                  isButtonNextDisabled
                }
                ref={nextButtonRef}
                id="nextStep"
                type="button"
                className="btn btn-primary"
                onClick={() => onPrevNextClicked("next")}
                data-testid="next-button"
              >
                <span className="indicator-label">
                  {t("Campaign.Button.Continue")}
                </span>
                <span className="indicator-progress">
                  {t("Campaign.Button.Continue")}
                  <span
                    className="spinner-border spinner-border-sm align-middle ms-2"
                    id="spinner"
                  ></span>
                </span>
              </button>
            )}
            {currentStep === 5 &&
              selectedCampaign.status !== undefined &&
              selectedCampaign.status !== "CREATED" && (
                <button
                  ref={submitButtonRef}
                  disabled={
                    !dataCampaign.campaignSchedule ||
                    dataCampaign.campaignSchedule.toString() === ""
                  }
                  id="nextStep"
                  type="button"
                  data-testid="submit-campaign"
                  className="btn btn-primary disabled"
                  data-bs-toggle="modal"
                  data-bs-target="#finishCampaignModal"
                  onClick={() => onPrevNextClicked("next")}
                >
                  <span className="indicator-label">
                    {t("ForgotPassword.Button.Submit")}
                  </span>
                  <span className="indicator-progress">
                    {t("ForgotPassword.Button.Submit")}...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              )}
            {currentStep === 5 &&
              selectedCampaign.status !== undefined &&
              selectedCampaign.status === "CREATED" && (
                <button
                  ref={submitButtonRef}
                  disabled={
                    !dataCampaign.campaignSchedule ||
                    dataCampaign.campaignSchedule.toString() === ""
                  }
                  id="nextStep"
                  type="button"
                  data-testid="submit-campaign"
                  className="btn btn-primary disabled"
                  onClick={async () => {
                    console.log(`Check submitButtonRef clicked`);

                    await setCampaignStatus();
                    await submitCampaignHandler();
                  }}
                  // disabled={selectedCampaign.status !== undefined && selectedCampaign.status !== "CREATED"}
                >
                  <span className="indicator-label">
                    {t("ForgotPassword.Button.Submit")}
                  </span>
                  <span className="indicator-progress">
                    {t("ForgotPassword.Button.Submit")}...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              )}
            {currentStep === 5 && selectedCampaign.status === undefined && (
              <button
                ref={submitButtonRef}
                disabled={
                  !dataCampaign.campaignSchedule ||
                  dataCampaign.campaignSchedule.toString() === ""
                }
                id="nextStep"
                type="button"
                data-testid="submit-campaign"
                className="btn btn-primary disabled"
                onClick={async () => {
                  console.log(`Check submitButtonRef clicked`);
                  setIsClickSave(true);
                  await setCampaignStatus();
                  await submitCampaignHandler();
                }}
                // disabled={selectedCampaign.status !== undefined && selectedCampaign.status !== "CREATED"}
              >
                <span className="indicator-label">
                  {t("ForgotPassword.Button.Submit")}
                </span>
                <span className="indicator-progress">
                  {t("ForgotPassword.Button.Submit")}...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
      {(isLoading || isLoadingCampaign) && <Loading />}
    </>
  );
};

export default CampaignEditor;
