import { Fragment, useMemo } from "react";
import { useTable, ColumnInstance, Row } from "react-table";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from "../core/QueryResponseProvider";
import { templateColumns } from "./columns/_columns";
import Template from "../../../../models/Template";
import Loading from "../../../../../resources/helpers/components/Loading";
import { TemplatePagination } from "../components/pagination/TemplatePagination";
import { KTCardBody } from "../../../../../resources/helpers";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import CardItem from "../../../../components/table/list/cardList/CardItem";

const TemplateTable = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const templates = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => templates, [templates]);
  const columns = useMemo(() => templateColumns, []);

  console.log(data, "templatetable wxx");
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return !isTabletOrMobile ? (
    <KTCardBody className="py-4 h-100 overflow-hidden">
      <div
        className="table-responsive"
        style={{
          height: "90%",
          overflowY: "scroll",
        }}
      >
        <table
          id="kt_table_users"
          data-testid="template-table"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead
            className="position-sticky top-0 bg-white"
            style={{ zIndex: 100 }}
          >
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
              {headers.map((column: ColumnInstance<Template>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody
            className="p-0 m-0 text-gray-600 fw-bold"
            {...getTableBodyProps()}
          >
            {rows.length > 0
              ? rows.map((row: Row<Template>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                })
              : !isLoading && (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        {t("Contacts.Info.Empty")}
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
      <TemplatePagination />
      {isLoading && <Loading />}
    </KTCardBody>
  ) : (
    <>
      <div className="px-5 h-100 d-flex flex-column gap-5 pb-7 mt-5">
        {data.length === 0 && (
          <div className="d-flex text-center w-100 align-content-center justify-content-center">
            {t("Contacts.Info.Empty")}
          </div>
        )}
        {data.map((template, index) => (
          <Fragment key={template.id?.toString()}>
            <CardItem
              id={template.id}
              name={template.templateName}
              isContactList={false}
              isStorefrontList={false}
              isProductList={false}
              isTemplateList={true}
              index={index}
              templateData={template}
            />
          </Fragment>
        ))}
      </div>
      <TemplatePagination />
      {isLoading && <Loading />}
    </>
  );
};

export { TemplateTable };
