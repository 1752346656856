import db, { createRef } from ".";
import { Collaboration, CollabsStatus, Room } from "../app/models/Chat";
import { converter2 } from "./converter";
import User, { UserCompanies } from "../app/models/User";
import * as lc from "../app/modules/localstorage";
import { arrayUnion } from "firebase/firestore";

export const getRoomByCollaborationId = async (
  collaborationDocId: string,
): Promise<Room[] | undefined> => {
  try {
    const collaboration = await db
      .collection("collaborations")
      .doc(collaborationDocId)
      .collection("rooms")
      .withConverter(converter2<Room>())
      .get();
    console.log("CIQNIH collaboration", collaboration);
    if (collaboration.empty) {
      return;
    }
    return collaboration.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  } catch (error) {
    console.error(error);
    return;
  }
};

export const findRoomById = async (
  collaborationDocId: string,
  roomsId: string
) => {
  try {
    const collaboration = await db
      .collection("collaborations")
      .doc(collaborationDocId)
      .collection("rooms")
      .doc(roomsId)
      .withConverter(converter2<Room>())
      .get();
    if (collaboration.exists) {
      return { ...collaboration.data(), id: collaboration.id };
    }
    return;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getQueuedRooms = async (collaborationId?:string): Promise<(Collaboration & Room)[]> => {
  try {
    const clientRef = lc.getClientRef();
    const queuedRooms = await db
      .collectionGroup("rooms")
      .where("status", "==", "queue")
      .where("client", "==", clientRef)
      .orderBy("updatedAt", "asc")
      .withConverter(converter2<Room>())
      .get();

    console.log({ queuedRooms });
    if (queuedRooms.empty) {
      return [];
    }

    const data = queuedRooms.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      collaborationId: doc.ref.parent.path.split("/")[1] ?? "",
    }));
    // console.log("queuedRooms", data, clientRef?.path);
    const collaborationRefs = data
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((room) => room.collaborationId);
    const collaborations = await Promise.all(
      collaborationRefs.map(async (collaborationId) => {
        const collaboration = await db
          .collection("collaborations")
          .doc(collaborationId)
          .withConverter(converter2<Collaboration>())
          .get();
        return { ...collaboration.data(), id: collaboration.id };
      })
    );
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    // data.filter((item) => {
    //   if (item.accountData.type === "whatsapp" && item.lastCustomerInteractionAt) {
    //     const lastInteractionDate = new Date(item.lastCustomerInteractionAt.toDate()); // Ubah Timestamp menjadi objek Date
    //     return (
    //       lastInteractionDate >= yesterday &&
    //       lastInteractionDate <= today
    //     );
    //   }
    // });
    console.log(data, "ini adalah data cees");
    return data
      .filter((item) => {
        if (
          item?.accountData?.type === "whatsapp" &&
          item?.lastCustomerInteractionAt
        ) {
          const lastInteractionDate = new Date(
            item?.lastCustomerInteractionAt.toDate()
          );
          return (
            lastInteractionDate >= yesterday && lastInteractionDate <= today
          );
        } else {
          return item
        }
      })
      .map((room) => {
        const collaboration = collaborations.find(
          (collaboration) => collaboration?.id === room.collaborationId
        );
        return { ...collaboration, ...room } as Collaboration & Room;
      });
  } catch (error) {
    console.log("error fetching queueRooms", error);
    return [];
  }
};

export const setStatusToHandled = async (
  collaborationId: string,
  roomId: string,
  handledUserId: string
) => {
  console.log(
    "==> masuk Room setStatusToHandled balezzz",
    collaborationId,
    roomId,
    handledUserId
  );
  try {
    const handleUserRef = createRef("users", handledUserId);
    await db
      .collection("collaborations")
      .doc(collaborationId)
      .collection("rooms")
      .doc(roomId)
      .update({
        status: "handled",
        handledByUser: handleUserRef,
        toUser: arrayUnion(handleUserRef),
        toUserOnline: arrayUnion(handleUserRef)
      });
  } catch (error) {
    console.error("Error setStatusToHandled", error);
  }
};

export const fetchAccessibleRoom = async (
  clientId: string,
  companyAccess: UserCompanies[]
) => {
  let query = db.collection("rooms").withConverter(converter2<Room>());

  const hasAllCompanyAccess = companyAccess.find(
    (accessCompany) => accessCompany.id === "*"
  );
  // if user does not have access to all company, get by company
  if (!hasAllCompanyAccess) {
    const companyAccessQuery = companyAccess.map((company) =>
      createRef("company", company.id)
    );
    query
      .where("company", "in", companyAccessQuery)
      .where("client", "==", createRef("clients", clientId));
    // .where("isHandled", "==", false)
  }

  return await query
    .get()
    .then((snaps) => {
      console.log("Hasil query:", snaps.docs);
      console.log(`found ${snaps.empty}`);

      const collabs = snaps.docs.map(
        (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as Room)
      );
      return collabs;
    })
    .catch((error) => {
      //TODO
      console.error("Erorr fetchAccessibleRoom : " + error);
      return [] as Room[];
    });
};
