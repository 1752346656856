import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { PageLink, PageTitle } from "../core/PageData";
import { CampaignReportWrapper } from "./campaign-report/CampaignReport";
import { CampaignReportDetailWrapper } from "./campaign-report/campaign-report-details/CampaignReportDetail";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Campaign from "../../../db/serviceCampaign";
import { RootState } from "../../../setup/redux/store";
import * as ReduxCampaignReport from "../../modules/campaign/report/CampaignReportSlice";
import { LCName, getItemLC } from "../../modules/localstorage";
import * as Log from "src/util/SDayslogger"

const CampaignReportPage = () => {
  Log.debug(`Campaign Report Page`);
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Campaign.Title.Campaign"),
      path: "/campaign/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const campaignList = useSelector(
    (state: RootState) => state.CampaignList.listCampaign
  );
  const selectedCampaign = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaign
  );

  const { campaignId } = useParams();
  Log.debug(`Campaign Report Page param ${campaignId}`);

  useEffect(() => {
    if (!campaignId) {
      return navigate("/campaign/list");
    }
    dispatch(ReduxCampaignReport.setSelectedCampaignID(campaignId));
  }, [campaignId]);

  const [storageFailover, setStorageFailover] = useState<any[]>([]);

  useEffect(() => {
    Log.debug("Use Effect Event Handler");
    function storageEventHandler(event: any) {
      Log.debug("event CampaignReportPage:", JSON.stringify(event));
      setStorageFailover(getItemLC(LCName.Failover));
    }

    window.addEventListener("storageFailover", storageEventHandler);

    return () => {
      window.removeEventListener("storageFailover", storageEventHandler);
    }
  }, []);

  if (!campaignId) {
    return <Navigate to="/campaign/list" />;
  }

  // if (campaignId)
  // {
  //   let campaign
  //   if (campaignList)
  //     campaign = campaignList.find(campaign => campaign.id === campaignId);
  //   else
  //     console.log("no campaign list redux");

  //   console.log(`Clicked Campaign ID: ${campaignId}
  //     Data: ${JSON.stringify(campaign)}
  //     Selected Campaign ${JSON.stringify(selectedCampaign)} `);

  //   // Set Selected Campaign ID and Campaign in Redux
  //   if (campaignId && campaign) {
  //     // Set Campaign Data
  //     dispatch(ReduxCampaignReport.setSelectedCampaignID(campaignId));
  //     dispatch(ReduxCampaignReport.setSelectedCampaign(campaign));

  //   }
  // };

  return (
    // <Routes>
    //   <Route element={<Outlet />}>
    //     <Route
    //       path="details"
    //       element={
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {t("DLR.Title.CampaignReport")}
      </PageTitle>
      <div className="h-100" style={{ overflowY: "scroll" }}>
        <CampaignReportWrapper />
        <CampaignReportDetailWrapper />
      </div>
    </>
    //       }
    //     />
    //   </Route>
    //   <Route index element={<Navigate to="/campaign/report/details" />} />
    // </Routes>
  );
};

export default CampaignReportPage;
