import db, { Timestamp } from ".";
import * as conn from "./connection";
import { converter2 } from "./converter";
import { createRef } from "./connection";
import Reconcile from "../app/models/Reconcile";
import moment from "moment";
import * as Log from "src/util/SDayslogger"

export const getConversationAnalyticByFilter = async (
  start: Date,
  end: Date,
  granularity: string,
  category: string[],
  phoneNumber: string[],
) => {
  if(!start || !end || !granularity || !category || category.length === 0 || !phoneNumber || phoneNumber.length === 0 ) return undefined;
  console.log(`getConversationAnalyticByFilter with param :` , {
    start,end,granularity,category,phoneNumber
  });

  // const startFormatted = Timestamp.fromDate(new Date(+start * 1000));
  // const endFormatted = Timestamp.fromDate(new Date(+end * 1000));

  const startFormatted = Timestamp.fromDate(start);
  const endFormatted = Timestamp.fromDate(end);
  Log.info(`FETCHIING SERVICE ANALYTICS - range start : ${startFormatted} end : ${endFormatted}`)
  return await db
    .collection("reconciliations")
    .withConverter(converter2<Reconcile>())
    .where("mutationStartDate", ">=", startFormatted)
    .where("mutationStartDate", "<=", endFormatted)
    .where("granularity", "==", "HALF_HOUR")
    // .where("conversationCategory", "in", category)
    .where("wabaPhoneNumber", "in", phoneNumber)
    .get()
    .then((snapshot) => {
      
      const conversationAnalytics = snapshot.docs.map((doc) => {
        Log.debug(`snapshot ${snapshot}`);
        return { ...doc.data(), id: doc.id };
      });

      Log.info("getAnalytic: ", conversationAnalytics.length);
      return conversationAnalytics;
    })
    .catch((error) => {
      Log.error(`get mutation info: ${error}`)
    })
};