import { reduxBatch } from "@manaflair/redux-batch";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import ApiSettingSlice from "../../app/modules/api-setting/redux/ApiSettingSlice";
import AuthSlice from "../../app/modules/auth/redux/AuthSlice";
import CampaignListSlice from "../../app/modules/campaign/list/CampaignListSlice";
import CampaignMessageSlice from "../../app/modules/campaign/message/CampaignMessageSlice";
import CampaignSlice from "../../app/modules/campaign/redux/CampaignSlice";
import CampaignReportSlice from "../../app/modules/campaign/report/CampaignReportSlice";
import CampaignTargetSlice from "../../app/modules/campaign/target/redux/CampaignTargetSlice";
import ChatSlice from "../../app/modules/chat/redux/ChatSlice";
import CIQSlice from "../../app/modules/ciq/redux/CIQSlice";
import CompanySlice from "../../app/modules/company/redux/CompanySlice";
import ContactSlice from "../../app/modules/contact/redux/ContactSlice";
import MarketplaceProductCategorySlice from "../../app/modules/product/marketplaceProductCategory/marketplaceProductCategorySlice";
// import MarketplaceActionCell from "../../app/layout/user-menu/settings/Marketplace/marketplace-list/components/table/columns/ActionCell";
import ProductListSlice from "../../app/modules/product/productList/ProductListSlice";
import ProductSlice from "../../app/modules/product/redux/ProductSlice";
import ShipmentsSlice from "../../app/modules/product/shipment/redux/ShipmentsSlice";
import StorefrontSlice from "../../app/modules/product/storefront/StorefrontSlice";
import StorefrontListSlice from "../../app/modules/product/storefront/storefrontList/StorefrontListSlice";
import SyncSlice from "../../app/modules/order/SyncSlice";
import TemplateListSlice from "../../app/modules/template/list/TemplateListSlice";
import TemplateSlice from "../../app/modules/template/redux/TemplateSlice";
import WABAAccountSlice from "../../app/modules/waba-accounts/redux/WABAAccountSlice";
import WABAAccountSignUpSlice from "../../app/modules/waba-accounts/redux/data/WABAAccountSignUpSlice";
import ModalExportOrderSlice from "../../app/components/modal/ModalExportOrder/redux/ModalExportOrderSlice";
import RegisterSlice from "../../app/modules/auth/redux/RegisterSlice";
import AddMemberSlice from "../../app/modules/auth/redux/AddMemberSlice";
import EventSlice from "../../app/modules/chat/redux/EventSlice"

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducerChat = persistReducer(persistConfig, ChatSlice);
const persistedReducerCIQ = persistReducer(persistConfig, CIQSlice);
// const persistedReducerCampaign = persistReducer(persistConfig, CampaignSlice)
const persistedReducerMarketplaceProductCategory = persistReducer(persistConfig, MarketplaceProductCategorySlice);
const persistedReducerShipments = persistReducer(persistConfig, ShipmentsSlice);

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
    /*
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },*/
  }),
  sagaMiddleware,
];

const store = configureStore({
  reducer: {
    AddMember: AddMemberSlice,
    ApiSetting: ApiSettingSlice,
    Auth: AuthSlice,
    Chat: persistedReducerChat,
    Company: CompanySlice,
    CIQ: persistedReducerCIQ,
    // Campaign: persistedReducerCampaign,
    Campaign: CampaignSlice,
    CampaignTarget: CampaignTargetSlice,
    CampaignReport: CampaignReportSlice,
    CampaignMessage: CampaignMessageSlice,
    Contact: ContactSlice,
    CampaignList: CampaignListSlice,
    TemplateList: TemplateListSlice,
    Template: TemplateSlice,
    Storefront: StorefrontSlice,
    MarketplaceProductCategory: persistedReducerMarketplaceProductCategory,
    Product: ProductSlice,
    ProductList: ProductListSlice,
    Registration: RegisterSlice,
    StorefrontList: StorefrontListSlice,
    Shipments: persistedReducerShipments,
    SyncOrder: SyncSlice,
    WABAAccountsList: WABAAccountSlice,
    WABAAccountSignUp: WABAAccountSignUpSlice,
    // For Components
    ModalExportOrder: ModalExportOrderSlice,
    //for firebase event
    FirebaseEvent: EventSlice,
  },
  middleware,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch],
});

export default store;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
