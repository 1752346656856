import { useEffect, useRef, useState } from "react";
import { getClientRef } from "../../modules/localstorage";
import { KTSVG, toAbsoluteUrl } from "../../../resources/helpers";
import { Manager } from "socket.io-client";
import clsx from "clsx";
import SVG from "react-inlinesvg"
import * as Log from "src/util/SDayslogger"

type UserItem = {
  isSidebarMinimized: boolean;
};

const UserBalance= ({ isSidebarMinimized }: UserItem) => {
  const iconBillingPath = "/media/icons/duotune/finance/fin008.svg";
  const [balance, setBalance] = useState<number>(0);
  const [creditLimit, setCreditLimit] = useState<number>(0);
  const [billingType, setBillingType] = useState<string>("");
  const [isShouldDisplayBalance, setIsShouldDisplayBalance] =
    useState<boolean>(false);
  const divRef = useRef<HTMLDivElement | null>(null);

  const formatNumberToIDR = (number: number) => {
    if (number >= 1000000) {
      return `IDR ${(number / 1000000).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })} M`;
    } else {
      const parsedNumber = Math.abs(number);
      return number < 0
        ? `IDR (${parsedNumber.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })})`
        : `IDR ${parsedNumber.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
    }
  };

  useEffect(() => {
    const clientRef = getClientRef();
    const clientId = clientRef?.id ?? "";

    const manager = new Manager(process.env.REACT_APP_SERVER_URL, {
      transports: ["websocket"],
      autoConnect: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 30000,
      timeout: 20000,
      query: {
        clientId: clientId,
      },
    });

    Log.debug("SOCKET INITIATED");
    const socket = manager.socket(`/`);
    socket.on(`balance-${clientId}`, (data) => {
      Log.debug("GET BALANCE FROM SOCKET", data);
      setBalance(Number(data.balance));
      if (data.creditLimit !== undefined)
        setCreditLimit(Number(data.creditLimit) ?? 0);
      if (data.billingType !== undefined) setBillingType(data.billingType);
    });
  }, []);

  useEffect(() => {
    setIsShouldDisplayBalance(isSidebarMinimized);
  }, [isSidebarMinimized]);

  const labelCaptionComponent = (caption: string) => (
    <span style={{ fontWeight: "600", color: "#9899ac", textAlign: "left" }}>
      {caption}
    </span>
  );
  const labelValueComponent = (value: string) => (
    <span style={{ fontWeight: "600", textAlign: "right" }}>{value}</span>
  );

  return (
    <div className="p-4 pt-0 mt-0 pb-0 mb-0 h-auto">
      <hr
        className="w-100"
        style={{
          border: "0.3px dashed gray",
        }}
      />
      {isShouldDisplayBalance && (
        <div className="h-30px w-30px p-1 d-flex align-items-center justify-content-center">
          <span className="menu-icon justify-content-center p-0 m-0">
            <SVG
              src={toAbsoluteUrl(iconBillingPath)}
              className={clsx(iconBillingPath, "fs-1 p-0 m-0")}
            />
          </span>
        </div>
      )}
      {!isShouldDisplayBalance && (
        <div className="row pt-2 ">
          <div className="col-3">{labelCaptionComponent("Balance")}</div>
          <div className="col-9 text-end">
            <span data-testid="balance-value">
              {labelValueComponent(formatNumberToIDR(balance))}
            </span>
          </div>
          {billingType === "postpaid" && (
            <>
              <div className="col-3">
                {labelCaptionComponent("Credit Limit")}
              </div>
              <div className="col-9 text-end">
                <span data-testid="credit-limit-value">
                  {labelValueComponent(formatNumberToIDR(creditLimit))}
                </span>
              </div>
            </>
          )}
        </div>
      )}

      <hr
        className="w-100 mx-0 px-0"
        style={{
          border: "0.3px dashed gray",
        }}
      />
    </div>
  );
};

export default UserBalance;
