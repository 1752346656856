import { NewDataCallback } from "src/api/firebase";

interface InitialState {
  //Event
  messageEventListeners: NewDataCallback[];
  
 // add for more event
  
}

export const FirebaseMessageEvent: string = "FirebaseEventMessageList";

export default InitialState