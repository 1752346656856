import db from ".";
import { Order } from "../app/models/Order";
import { converter2 } from "./converter";
import * as Log from "src/util/SDayslogger"

export const getAllOrders = async () => {
  return await db
    .collection("orders")
    .withConverter(converter2<Order>())
    .get()
    .then((snap) => {
      const orders = snap.docs.map((doc) => {
        // if (!snap || snap.empty) {
        //   return undefined;
        // }
        return {...doc.data(), id: doc.id};
      });
      return orders;
    })
    .catch((err) => {
      Log.error(`getAllOrders ${err}`);
      return undefined;
    });
};

/**
 * getOrderBySN -> get order by serial number, 
 * @param order_sn Order serial number 
 * @returns 
 */
export const getOrderBySN = async (order_sn: string) => {
  return await db
  .collection("orders")
  .withConverter(converter2<Order>())
  .where("ordersn", "==", order_sn)
  .get()
  .then((snap) => {
    return snap.docs.map((doc) => doc.data());
  })
  .catch(err => {
    Log.error(`getOrderBySN ${err}`)
    return undefined
  })

};

export const getOrderByID = async (id: string) => {
  return await db
    .collection("orders")
    .doc(id)
    .withConverter(converter2<Order>())
    .get()
    .then((doc) => {
      if (!doc || !doc.exists) {
        return undefined;
      }
      return { ...doc.data(), id: doc.id };
    })
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const updatePriorityNumber = async (id: string, newPrior: number) => {
  return await db.collection("orders").doc(id).update({
    orderPriorityNumber: newPrior
  })
}