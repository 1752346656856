import { RootState } from '../../../../setup/redux/store'
import * as lc from '../../localstorage/index'
import * as api from '../../../../api/index'
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import * as auth from "../redux/AuthSlice";
import { FC, useEffect, useRef, useState } from 'react';
import { checkUserConnection } from '../../../../api/server/connection';
import { getUserSessionToken } from '../../../../db/session';
import { logout } from '../../../../api/index';
import * as Log from "../../../../util/SDayslogger"
import { useNavigate } from 'react-router-dom';
import { deleteMessage } from '../../chat/redux/ChatSlice';
import { cleanReduxCIQ } from '../../ciq/redux/CIQSlice';
import { cleanReduxContact } from '../../contact/redux/ContactSlice';
import { useTranslation } from "react-i18next";
import { cleanReduxShipment } from "../../../modules/product/shipment/redux/ShipmentsSlice"
import { cleanReduxProduct } from '../../product/redux/ProductSlice';
import { cleanReduxMarketplaceProductCategory } from '../../product/marketplaceProductCategory/marketplaceProductCategorySlice';

const mapState = (state: RootState) => ({ auth: state.Auth })
const connector = connect(mapState, auth.AuthSlice.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true)
  const didRequest = useRef(false)
  let userSessionToken = lc.getItemLC(lc.LCName.SessionToken);
  const currentUser = lc.getItemLC(lc.LCName.User);
  const nav = useNavigate();
  let collablist = useSelector((state: RootState) => state.Chat.chatList); //list Collaboration
  let userCollablist = useSelector((state: RootState) => state.Chat.userChatList); //list Collaboration
  
  // let requestUser;
  useEffect(() => {
    const screentime = new Date().getTime();
    const sessionid = lc.getItemLC(lc.LCName.SessionID);
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          if (currentUser != null) {
            await AuthUser(currentUser)
              .then((response) => {
                dispatch(props.setAuthUser(JSON.stringify(currentUser)));
                dispatch(props.setAuth(response));
                dispatch(props.setSessionUser(screentime));
                checkUserConnection(currentUser.uid, sessionid);
                
                console.log("Init Get All Collaboration");
                const user = {
                  company: lc.getItemLC(lc.LCName.CompanyID),
                  uid: currentUser.uid,
                }
                
                /***
                 * Replace With Pesist Redux
                 * **/

                // if (!collablist || collablist.length <= 0) {
                // Chat
                //   // .fetchCollaborations(user.uid, user.company)
                //   .fetchCollaborations(user.uid)
                //   .then(collabs => dispatch(chat.setChatList(collabs)))
                // }
                // if (!userCollablist || userCollablist.length <= 0) {
                // Chat
                //   .fetchFriendCollaborations(user.uid)
                //   .then(collabs => dispatch(chat.setUserChatList(collabs)))
                // }
              });
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          //dispacth for log out
          dispatch(props.setAuth(false))
        }
      } finally {
        setShowLoading(false)
      }
      return () => (didRequest.current = true)
    }

    if (currentUser) {
      requestUser()
    } else {
      //dispacth for log out
      lc.removeSession()
      dispatch(props.setAuth(false))
      setShowLoading(false)
      lc.removeSession();
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (currentUser === null && userSessionToken === null) {
      dispatch(props.setAuth(false))
    }
    if (userSessionToken !== null && currentUser !== null) {
      let session = async () => {
        await getSessionToken(currentUser.uid)
          .then((response) => {
            if (response) {
              if (userSessionToken !== response) {
                // userSessionToken = null;
                logout()
                  .then(() => {
                    const user = lc.getItemLC(lc.LCName.User);
                    const sessionid = lc.getItemLC(lc.LCName.SessionID);
                    console.log(`Logout User ${user} SessionID ${sessionid}`);
                    dispatch(props.deleteUser());
                    lc.removeSession()
                    dispatch(props.setAuth(false));
                    dispatch(deleteMessage)
                    dispatch(cleanReduxCIQ(true))
                    dispatch(cleanReduxContact(true))
                    dispatch(cleanReduxShipment(true))
                    dispatch(cleanReduxProduct(true));
                    dispatch(cleanReduxMarketplaceProductCategory(true));
                    alert(`${t("Login.Error.MultipleLogin")}`);
                    // window.location.reload();
                    nav("/auth")
                  })
                  .catch((error) => {
                    Log.errorWithNavigate(nav, "Logout Failed: " + error);
                  });
              }
            }
          });
      }
      // session();
    }
  }, [nav])
  return showLoading ? <div>{t("Login.Button.Loading")}</div> : <>{props.children}</>
}

export default connector(AuthInit);

function AuthUser(currentUser: any): Promise<boolean> {
  Log.debug(`Session AuthUser ${JSON.stringify(currentUser)}`);
  return api.AuthUser(currentUser)
}

async function getSessionToken(uid: string): Promise<string> {
  Log.debug(`Session UID ${uid}`);
  return await getUserSessionToken(uid)
}