import Template, { templateLanguages } from "../app/models/Template";
import Language, {
  templateStatus,
  button as templateButton,
  templateButtonType,
  accountData,
  accountType,
} from "../app/models/Language";
import * as server from "../api/server/templateMessage";
import * as servTemplate from "../db/serviceTemplate";
import { format } from "date-fns";
import { Timestamp, createRef } from "../db";
import * as Log from "../util/SDayslogger";
import { getAccountByID } from "../db/serviceAccount";

export const updateOrCreateWhatsappTemplate = async (
  newTemplate: any,
  newLanguage: any,
  whatsappBusinessID: string,
  whatsappAccessToken: string,
  isUpdateStage: boolean,
  templateID: string,
  accountID: string,
  templateAccounts: any | undefined,
  callback: any
) => {
  console.log(newLanguage, "_newlanguage");
  console.log("MASUK updateOrCreateWhatsappTemplate komen");

  let template: any;
  if (newTemplate) {
    /***
     * Convert Object To Updatable
     */
    template = JSON.parse(JSON.stringify(newTemplate));
    // template.account = newTemplate.account; //temporary
    // template.company = newTemplate.company; //temporary
    template.client = newTemplate.client;
    template.accounts = newTemplate.accounts;
    template.companies = newTemplate.companies;
    template.languages = newTemplate.languages;
    template.createdAt = newTemplate.createdAt;
    template.updatedAt = newTemplate.updatedAt;
    template.isActive = true;
    /***
     * End Convert Object To Updatable
     */

    let mediaType = "";
    if (newLanguage && newLanguage.header && newLanguage.header.type) {
      if (newLanguage.header.type === "media") {
        mediaType = newLanguage.header.mediaType;
      } else {
        mediaType = newLanguage.header.type;
      }
    }
    console.log("komen Check Stage : " + isUpdateStage);
    const accountRef = createRef("account", accountID);
    let responseTemplateID: string = "";
    if (templateAccounts && templateAccounts.length > 0) {
      const findTemplateAccount = templateAccounts.find(
        (acc: any) =>
          acc.account.id === accountID && acc.type === accountType.whatsapp
      );
      console.log(`findTemplateAccount `, findTemplateAccount);
      if (
        findTemplateAccount &&
        findTemplateAccount.responseTemplateID !== ""
      ) {
        responseTemplateID = findTemplateAccount.responseTemplateID
          ? findTemplateAccount.responseTemplateID
          : "";
      }
    }
    /***
     * Process For Update Template
     */
    if (isUpdateStage) {
      console.log("MASUK IF UPDATE TEMPLATE komen");

      await server
        .sendRequestCreateTemplate(
          mediaType,
          newLanguage,
          newTemplate,
          whatsappBusinessID,
          whatsappAccessToken,
          isUpdateStage,
          newLanguage.responseTemplateID,
          async (
            updateStatus: boolean,
            responseJson: string,
            errorMessage: string,
            errorResponse: string
          ) => {
            console.log(
              "komen Hasil Request Update Template - Response Request : \n updateStatus : " +
                updateStatus +
                " \n reponseJSOn : " +
                JSON.stringify(responseJson) +
                " \n errorMessage : " +
                errorMessage +
                " \n errorResponse : " +
                JSON.stringify(errorResponse)
            );

            let resultLanguage = { ...newLanguage };
            const jsonResp: any = JSON.stringify(responseJson) || "";
            console.log(jsonResp, "json_Res");
            if (resultLanguage && !resultLanguage.createdAt) {
              resultLanguage.createdAt = template.createdAt;
            }
            if (updateStatus) {
              const newAccountsData = templateAccounts?.map(
                (accountData: any) => {
                  if (accountData.account.id === accountID) {
                    return {
                      ...accountData,
                      updatedAt: Timestamp.now(),
                      responseJSON: jsonResp
                        ? jsonResp
                        : accountData.responseJSON,
                    };
                  } else {
                    return accountData;
                  }
                }
              );
              console.log(`newAccountsData `, newAccountsData);
              //Update Template Success
              if (responseJson) {
                resultLanguage.responseJSON = JSON.stringify(responseJson);
              }
              const newTemplateLanguages: templateLanguages[] =
                template.languages?.map((acc: any) => {
                  if (
                    acc.templateLanguage === resultLanguage.language!
                    // &&
                    // acc.account?.id === accountID
                  ) {
                    return {
                      ...acc,
                      account: accountRef,
                      templateStatus: resultLanguage.status,
                      // templateStatus.rejected,
                    };
                  } else {
                    return acc;
                  }
                });
              template.languages = newTemplateLanguages;
              // resultLanguage.status = templateStatus.pending;
              resultLanguage.status = templateStatus.rejected;
              resultLanguage.updatedAt = Timestamp.now();
              const now = new Date();
              template.updatedAt = Timestamp.now();
              await servTemplate.updateTemplate(template, templateID);
              await servTemplate.updateLanguage(
                templateID,
                `${newLanguage.language}_${whatsappBusinessID}`,
                resultLanguage
              );
              //updated templateAccounts
              if (newAccountsData && newAccountsData.length > 0) {
                newAccountsData.forEach(async (templateAcc: any) => {
                  if (
                    !templateAcc.id &&
                    templateAcc.account &&
                    templateAcc.account.id === accountID
                  ) {
                    console.log(templateID, "templateID 201 tempacc");
                    console.log(
                      resultLanguage.language,
                      "resultLanguage.language 201 tempacc"
                    );
                    console.log(templateAcc, "templateAcc 201 tempacc");

                    await servTemplate.createTemplateAccounts(
                      templateID,
                      resultLanguage.language!,
                      templateAcc
                    );
                  }
                  if (
                    templateAcc.id &&
                    templateAcc.account &&
                    templateAcc.account.id === accountID
                  ) {
                    await servTemplate.updateTemplateAccounts(
                      templateID,
                      resultLanguage.language!,
                      templateAcc
                    );
                  }
                });
              }
              console.log("before if updatedAt:");
              callback(template);
            } else if (
              !updateStatus &&
              (!errorMessage || errorMessage === "")
            ) {
              //Update Template Failed
              const newAccountsData = templateAccounts?.map(
                (accountData: any) => {
                  if (accountData.account.id === accountID) {
                    return {
                      ...accountData,
                      errorMessage: errorMessage ? errorMessage : "",
                      errorResponse: errorResponse
                        ? JSON.stringify(errorResponse)
                        : "",
                      responseJSON: jsonResp
                        ? jsonResp
                        : accountData.responseJSON,
                      updatedAt: Timestamp.now(),
                    };
                  } else {
                    return accountData;
                  }
                }
              );
              console.log(`newAccountsData `, newAccountsData);
              if (errorMessage) {
                resultLanguage.errorMessage = errorMessage;
              }
              if (errorResponse) {
                resultLanguage.responseJSON = JSON.stringify(errorResponse);
              }
              const newTemplateLanguages: templateLanguages[] =
                template.languages?.map((acc: any) => {
                  if (
                    acc.templateLanguage === resultLanguage.language!
                    // &&
                    // acc.account?.id === accountID
                  ) {
                    return {
                      ...acc,
                      account: accountRef,
                      templateStatus: resultLanguage.status,
                      // templateStatus.rejected,
                    };
                  } else {
                    return acc;
                  }
                });
              template.languages = newTemplateLanguages;
              resultLanguage.status = templateStatus.rejected;
              resultLanguage.updatedAt = Timestamp.now();
              const now = new Date();
              template.updatedAt = Timestamp.now();
              await servTemplate.updateTemplate(template, templateID);
              await servTemplate.updateLanguage(
                templateID,
                `${newLanguage.language}_${whatsappBusinessID}`,
                resultLanguage
              );
              //updated templateAccounts
              if (newAccountsData && newAccountsData.length > 0) {
                newAccountsData.forEach(async (templateAcc: any) => {
                  if (
                    !templateAcc.id &&
                    templateAcc.account &&
                    templateAcc.account.id === accountID
                  ) {
                    console.log(templateID, "templateID 318 tempacc");
                    console.log(
                      resultLanguage.language,
                      "resultLanguage.language 318 tempacc"
                    );
                    console.log(templateAcc, "templateAcc 318 tempacc");
                    await servTemplate.createTemplateAccounts(
                      templateID,
                      resultLanguage.language!,
                      templateAcc
                    );
                  }
                  if (
                    templateAcc.id &&
                    templateAcc.account &&
                    templateAcc.account.id === accountID
                  ) {
                    await servTemplate.updateTemplateAccounts(
                      templateID,
                      resultLanguage.language!,
                      templateAcc
                    );
                  }
                });
              }

              callback(template);
            } else {
              callback(undefined);
            }
          }
        )
        .then(async (response) => {
          //After Request New Template
        })
        .catch(function (error) {
          //Error Response
          Log.error("Edit Template : sendRequestCreateTemplate " + error)

        });

      /***
       * Process For Create Template
       */
    } else {
      Log.debug(`Create Template for wabaId ${whatsappBusinessID}`);

      await server
        .sendRequestCreateTemplate(
          mediaType,
          newLanguage,
          newTemplate,
          whatsappBusinessID,
          whatsappAccessToken,
          isUpdateStage,
          "",
          async (
            respTemplateID: string,
            responseJson: string,
            errorMessage: string,
            errorResponse: string
          ) => {
            try {
              console.log("MASUK sendRequestCreateTemplate komen");
              console.log(respTemplateID, "respTemplateID komen");
              console.log(responseJson, "responseJson komen");
              console.log(errorResponse, "errorResponse komen");

              console.log(
                "Hasil Request Create Template - Response Request : \n responsetemplateID : " +
                  respTemplateID +
                  " \n reponseJSOn : " +
                  JSON.stringify(responseJson) +
                  " \n errorMessage : " +
                  errorMessage +
                  " \n errorResponse : " +
                  JSON.stringify(errorResponse)
              );
              let resultLanguage = { ...newLanguage };
              console.log(resultLanguage, "resultLanguage komen");
              let newAccountsData: accountData[] =
                templateAccounts && templateAccounts !== undefined
                  ? templateAccounts
                  : [];
              console.log(newAccountsData, "newAccountsData komen");
              let newTemplateLanguage: any[] = template.languages
                ? template.languages
                : [];
              if (resultLanguage && !resultLanguage.createdAt) {
                resultLanguage.createdAt = template.createdAt;
              }
              if (respTemplateID && respTemplateID !== "") {
                console.log("MASUK IF UPDATE TEMPLATE SUCCESS komen");

                //Update Template Success
                resultLanguage.responseTemplateID = respTemplateID;
                resultLanguage.responseJSON = JSON.stringify(responseJson);
                resultLanguage.updatedAt = Timestamp.now();
                const now = new Date();
                const findTemplateAccount = newAccountsData.find(
                  (templateAccount) =>
                    templateAccount.account &&
                    templateAccount.account.id === accountID
                );
                const jsonResp = resultLanguage.responseJSON;
                let jsonTemplateStatus = JSON.parse(jsonResp).status !== undefined
                  ? JSON.parse(jsonResp).status
                  : templateStatus.pending;


                console.log(resultLanguage, "resultLanguage ikome");
                console.log(findTemplateAccount, "findTemplateAccount ikome");
                // console.log(jsonResp, "jsonResp ikome");
                console.log(jsonTemplateStatus, "jsonTemplateStatus ikome");
                console.log(JSON.parse(jsonResp).status, "status json ikome");
                if (!findTemplateAccount) {
                  console.log("MASUK IF !findTemplateAccount");
                  const newAccountData: accountData = {
                    account: accountRef,
                    type: accountType.whatsapp,
                    responseJSON: jsonResp,
                    responseTemplateID: respTemplateID,
                    status: jsonTemplateStatus,
                    createdAt: Timestamp.now(),
                    updatedAt: Timestamp.now(),
                  };
                  console.log(`komen newAccountData if : `, newAccountData);
                  newAccountsData.push(newAccountData);
                } else {
                  console.log("MASUK ELSE !findTemplateAccount");
                  newAccountsData = newAccountsData.map((templateAccount) => {
                    if (
                      templateAccount.account &&
                      templateAccount.account.id! === accountID
                    ) {
                      return {
                        ...templateAccount,
                        updatedAt: Timestamp.now(),
                        responseJSON: jsonResp,
                        responseTemplateID: respTemplateID,
                        status: jsonTemplateStatus,
                      };
                    } else return templateAccount;
                  });
                }
                resultLanguage.status = JSON.parse(jsonResp).status;
                console.log(`komen newAccountsData else : `, newAccountsData);
                const newTemplateLanguages: templateLanguages[] =
                  template.languages?.map((acc: templateLanguages) => {
                    if (
                      acc.templateLanguage === resultLanguage.language! &&
                      acc.account?.id === accountID
                      // `${acc.templateLanguage}_${whatsappBusinessID}` ===
                      // `${resultLanguage.language!}_${whatsappBusinessID}`
                    ) {
                      const newDataLanguage: templateLanguages = {
                        templateLanguage: acc.templateLanguage,
                        account: accountRef,
                        templateStatus: jsonTemplateStatus,
                      };
                      return newDataLanguage;
                    } else {
                      return acc;
                    }
                  });
                console.log(
                  `komen [template.client] newTemplateLanguages : `,
                  newTemplateLanguages
                );
                template.languages = newTemplateLanguages;
                template.updatedAt = Timestamp.now(); //{seconds : Timestamp.now().seconds, nanoseconds: Timestamp.now().nanoseconds};
                console.log(template, "template komen");
                console.log(newLanguage.language, "newLanguage.language komen");
                console.log(resultLanguage, "resultLanguage komen");
                await servTemplate.updateTemplate(template, templateID);
                await servTemplate.updateLanguage(
                  templateID,
                  `${newLanguage.language}_${whatsappBusinessID}`,
                  resultLanguage
                );
                //updated templateAccounts
                if (newAccountsData && newAccountsData.length > 0) {
                  newAccountsData.forEach(async (templateAcc) => {
                    if (
                      !templateAcc.id &&
                      templateAcc.account &&
                      templateAcc.account.id === accountID
                    ) {
                      console.log(templateID, "templateID 537 tempacc");
                      console.log(
                        resultLanguage.language,
                        "resultLanguage.language 537 tempacc"
                      );
                      console.log(templateAcc, "templateAcc 537 tempacc");
                      await servTemplate.createTemplateAccounts(
                        templateID,
                        `${resultLanguage.language!}_${whatsappBusinessID}`,
                        templateAcc
                      );
                    }
                    if (
                      templateAcc.id &&
                      templateAcc.account &&
                      templateAcc.account.id === accountID
                    ) {
                      await servTemplate.updateTemplateAccounts(
                        templateID,
                        `${resultLanguage.language!}_${whatsappBusinessID}`,
                        templateAcc
                      );
                    }
                  });
                }

                callback(template);
              } else {
                //if((!respTemplateID ||  respTemplateID === "") && (!errorMessage || errorMessage === "")){
                //Update Template Failed
                resultLanguage.errorMessage = errorMessage;
                resultLanguage.responseJSON = JSON.stringify(errorResponse);
                resultLanguage.status = templateStatus.rejected;
                resultLanguage.updatedAt = Timestamp.now();
                let newAccountsData: accountData[] = templateAccounts
                  ? templateAccounts
                  : [];
                let newTemplateLanguage: any[] = template.languages
                  ? template.languages
                  : [];
                const jsonResp: any = resultLanguage.responseJSON;

                console.log(resultLanguage, "resultLanguage ikome");
                // console.log(jsonResp, "jsonResp ikome");
                console.log(JSON.parse(jsonResp).status, "status json ikome");
                //find if not found newAccountsData
                const findTemplateAccount = newAccountsData.find(
                  (templateAccount) =>
                    templateAccount.account &&
                    templateAccount.account.id === accountID
                );
                if (!findTemplateAccount) {
                  const newAccountData: accountData = {
                    account: accountRef,
                    type: accountType.whatsapp,
                    errorMessage: errorMessage ? errorMessage : "",
                    responseJSON: jsonResp,
                    responseTemplateID: respTemplateID,
                    status: templateStatus.rejected,
                    createdAt: Timestamp.now(),
                    updatedAt: Timestamp.now(),
                  };
                  console.log(`newAccountData : `, newAccountData);
                  newAccountsData.push(newAccountData);
                } else {
                  newAccountsData = newAccountsData.map((templateAccount) => {
                    if (
                      templateAccount.account &&
                      templateAccount.account.id! === accountID
                    ) {
                      return {
                        ...templateAccount,
                        updatedAt: Timestamp.now(),
                        errorMessage: errorMessage ? errorMessage : "",
                        responseJSON: jsonResp,
                        responseTemplateID: respTemplateID,
                        status: templateStatus.rejected,
                      };
                    } else return templateAccount;
                  });
                }
                console.log(`newAccountsData `, newAccountsData);
                console.log(template, "ini adaalah template orx");
                const newTemplateLanguages: templateLanguages[] =
                  template.languages?.map((acc: any) => {
                    console.log(acc, "ini acc orx");
                    if (
                      acc.templateLanguage === resultLanguage.language!
                      // &&
                      // acc.account?.id === accountID
                    ) {
                      return {
                        ...acc,
                        account: accountRef,
                        templateStatus: resultLanguage.status,
                        // templateStatus.rejected,
                      };
                    } else {
                      return acc;
                    }
                  });
                console.log(
                  `[template.client] newTemplateLanguages : `,
                  newTemplateLanguages
                );
                template.languages = newTemplateLanguages;
                const now = new Date();
                template.updatedAt = Timestamp.now();
                console.log(resultLanguage, "resultlang orx");
                console.log(template, "template orx komen");
                console.log(newTemplateLanguages, "newtemplang orx komen");
                console.log(templateID, "templateID orx");
                console.log(`${newLanguage.language}_${whatsappBusinessID}  orx`);
                await servTemplate.updateTemplate(template, templateID);
                await servTemplate.updateLanguage(
                  templateID,
                  `${newLanguage.language}_${whatsappBusinessID}`,
                  resultLanguage
                );
                
                //updated templateAccounts
                if (newAccountsData && newAccountsData.length > 0) {
                  newAccountsData.forEach(async (templateAcc) => {
                    if (
                      !templateAcc.id &&
                      templateAcc.account &&
                      templateAcc.account.id === accountID
                    ) {
                      console.log(templateID, "templateID 685 tempacc");
                      console.log(
                        resultLanguage.language,
                        "resultLanguage.language 685 tempacc"
                      );
                      console.log(templateAcc, "templateAcc 685 tempacc");
                      await servTemplate.createTemplateAccounts(
                        templateID,
                        `${resultLanguage.language!}_${whatsappBusinessID}`,
                        templateAcc
                      );
                    }
                    if (
                      templateAcc.id &&
                      templateAcc.account &&
                      templateAcc.account.id === accountID
                    ) {
                      await servTemplate.updateTemplateAccounts(
                        templateID,
                        `${resultLanguage.language!}_${whatsappBusinessID}`,
                        templateAcc
                      );
                    }
                  });
                }

                callback(template);
              }
              // else{
              //   callback(undefined);
              // }
            } catch (error) {
              Log.error("Process Template Update : sendRequestCreateTemplate " + error)              
            }
          }
        )
        .then(async (response) => {
          //After Request New Template
        })
        .catch(function (error) {
          //Error Response
          callback(undefined);
          Log.error("Create Template : sendRequestCreateTemplate " + error)
          
        });
    }
  }
};
