/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ID, KTSVG, QUERIES } from "../../../../../../resources/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { handledCollab, handleCIQ } from "../../core/_requests";
import { useTranslation } from "react-i18next";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import BroadcastModal from "../../../../broadcast/BroadcastModal";
import { useDispatch, useSelector } from "react-redux";
import * as Chat from "../../../../../../actions/chat";
import * as chat from "../../../../../../app/modules/chat/redux/ChatSlice";
import * as CIQ from "../../../../../../app/modules/ciq/redux/CIQSlice";
import { createNotificationAnotherUser } from "../../../../../../db/serviceUser";
import { Link } from "react-router-dom";
import { RootState } from "../../../../../../setup/redux/store";
import { Timestamp } from "firebase/firestore";
import { Collaboration, Room } from "src/app/models/Chat";

type Props = {
  id: ID;
  collaborationId: string;
  indexId: number;
  lastInteractionDate?: Timestamp;
  accountType?: string | undefined;
  rooms?: Room & Collaboration
};

const CIQActionsCell: FC<Props> = ({
  id,
  collaborationId,
  indexId,
  lastInteractionDate,
  accountType,
  rooms
}) => {
  const dispatch = useDispatch();
  const roomId = id?.toString() ?? "";
  const { setItemIdForUpdate } = useListView();
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { state, updateState } = useQueryRequest();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const selectedRoom = useSelector(
    (state: RootState) => state.Chat.selectedRoom
  );

  // const openAddUserModal = () => {
  //   setItemIdForUpdate(null);
  // };

  // const openEditModal = () => {
  //   setItemIdForUpdate(id);
  // };

  // const onSuccessSubmit = () => {
  //   //create emelent in list message
  //   console.log("onSuccessSubmit");
  //   let triggerDummyButton = document.getElementById(
  //     "triggerAlert"
  //   ) as HTMLButtonElement;
  //   triggerDummyButton.click();
  // };

  // const callback = (id: string) => {
  //   console.log("==> masuk callback balezzz", id);

  //   if (id && id !== null) {
  //     Chat.fetchCollaborationByID(id.toString()).then((collab) => {
  //       if (collab) {
  //         console.log("masuk if collab balezzz", collab);
  //         dispatch(chat.updateChatList(collab));
  //         dispatch(chat.setSelectedChat(collab.id));
  //         dispatch(
  //           chat.setSelectedRoom(
  //             collab.roomsModel !== undefined && collab?.roomsModel[0].id
  //           )
  //         );
  //         console.log("Process Create Notified Another User");
  //         if (collab.company && collab.company?.id && collab.customer) {
  //           //Process Create Notif For Another User
  //           createNotificationAnotherUser(collab);
  //         }
  //       }
  //     });
  //   }
  // };

  // const updateItem = useMutation(
  //   async () => {
  //     const handleCIQResult = await handleCIQ(collaborationId, roomId);
  //     const collab = await Chat.fetchCollaborationByID(collaborationId);
  //     if (!collab) {
  //       return;
  //     }
  //     // check if handle CIQ success
  //     if (handleCIQResult) {
  //       // dispatch(CIQ.deleteItemListCIQ(roomId));
  //       // dispatch(chat.updateChatList(collab));
  //       if (collab.company && collab.company?.id && collab.customer) {
  //         createNotificationAnotherUser(collab);
  //       }
  //     }
  //     //  else {
  //     //   dispatch(chat.updateAllChatChatList(collab));
  //     // }
  //   },
  //   {
  //     onSuccess: () => {
  //       updateState({
  //         sort: state.sort === "delete" ? "asc" : "delete",
  //         items_per_page: state.items_per_page,
  //         page: 1,
  //         action: "noAction",
  //       });
  //     },
  //     onError(error, variables, context) {
  //       updateState({
  //         sort: state.sort === "delete" ? "asc" : "delete",
  //         items_per_page: state.items_per_page,
  //         page: 1,
  //         action: "noAction",
  //       });
  //     },
  //   }
  // );

  const disableHandleCIQ = (
    lastInteractionDate: Timestamp | undefined,
    accountType: string | undefined
  ) => {
    if (!accountType || !lastInteractionDate) {
      return false;
    }

    if (accountType === "whatsapp") {
      const today = new Date();
      const lastInteraction = lastInteractionDate.toDate();

      const timeDifference = today.getTime() - lastInteraction.getTime();

      const daysDifference = timeDifference / (1000 * 3600 * 24);

      return daysDifference > 1;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (lastInteractionDate) {
      const disabled = disableHandleCIQ(lastInteractionDate, accountType);
      setIsDisabled(disabled);
    }
  }, [lastInteractionDate, accountType]);

  const handleChat = async () => {
    let collaboration = rooms?.parent_document
    collaboration.roomsModel = [rooms]
    collaboration.id = collaborationId
    const handleCIQResult = await handleCIQ(collaborationId, roomId);
    if (handleCIQResult) {
      if (collaboration.company && collaboration.company?.id && collaboration.customer) {
        createNotificationAnotherUser(collaboration);
      }
      dispatch(chat.setSelectedChat(collaborationId));
      dispatch(chat.setSelectedRoom(roomId));
      // const observer = new MutationObserver((mutationsList) => {
      //   const element = document.getElementById(`chatitem-${collaborationId}`);
      //   if (element) {
      //     element.click();
      //     observer.disconnect(); // Hentikan observer setelah elemen ditemukan dan diklik
      //   }
      // });

      // // Konfigurasikan observer untuk memantau perubahan DOM
      // observer.observe(document.body, { childList: true, subtree: true });

      // // Anda mungkin ingin menghentikan observer setelah periode waktu tertentu jika elemen tidak ditemukan
      // setTimeout(() => {
      //   observer.disconnect();
      // }, 10000);
    }
  }

  return (
    <div className="d-flex flex-row">
      <Link
        className={`btn btn-primary btn-sm p-2 py-1 mt-0 ${isDisabled ? "disabled" : ""
          }`}
        to={isDisabled ? "#" : "/handled-customer"}
        onClick={async (e) => {
          if (isDisabled) {
            e.preventDefault();
            return;
          }
          await handleChat()
          // const intervalId = setInterval(() => {
          //   document.getElementById(`chatitem-${collaborationId}`)?.click();
          //   clearInterval(intervalId);
          // }, 500);
        }}
        data-testid="button-handle"
      >
        {t("CIQ.Button.Handle")}
      </Link>
      {/* <button className={`btn btn-primary btn-sm p-2 py-1 mt-0 ${isDisabled ? "disabled" : ""
        }`} onClick={async (e) => {
          e.preventDefault()
          handleChat()
        }}>
        {t("CIQ.Button.Handle")}</button> */}
    </div>
  );
};

export { CIQActionsCell };
function updateState(arg0: {
  sort: string;
  items_per_page: any;
  page: number;
  action: string;
}) {
  throw new Error("Function not implemented.");
}
