import { FC, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import * as Chat from "../../../../actions/chat";
import { toAbsoluteUrl } from "../../../../resources/helpers";
import FriendChatMessage from "./FriendChatMessage";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { RootState } from "../../../../setup/redux/store";
import * as chat from "../../../modules/chat/redux/ChatSlice";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { Collaboration as HandledMessageListItem } from "../../../models/Chat";
import User from "../../../models/User";
import Customer from "../../../models/Customer";
import newMessageModel, { MessageStatus } from "../../../models/Message";
import { Timestamp } from "../../../../db";
import { createRef } from "../../../../db/connection";
import * as lc from "../../../modules/localstorage/index";
import { storage } from "../../../../db";
import { checkFile, formatSize } from "./chat-util/ChatUtil";
import { v4 as uuidv4 } from "uuid";
import { ChatFileView } from "./chat-modal/ChatFileView";
import * as Log from "../../../../util/SDayslogger";
import { useMediaQuery } from "react-responsive";
import { subsDRMessage } from "../../../../api/firebase/message";
import "../../../../styles/css/dropdown.scss";
import "../../../../styles/css/chat.scss";
import "../../../../styles/css/margin.scss";

const mapState = (state: RootState) => ({ chat: state.Chat });
const connector = connect(mapState, chat.ChatSlice.actions);

type Props = {
  isDrawer?: boolean;
  propsredux?: ConnectedProps<typeof connector>;
  friend?: User;
  customer?: Customer;
  user?: User;
};

const FriendChatInner: FC<Props> = ({ isDrawer = false }, props) => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false);
  let [onLoadMoreMessage, setOnLoadMoreMessage] = useState<boolean>(false);
  // const [customerChat, setCustomerChat] = useState<Customer|undefined>(customer); // Selected Customer
  // const [userChat, setUserChat] = useState<User>(); // Selected User
  const [collabs, setcollabs] = useState<HandledMessageListItem>(); //Selected Collaboration
  const [message, setMessage] = useState<string>(""); // Input Message
  const [companyID, setCompanyID] = useState<string>(
    lc.getItemLC(lc.LCName.CompanyID)?.toString()
  ); // Input Message
  const dispatch = useDispatch();
  const selectedChat = useSelector(
    (state: RootState) => state.Chat.selectedUserChat
  ); //Uid Collaboration
  let collablist = useSelector((state: RootState) => state.Chat.userChatList); //list Collaboration
  const [messages, setMessages] = useState<newMessageModel[]>([]); //List Message
  let listMessagess = useSelector(
    (state: RootState) => state.Chat.userListMessage
  ); //list Collaboration
  let lastVisibleMessage: any = useSelector(
    (state: RootState) => state.Chat.lastVisibleMessage
  ); //list Collaboration
  const [channel, setChannel] = useState<string>(""); //Selected Channel or Last Interaction Channel
  const [file, setFile] = useState<File>();
  const [fileSize, setFileSize] = useState<string>("");
  const [inputFileName, setInputFileName] = useState<string>("");
  const [inputcontentType, setInputContentType] = useState<string>("");
  const [mediaURL, setMediaURL] = useState<string | undefined>("");
  const [messageType, setmessageType] = useState<string | undefined>("text");
  const [prevFile, setPrevFile] = useState("");
  const bottomRef = useRef<HTMLDivElement>(null);
  const attachmentRef = useRef<HTMLInputElement>(null);
  const sendButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isNotified, setIsNotified] = useState<boolean>(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [isLoadingCollaboration, setIsLoadingCollaboration] = useState(false);
  let listMediaUrl: string[] = [];
  let subcNotif: any = null;
  const [friendChat, setFriendChat] = useState<User | undefined>();

  const activeUserId = lc.getItemLC("UID");

  /***
   * Chat Push Notification
   *   use effect for triggered after list messages on redux updated
   */
  useEffect(() => {
    if (!onLoadMoreMessage) {
      setIsNotified(true);
      setMessages(listMessagess);
      //update unreadMessage
      Chat.clearUserUnreadMessages(selectedChat);
      setTimeout(() => {
        onNotify(true);
      }, 100);
      // onNotify();
    }
  }, [listMessagess]);

  /***
   * End Of Chat Push Notification
   */

  /***
   * Chat Attachment
   *   use effect for triggered after choosing file attachment
   */

  //Get Uploaded File
  const setPreviewFile = async (event: any) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
      if (attachmentRef.current) {
        if (attachmentRef.current.value) {
          attachmentRef.current.value = "";
        }
      }
    }
  };

  // Get Attachment
  useEffect(() => {
    if (file) {
      //Set File Attribut
      const size: number = file.size;
      setPrevFile(URL.createObjectURL(file));
      setInputFileName(file.name);
      setFileSize(formatSize(size));
      setInputContentType(file.type);
      //Check File Type
      const tempArrFileType: string = file.type;
      const arryFileType = tempArrFileType.split("/");
      // console.log("File Type : "+tempArrFileType);
      if (
        arryFileType === undefined ||
        arryFileType.length < 1 ||
        arryFileType[0] === undefined
      ) {
        //return error
        alert(t("HC.Error.InvalidFiletype"));
        setInputFileName(t("HC.Error.InvalidFiletype"));
        setFile(undefined);
        return;
      } else {
        // const [result, error] = checkFile2("image","jpg",5);
        const [result, error, maxSize] = checkFile(
          arryFileType[0],
          arryFileType[1],
          size
        );
        if (error !== undefined) {
          if (maxSize !== 0) {
            alert(t("HC.File." + error).replace("<<size>>", maxSize + " MB"));
            setInputFileName(
              t("HC.File." + error).replace("<<size>>", maxSize + " MB")
            );
            setFile(undefined);
          } else {
            alert(t("HC.Error." + error));
            setInputFileName(t("HC.Error." + error));
            setFile(undefined);
          }
          return;
        }
        if (result !== undefined) {
          setmessageType(result.toString());
        }

        // if(arryFileType[1]==="mpeg"){
        //   setInputContentType("audio/mpeg");
        //   setmessageType("audio");
        // }
      }
    }
  }, [file]);

  // Upload File To Storage
  const uploadFile = async (companyID: string) => {
    let fileURL = "";
    if (file) {
      // setPicture(event.target.files[0])
      const metadata = {
        contentType: inputcontentType,
        fileName: inputFileName,
      };
      const uuid = uuidv4();
      const task = storage
        .ref(
          "collaborations" +
            "/" +
            selectedChat +
            "/" +
            messageType +
            "s/chat/" +
            uuid
        )
        .child(inputFileName)
        .put(file, metadata);
      await task
        .then(async (snapshot) => {
          return storage
            .ref(
              "collaborations" +
                "/" +
                selectedChat +
                "/" +
                messageType +
                "s/chat/" +
                uuid
            )
            .child(inputFileName)
            .getDownloadURL()
            .then((url) => {
              fileURL = url;
            });
        })
        .catch((error) => {
          console.log("error : " + error.message);
          alert(t("HC.Error.FailedUpload"));
          setInputFileName(t("HC.Error.FailedUpload"));
          setFile(undefined);
        });
    }
    return fileURL;
  };
  /***
   * End Of Chat Attachment
   */

  /***
   * Chat Initialization
   */
  useEffect(() => {
    // Update Unread Messages
    Chat.clearUserUnreadMessages(selectedChat);

    //Subscribe delivery report message by Collaboration ID
    // const started = new Date();
    // const subcNotif = subsDRMessage(selectedChat, started);

    // Clear Unread Message On Redux
    dispatch(chat.updateUserUnreadMessage(selectedChat));

    // //Set Last Channel
    // const lastChannel = collabs?.lastInteractionChannel.toString().toLowerCase();
    // if(lastChannel!== undefined){
    //   setChannel(lastChannel)
    // }else{
    //   setChannel("whatsapp")
    // }

    // Set Selected Collaboration = Uid From Collaboration
    setcollabs(
      collablist.find((obj) => {
        return obj.id === selectedChat;
      })
    );

    setIsLoadingCollaboration(true);

    //Get and Set List Message By Selected Collaboration
    Chat.fetchMessageCollaboration(
      listMessagess,
      selectedChat,
      "",
      undefined,
      selectedChat,
      (lastVisible: any) => {
        //Set lastVisible
        dispatch(chat.setLastVisibleMessage(lastVisible));
        lastVisibleMessage = lastVisible;
      }
    )
      .then(async (ListMessages: newMessageModel[] | undefined) => {
        if (ListMessages) {
          //Set Messagelist State
          // setMessages(ListMessages);
          //Set Messagelist To Redux
          dispatch(chat.setUserListMessages(ListMessages));
        } else {
          // setMessages(listMessagess);
          dispatch(chat.setUserListMessages(listMessagess));
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
        dispatch(chat.setUserListMessages([]));
      });
    setIsLoadingCollaboration(false);

    /***
     * Delivery Report Notification
     */

    // Initialization Event Handler For Delivery Report Notification
    function EventHandler(event: any) {
      //Delivery Report Event Properties
      const messageid = event.detail.id;
      const messagestatus = event.detail.status;
      const message: newMessageModel = event.detail.message;
      // console.log("Delivery Report Notification - "+message+" - "+message.collaboration?.id+" - "+selectedChat);
      if (
        message &&
        message.collaboration &&
        message.collaboration.id === selectedChat
      ) {
        dispatch(chat.updateStatusListMessages(message));
      }
    }

    // Hook up the event handler
    window.addEventListener("DRNotif", EventHandler);

    return () => {
      // Remove the subscribe notification by collaboration id
      // subcNotif();
      // Remove the handler when the component unmounts
      window.removeEventListener("DRNotif", EventHandler);
    };

    /***
     * End Of Delivery Report Notification
     */

    // }, [selectedChat,listMessagess]);
  }, [selectedChat]);

  // Set Friend
  useEffect(() => {
    setFriendChat(collabs?.userModel);
  }, [collabs]);

  /***
   * Chat Initialization
   */

  const sendMessage = async () => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
    }

    // Sending Message Start
    if (sendButtonRef) {
      sendButtonRef.current?.setAttribute("data-kt-indicator", "on");
    }

    let firebaseMediaURL = "";
    if (messageType !== "text") {
      firebaseMediaURL = await Promise.resolve(uploadFile(companyID));
      if (firebaseMediaURL === "" || firebaseMediaURL === undefined) {
        alert(t("HC.Error.FailedUpload"));
        setInputFileName(t("HC.Error.FailedUpload"));
        setFile(undefined);
        if (sendButtonRef) {
          sendButtonRef.current?.removeAttribute("data-kt-indicator");
        }
        return;
      }
    } else {
      if (message === "") {
        if (sendButtonRef) {
          sendButtonRef.current?.removeAttribute("data-kt-indicator");
        }
        return;
      }
    }

    //Create New Message Model
    let newMessage: newMessageModel = {
      channel: channel,
      createdAt: Timestamp.now(),
      sentAt: Timestamp.now(),
      deliveredAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      // createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      // sentAt: firebase.firestore.Timestamp.fromDate(new Date()),
      // deliveredAt: firebase.firestore.Timestamp.fromDate(new Date()),
      // updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      destination: "outbound",
      mediaUrl: firebaseMediaURL,
      isActive: true,
      messageType: messageType,
      textContent: message,
      previewurl: false,
      voice: false,
      filename: inputFileName,
      filesize: fileSize,
      collaboration: createRef("collaborations", selectedChat),
      fromUser: createRef("users", activeUserId),
      toUser: createRef("users", friendChat?.id!),
      status: MessageStatus.delivered,
      notifiedAt: null,
    };

    //Update Text Box to Empty Text
    toggleChatUpdateFlat(!chatUpdateFlag);
    setMessage("");
    setFile(undefined);
    setFileSize("");
    setInputFileName("");
    setInputContentType("");
    setMediaURL("");
    setmessageType("text");
    setPrevFile("");

    // DRNotify
    const setNewMessage = (newMessage: newMessageModel) => {
      //Set New Message To List Message
      let newListMessage: newMessageModel[] = [];
      messages.map((item) => {
        newListMessage.push(item);
      });
      newListMessage.push(newMessage);
      listMessagess = newListMessage;
      setMessages(newListMessage);
      dispatch(chat.setUserListMessages(newListMessage));
    };

    const callback = (responseMessage: newMessageModel, error: string) => {
      if (responseMessage !== undefined) {
        setNewMessage(responseMessage);
        const newCollabList: HandledMessageListItem[] = [];
        if (collabs !== undefined) {
          let newCollab = collabs;
          collablist.map((item) => {
            if (item.id === selectedChat) {
              if (newMessage.messageType !== undefined) {
                let lastmessage = newMessage.textContent;
                if (
                  (newMessage.textContent === "" ||
                    newMessage.textContent === undefined) &&
                  newMessage.filename !== undefined
                ) {
                  lastmessage = newMessage.filename;
                }
                item = {
                  ...item,
                  lastInteractionAt: newMessage.createdAt,
                  lastInteractionChannel: newMessage.channel,
                  lastInteractionMessage: lastmessage,
                  lastInteractionType: newMessage.messageType,
                  // lastUserInteractionAt: newMessage.lastUserInteractionAt,
                };
                newCollab = item;
                Chat.updateLastInteractionUserChat(selectedChat, newMessage);
                newCollabList.push(newCollab);
                return item;
              }
            }
          });
          collablist.map((item) => {
            if (item.id !== selectedChat) {
              newCollabList.push(item);
              return item;
            }
          });
          setcollabs(newCollab);
          // console.log("Ini Selected Collaboration: "+JSON.stringify(collabs));

          // console.log("Ini User Model: "+JSON.stringify(collabs?.userModel));

          dispatch(chat.setUserChatList(newCollabList));
        }

        if (
          responseMessage.messageType !== undefined &&
          (responseMessage.messageType === "image" ||
            responseMessage.messageType === "video") &&
          responseMessage.mediaUrl !== undefined &&
          responseMessage.id !== undefined &&
          responseMessage.filename !== undefined
        ) {
          messages.forEach((obj) => {
            if (
              obj.messageType !== undefined &&
              (obj.messageType === "video" || obj.messageType === "image")
            ) {
              listMediaUrl.push(`kt_modal_${obj.messageType}_${obj.id}`);
            }
          });

          let currentMedia = `kt_modal_${responseMessage.messageType}_${responseMessage.id}`;
          let nextMedia = "";
          let previousMedia = "";
          for (let index = 0; index < listMediaUrl.length; index++) {
            if (listMediaUrl[index] === currentMedia) {
              nextMedia = listMediaUrl[index + 1];
              previousMedia = listMediaUrl[index - 1];
            }
          }

          <ChatFileView
            messageType={responseMessage.messageType}
            mediaURL={responseMessage.mediaUrl}
            messageId={responseMessage.id}
            mediaName={responseMessage.filename}
            previousMedia={previousMedia}
            nextMedia={nextMedia}
            currentMedia={currentMedia}
          />;
        }
      } else {
        //severe
        console.log("Failed To Sent Message");
      }

      // Back to Bottom when User Send New Messages
      if (bottomRef.current) {
        bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
      }
    };

    await Chat.createFriendCollaborationMessage(
      newMessage,
      selectedChat,
      friendChat?.id!,
      (respMessage: newMessageModel, responseJson: string) => {
        //Callback message send
        callback(respMessage, responseJson);
      }
    ).catch((error) => {
      Log.errorWithNavigate(
        nav,
        "Failed to Create Collaboration message : " + selectedChat
      );
    });

    // Sending Message Finished
    if (sendButtonRef) {
      sendButtonRef.current?.removeAttribute("data-kt-indicator");
    }
    // setIsSending(false);
    // console.log("Sending message finished : " +isSending);
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage();
    }
  };

  const onLoadMessage = async () => {
    setIsNotified(false);
    setIsLoadingMessage(true);
    let maxLoaded = false;
    if (bottomRef.current) {
      if (bottomRef.current.scrollTop === 0) {
        setOnLoadMoreMessage(true);
        const initialHeight: number = bottomRef.current.scrollHeight;
        await Chat.fetchCollaborationPrevMessage(
          selectedChat,
          lastVisibleMessage,
          (lastVisible: any) => {
            //Set lastVisible
            dispatch(chat.setLastVisibleMessage(lastVisible));
            lastVisibleMessage = lastVisible;
          }
        ).then(async (prevListMessages: newMessageModel[]) => {
          if (prevListMessages && prevListMessages.length > 0) {
            //current message + prevmessage
            const newMessageList = [...prevListMessages, ...messages];
            //save to redux
            dispatch(chat.setUserListMessages(newMessageList));
            //save to state
            setMessages(newMessageList);
          } else {
            maxLoaded = true;
          }
        });
        if (!maxLoaded) {
          if (bottomRef.current.scrollHeight !== initialHeight) {
            bottomRef.current.scrollTop =
              bottomRef.current.scrollHeight - initialHeight;
          }
        } else {
          bottomRef.current.scrollTop = 0;
        }
        setIsLoadingMessage(false);
        setOnLoadMoreMessage(false);
      }
    }
  };

  const onNotify = (isNotif: boolean) => {
    // if(!onLoadMore){
    if (isNotif) {
      if (bottomRef.current) {
        // bottomRef.current.clientHeight = bottomRef.current.scrollHeight+500;
        bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
        // bottomRef.current.scrollIntoView({behavior: "smooth"});
        // , block: "end", inline: "nearest"})
      }
    }
    return true;
    // }else{
    //   // setOnLoadMoreMessage(false);
    //   return false;
    // }
    // return true;
  };

  // const onSendingMessage = () => {
  //   if(bottomRef.current) {
  //     bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
  //   }
  //   return true;
  // };

  // // textarea resizing v2
  // const divChatBody = document.getElementById("chat-body");
  const tx = document.getElementsByTagName("textarea");
  for (let i = 0; i < tx.length; i++) {
    let scHeight = tx[i].scrollHeight;
    console.log("scHeight: " + scHeight);
    console.log("tx.scHeight value is: " + tx[i].scrollHeight.toString());

    const maxHeightTx = 100;
    const maxHeightChatBody = 70;
    if (tx[i].scrollHeight < maxHeightTx) {
      // divChatBody?.setAttribute(
      //   "style",
      //   "height: " +
      //     maxHeightChatBody +
      //     "%; overflow: hidden scroll; padding-left: 5px; padding-right: 0px;"
      // );
      tx[i].setAttribute(
        "style",
        "height:" +
          scHeight +
          "px;overflow-y:scroll;max-height:100px; z-index:99; background-color:white"
      );
      tx[i].addEventListener("input", OnInput, false);
    } else {
      tx[i].setAttribute(
        "style",
        "height:" +
          maxHeightTx +
          "px;overflow-y:scroll;max-height:100px; z-index:99; background-color:white"
      );
      // tx[i].setAttribute("style", "height:"+ (tx[i].scrollHeight) +"px;overflow-y:scroll;");
      tx[i].addEventListener("input", OnInput, false);
    }
  }

  const onTextMessage = (e: any) => {
    let textValue = e.target.value;
    setMessage(textValue);

    //Disable Send Button when the Text is empty
    // console.log("isi message: " + message);
    // const buttonSend = document.getElementById("sendButton");
    // if (textValue.trim() === "") {
    //   buttonSend?.setAttribute("disabled", "disabled");
    //   const textArea = document.getElementById("chatTextMessage");
    // } else if (textValue !== "") {
    //   buttonSend?.removeAttribute("disabled");
    // }
  };

  function OnInput(this: any) {
    this.style.height = "auto";
    this.style.height = this.scrollHeight + "px";
    // this.style.max.height = "150px";
  }

  const removeFile = () => {
    setMessage("");
    setFile(undefined);
    setFileSize("");
    setInputFileName("");
    setInputContentType("");
    setMediaURL("");
    setmessageType("text");
    setPrevFile("");
  };

  return (
    <div
      // className="card-body"
      className="flex-fill"
      style={{
        border: "top bottom 10px solid #9899AC",
        overflow: "hidden",
        height: "100%",
      }}
      id={isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"}
    >
      <div  
        id="chat-body"
        className="card-body chat-body-custom"
        // style={{height:"55%", overflowY: "scroll"}}
        style={{
          height: isTabletOrMobile ? "450px" : "80%",
          overflowX: "hidden",
          overflowY: "scroll",
          paddingLeft: "5px",
          paddingRight: "0px",
        }}
        onScroll={onLoadMessage}
        // onLoad
        ref={bottomRef}
        // className={clsx("scroll-y me-n5 pe-5")}
        // className={clsx("scroll-y me-n5 pe-5 h-lg-auto", {
        // "h-100": !isDrawer,//"h-lg-auto",
        // })}
        // data-kt-element="messages"
        // data-kt-scroll="true"
        // data-kt-scroll-activate="{default: false, lg: true}"
        // data-kt-scroll-max-height="auto"
        // data-kt-scroll-max-width="auto"
        // data-kt-scroll-dependencies={
        //   isDrawer
        //     ? "#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
        //     : "#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
        // }
        // data-kt-scroll-wrappers={
        //   isDrawer
        //     ? "#kt_drawer_chat_messenger_body"
        //     : "#kt_content, #kt_chat_messenger_body"
        // }
        // // data-kt-scroll-offset={isDrawer ? "0px" : "-2px"}
        // data-kt-scroll-offset="100%"
      >
        {isLoadingCollaboration && (
          <div className="h-100 text-gray-700" style={{ textAlign: "center" }}>
            <span style={{ fontStyle: "italic" }}>{t("HC.Info.LoadChat")}</span>
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </div>
        )}
        {isLoadingMessage && (
          <div className="d-flex justify-content-center p-3">
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </div>
        )}
        {!isLoadingCollaboration &&
          messages?.map((message, index) => {
            return (
              <FriendChatMessage
                message={message}
                key={index}
                index={index}
                isDrawer={isDrawer}
                messages={messages}
                collaborationId={selectedChat}
              />
            );
          })}

        {/* Loading Upload and Send Message */}
        {/* {isSending && onSendingMessage()} */}
      </div>

      <div
        className="card-footer pt-1 sticky-bottom chat-footer-custom"
        // className="card-footer pt-1"
        id={
          isDrawer
            ? "kt_drawer_chat_messenger_footer"
            : "kt_chat_messenger_footer"
        }
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          left: 0,
          justifyContent: "space-between",
          padding: "10px",
          backgroundColor: "white",
        }}
      >
        <div style={{ overflowY: "scroll" }}>
          {/* {messageType !== "document" && messageType !== "audio" && ( */}
            <textarea
            id="chatTextMessage"
              className="form-control form-control-flush mb-3"
              // style={{paddingLeft:"0px"}}
              style={{
                height: isTabletOrMobile ? "" : "60%",
                paddingLeft: "0px",
                maxHeight: "150px",
                backgroundColor: "white",
                zIndex: 99,
                display: (messageType !== "document" && messageType !== "audio") ? "" : "none",
              }}
              rows={1}
              data-kt-element="input"
              placeholder={t("HC.Input.TextMessage")}
              value={message}
              onChange={onTextMessage}
              onKeyDown={onEnterPress}
            ></textarea>
          {/* )} */}
        </div>


        <div 
          className="d-flex space">
          <div className="d-flex align-items-center">
            {!file && (
              <label
                className="btn btn-sm btn-icon btn-active-light-primary me-1"
                // type="button"
                data-bs-toggle="tooltip"
                data-kt-image-input-action="change"
                title={t("HC.Info.Upload")}
              >
                <i className="bi bi-upload text-custom fs-1 p-5"></i>

                <input
                  ref={attachmentRef}
                  id="chat-media"
                  onChange={setPreviewFile}
                  type="file"
                  name="attachment"
                  multiple={false}
                  style={{ display: "none" }}
                />
              </label>
            )}

            {file && <label>{inputFileName}</label>}
            {/* {file && <i title="Remove File" className="bi bi-x-circle fs-1 p-3" onClick={removeFile}></i>} */}
            {file && (
              <label
                className="btn btn-sm btn-icon-danger btn-text-danger me-1"
                title={t("HC.File.RemoveFile")}
              >
                <i className="bi bi-x-circle fs-1" onClick={removeFile}></i>
              </label>
            )}
          </div>

          <div
            className="d-flex align-items-center me-2 bg-primary"
            style={{ right: 0, padding: 2.5}}
          >
            <button
              id="sendButton"
              ref={sendButtonRef}
              className="btn btn-primary"
              type="button"
              data-kt-element="send"
              onClick={sendMessage}
            >
              <span className="indicator-label">
                {t("HC.Button.SendFrom").toUpperCase()}
              </span>
              <span className="indicator-progress">
                {t("HC.Button.SendFrom").toUpperCase()}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* {isNotified && onNotify()} */}
    </div>
  );
};

export default connector(FriendChatInner);