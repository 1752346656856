import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { deleteObject } from "firebase/storage";
import { t } from "i18next";
import db, {newDB} from ".";
import {
  query,
  startAfter,
  where,
  and,
  or,
  collection,
  getCountFromServer,
  
} from "firebase/firestore";
import Product, {Marketplace} from "../app/models/Product";
import Shipment from "../app/models/Shipment";
import { createRef } from "./connection";
import { converter2 } from "./converter";
import { getBrandById } from "./serviceBrand";
import * as Log from "src/util/SDayslogger"

export const getProductByName = async (productName: string) => {
  console.log("getProductByName " + productName);
  return await db
    .collection("products")
    .where("isActive", "==", true)
    .where("name", "==", productName)
    .withConverter(converter2<Product>())
    .limit(1)
    .get()
    .then((snaps) => {
      const product = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      console.log("Data : " + product.length);
      return product;
    })
    .catch((error) => {
      Log.error("Error getProduct By Name : ", error);
    });
};

export const getProductByNameInsensitiveCase = async (
  productNameInsensitive: string
) => {
  console.log("getProductByNameInsensitiveCase " + productNameInsensitive);
  return await db
    .collection("products")
    .where("isActive", "==", true)
    .where("productNameInsensitive", "==", productNameInsensitive)
    .withConverter(converter2<Product>())
    .limit(1)
    .get()
    .then((snaps) => {
      const product = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      console.log("Data : " + product.length);
      return product;
    })
    .catch((error) => {
      Log.error(`Error getProductByNameInsensitiveCase ${error}`);
    });
};

export const createProduct = async (productData: any) => {
  let productid = "";
  await db
    .collection("products")
    .add(productData)
    .then((ref) => {
      productid = ref.id;
      return ref.id;
    })
    .catch((error) => {
      Log.error("Error adding document Product: ", error);
    });
  return productid;
};

export const transactionProduct = async (productData: Product[]) => {
  const productCollectionRef = db.collection("products");
  const logArray: string[] = [];
  const errorArray: string[] = [];
  const idArray: string[] = [];
  const dataProductLog: {
    product: Product;
    log: string;
  }[] = [];

  const transaction = db.runTransaction(async (transaction) => {
    for (const product of productData) {
      const querySnapshot = await productCollectionRef
        .where("parentSKU", "==", product.parentSKU)
        .get();
      console.log(`transaction after query ${product.parentSKU}`);
      if (!querySnapshot.empty) {
        const productName = product.name || "";
        const log = `Product ${productName} with SKU ${
          product.parentSKU || ""
        } already exists. Skipping...`;
        logArray.push(log);
        errorArray.push(log);
        dataProductLog.push({
          product,
          log,
        });
        continue;
      }
      if (!product.brand) {
        const productName = product.name || "";
        const log = `Product ${productName} with SKU ${
          product.parentSKU || ""
        } Brand not created, Skipping...`;
        logArray.push(log);
        errorArray.push(log);
        dataProductLog.push({
          product,
          log,
        });
        continue;
      }

      console.log(`transaction after if ${product.nameInsensitive}`);

      const newProductRef = productCollectionRef.doc(); // Generate a new document reference
      transaction.set(newProductRef, product);
      const productName = product.name || "";
      const log = t("ImportProduct.Success.ImporProduct", {
        productName: productName,
      });
      logArray.push(log);
      idArray.push(newProductRef.id);
      dataProductLog.push({
        product,
        log,
      });
      console.log(`transaction after push ${product.nameInsensitive}`);
    }

    if (errorArray.length > 0) {
      throw new Error("Rollback");
    }
  });

  try {
    await transaction;
    console.log(
      "Products uploaded successfully to Firestore.",
      JSON.stringify(productData)
    );
  } catch (error) {
    Log.error("Error uploading products to Firestore:", error);
    if (error instanceof Error && error.message === "Rollback") {
      errorArray.push("Transaction rolled back.");
    } else {
      errorArray.push("Error uploading products to Firestore.");
    }
  }

  return {
    logArray,
    idArray,
    errorArray,
    productData,
    dataProductLog,
  };
};

export const updateProduct = async (productID: string, productData: any) => {
  let productid = "";
  await db
    .collection("products")
    .doc(productID)
    .set(
      {
        ...productData,
      },
      {
        merge: true,
      }
    )
    .then(() => {
      return productID;
    })
    .catch((error) => {
      Log.error("Error adding document Product: ", error);
    });
  return productid;
};

export const createMedia = async (
  productID: string,
  mediaType: string,
  media: any
) => {
  return await db
    .collection("products")
    .doc(productID)
    .collection(mediaType)
    .add(media)
    .then((ref: any) => {
      let refID = "";
      if (ref && ref.id) {
        refID = ref.id;
      }
      return refID;
    })
    .catch((error: any) => {
      Log.error("Error adding document Media: ", error);
      return "";
    });
};

export const deleteFileFromStorage = (url: any) => {
  try {
    deleteObject(url).then(() => {
      console.log("success Deleted File Media");
    });
  } catch (error) {
    Log.error("Error deleting file: ", error);
  }
};

export const deleteMedia = (
  productID: string,
  mediaID: string,
  mediaType: string
) => {
  db.collection("products")
    .doc(productID)
    .collection(mediaType)
    .doc(mediaID)
    .delete()
    .then(() => {
      console.log("Success Delete Media");
    })
    .catch((error: string) => {
      Log.error(
        "Error deleted Media Doc document (" + mediaID + ") :",
        error
      );
    });
};

export const getAllShipment = async () => {
  return await db
    .collection("shipments")
    .get()
    .then((snaps) => {
      const shipment = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return shipment;
    })
    .catch((error) => {
      Log.error(`Error getAllShipment ${error}`);
    });
};

export const getShipmentParent = async () => {
  return await db
    .collection("shipments")
    .where("isParent", "==", true)
    .where("isActive", "==", true)
    .orderBy("displayOrder", "asc")
    .withConverter(converter2<Shipment>())
    .get()
    .then((snaps) => {
      const shipment = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return shipment;
    })
    .catch((error) => {
      Log.error("Error getProduct By Name : ", error);
    });
};

export const getShipmentParentByInsName = async (nameInsensitive: string) => {
  return await db
    .collection("shipments")
    .where("isParent", "==", true)
    .where("isActive", "==", true)
    .where("shipmentNameInsensitive", "==", nameInsensitive)
    .withConverter(converter2<Shipment>())
    .limit(1)
    .get()
    .then((snaps) => {
      const shipment = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return shipment;
    })
    .catch((error) => {
      Log.error("Error getProduct By Name : ", error);
    });
};

export const getShipmentChildrenByInsName = async (nameInsensitive: string) => {
  return await db
    .collection("shipments")
    .withConverter(converter2<Shipment>())
    .where("isParent", "==", false)
    .where("isActive", "==", true)
    .where("shipmentNameInsensitive", "==", nameInsensitive)
    .get()
    .then((snaps) => {
      const shipments = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id } as Shipment;
      });
      return shipments;
    })
    .catch((error: string) => {
      Log.error("Error getProduct By Name : ", error);
      return [] as Shipment[];
    });
};

export const getShipmentChildren = async (parentid: string) => {
  const shipmentDocRef = db.collection("shipments").doc(parentid);

  const children = await db
    .collection("shipments")
    .where("isParent", "==", false)
    .where("parentId", "==", shipmentDocRef)
    .where("isActive", "==", true)
    .withConverter(converter2<Shipment>())
    .get()
    .then((snaps) => {
      const shipmentChild = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return shipmentChild;
    })
    .catch((error) => {
      Log.error(`Error getShipmentChildren  ${error}`);
      return undefined;
    });
  return children;
};

export const createShipments = async (productID: string, shipments: any) => {
  return await db
    .collection("products")
    .doc(productID)
    .collection("shipments")
    .add(shipments)
    .then((ref: any) => {
      let refID = "";
      if (ref && ref.id) {
        refID = ref.id;
      }
      return refID;
    })
    .catch((error: any) => {
      Log.error("Error adding document Shipments: ", error);
      return "";
    });
};

export const createCompanyAndMarketplace = async (
  productID: string,
  companies: any,
  marketplaces: Marketplace
) => {
  console.log("createMarketplace and company");
  try {
    await db
      .collection("products")
      .doc(productID)
      .set({ companies }, { merge: true });
  } catch (err) {
    Log.error("Error adding company", err);
  }

  try {
    await db
      .collection("products")
      .doc(productID)
      .collection("marketplaces")
      .add(marketplaces);
  } catch (err) {
    Log.error("Error adding product marketplaces", err);
  }
};

/**
 *
 * @param productID
 * @author barox
 */
export const deleteCompanyAndMarketplaces = async (productID: string) => {
  try {
    await db
      .collection("products")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.update({
            companies: firebase.firestore.FieldValue.delete(),
          });
        });
      });
  } catch (err) {
    Log.error("Error adding company", err);
  }

  try {
    await db
      .collection("products")
      .doc(productID)
      .collection("marketplaces")
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach((doc: any) => {
          doc.ref.delete();
        });
      });
  } catch (err) {
    Log.error("Error adding product marketplaces", err);
  }
};

/**
 *
 * @param productID
 * @author barox
 */
export const deleteShipments = async (productID: string) => {
  try {
    await db
      .collection("products")
      .doc(productID)
      .collection("shipments")
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach((doc: any) => {
          doc.ref.delete();
        });
      });
  } catch (err) {
    Log.error("Error adding product marketplaces", err);
  }
};

/**
 *
 * @param productID
 * @author barox
 */
export const deleteMediaDoc = async (productID: string) => {
  try {
    await db
      .collection("products")
      .doc(productID)
      .collection("photos")
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach((doc: any) => {
          doc.ref.delete();
        });
      });
  } catch (err) {
    Log.error("Error adding product marketplaces", err);
  }

  try {
    await db
      .collection("products")
      .doc(productID)
      .collection("video")
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach((doc: any) => {
          doc.ref.delete();
        });
      });
  } catch (err) {
    Log.error("Error adding product marketplaces", err);
  }
};

export const createCompany = async (productID: string, companies: any) => {
  try {
    await db
      .collection("products")
      .doc(productID)
      .set({ companies }, { merge: true });
  } catch (err) {
    Log.error("Error adding company", err);
  }
};

export const createMarketplace = async (
  productID: string,
  marketplace: any
) => {
  console.log(`createMarketplace document : ${JSON.stringify(marketplace)}`);
  if (!marketplace) return;
  return await db
    .collection("products")
    .doc(productID)
    .collection("marketplaces")
    .add(marketplace)
    .then((ref: any) => {
      let refID = "";
      if (ref && ref.id) {
        refID = ref.id;
      }
      console.log("refid : ", refID);
      return refID;
    })
    .catch((error: any) => {
      Log.error("Error adding product marketplaces", error);
      return "";
    });
};

export const createVariant = async (productID: string, variant: any) => {
  return await db
    .collection("products")
    .doc(productID)
    .collection("variants")
    .add(variant)
    .then((ref: any) => {
      let refID = "";
      if (ref && ref.id) {
        refID = ref.id;
      }
      return refID;
    })
    .catch((error: any) => {
      Log.error("Error adding variant", error);
      return "";
    });
};

/**
 * @author rifqi
 * @param productID
 * @param variants
 */
export const createVariants = async (productID: string, variants: any[]) => {
  const batch = db.batch();

  variants.forEach((variant) => {
    console.log(
      `createDataVariants service for each productID ${productID}|| variants ${JSON.stringify(
        variants
      )}`
    );
    const ref = db
      .collection("products")
      .doc(productID)
      .collection("variants")
      .doc();
    batch.set(ref, variant);
  });

  return await batch
    .commit()
    .then(() => {
      console.log("Variants added successfully");
    })
    .catch((error: any) => {
      Log.error("Error adding variants", error);
    });
};

/**
 *
 * @param productID
 * @author rifqi
 */
export const removeVariants = async (productID: string) => {
  return await db
    .collection("products")
    .doc(productID)
    .collection("variants")
    .get()
    .then((querySnapshot: any) => {
      querySnapshot.forEach((doc: any) => {
        doc.ref.delete();
      });
    })
    .catch((error: any) => {
      Log.error("Error removing variants", error);
    });
};

export const createProductVariants = async (
  productID: string,
  productVariants: any[]
) => {
  console.log("Enter Create Product Variant");
  const batch = db.batch();
  const productRef = db.collection("products").doc(productID);
  for (const productVariant of productVariants) {
    const productVariantRef = productRef.collection("productvariants").doc();
    batch.set(productVariantRef, productVariant);
  }
  return batch
    .commit()
    .then(() => {
      console.log("Variant List Updated Success");
    })
    .catch((error: any) => {
      Log.error("Error adding product variants", error);
    });
};

export const removeProductVariants = async (productID: string) => {
  return db
    .collection("products")
    .doc(productID)
    .collection("productvariants")
    .get()
    .then((querySnapshot: any) => {
      const batch = db.batch();
      querySnapshot.forEach((doc: any) => {
        batch.delete(doc.ref);
      });
      return batch.commit();
    })
    .catch((error: any) => {
      Log.error("Error removing product variants", error);
    });
};

export const createProductVariant = async (
  productID: string,
  productVariant: any
) => {
  return await db
    .collection("products")
    .doc(productID)
    .collection("productvariants")
    .add(productVariant)
    .then((ref: any) => {
      let refID = "";
      if (ref && ref.id) {
        refID = ref.id;
      }
      return refID;
    })
    .catch((error: any) => {
      Log.error("Error adding product variant", error);
    });
};

export const createOrUpdateProductCategoryAttribute = async (
  productID: string,
  attributeId: string,
  categoryAttribute: any
) => {
  return await db
    .collection("products")
    .doc(productID)
    .collection("categoryAttributes")
    .doc(attributeId)
    .set({ ...categoryAttribute }, { merge: true })
    .then((ref: any) => {
      let refID = "";
      if (ref && ref.id) {
        refID = ref.id;
      }
      return refID;
    })
    .catch((error: any) => {
      Log.error("Error adding product variant", error);
    });
};

export const removeProductCategoryAttributes = async (productID: string) => {
  return db
    .collection("products")
    .doc(productID)
    .collection("categoryAttributes")
    .get()
    .then((querySnapshot: any) => {
      const batch = db.batch();
      querySnapshot.forEach((doc: any) => {
        batch.delete(doc.ref);
      });
      return batch.commit();
    })
    .catch((error: any) => {
      Log.error("Error removing product categoryAttributes", error);
    });
};

export const getCompanyRefByCompanyName = async (
  companyName: string
): Promise<string | undefined> => {
  try {
    const snapshot = await db
      .collection("company")
      .where("name", "==", companyName)
      .limit(1)
      .get();
    return snapshot.docs[0].id;
  } catch (error) {
    console.log("Error getting company ref: ", error);
  }
};

export const getAllProduct = async () => {
  return await db
    .collection("products")
    .where("status", "!=", "drafted")
    .where("isActive", "==", true)
    .orderBy("status", "asc")
    .orderBy("name", "asc")
    .get()
    .then((snaps) => {
      const productList = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return productList;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr getAllproducts : " + error);
    });
};

export const getProductById = async (id: string) => {
  return await db
    .collection("products")
    .doc(id)
    .get()
    .then((ref) => {
      return { id: ref.id, data: ref.data() };
    })
    .catch((err) => {
      Log.error("Error fetching product id", err);
    });
};

export const getProductPhotosByProductId = async (productID: string) => {
  const productSnapshot = await db
    .collection("products")
    .doc(productID)
    .collection("photos")
    .get();
  return productSnapshot.docs.map((photo) => {
    return photo.data();
  });
};

// export const getVariantPhotosByProductId = async (productID: string) => {
//   const productSnapshot = await db
//     .collection("products")
//     .doc(productID)
//     .collection("variants")
//     .where("index", "==", 0)
//     .limit(1)
//     .get()

//   return productSnapshot.docs.map((variant) => variant.data())
// }

export const getProductVideoByProductId = async (productID: string) => {
  const productSnapshot = await db
    .collection("products")
    .doc(productID)
    .collection("video")
    .get();
  return productSnapshot.docs.map((video) => {
    return video.data();
  });
};

export const getProductShipmentByProductId = async (productID: string) => {
  const productSnapshot = await db
    .collection("products")
    .doc(productID)
    .collection("shipments")
    .get();
  return productSnapshot.docs.map((shipment) => {
    return shipment.data();
  });
};

export const getVariantCategoryByProductId = async (productID: string) => {
  const variantSnapshot = await db
    .collection("products")
    .doc(productID)
    .collection("variants")
    .get();

  return variantSnapshot.docs.map((variant) => variant.data());
};

export const getVariantListByProductId = async (productID: string) => {
  const variantSnapshot = await db
    .collection("products")
    .doc(productID)
    .collection("productvariants")
    .get();

  return variantSnapshot.docs.map((variant) => variant.data());
};

export const getMarketplacesByProductID = async (productID: string) => {
  const variantSnapshot = await db
    .collection("products")
    .doc(productID)
    .collection("marketplaces")
    .get();

  return variantSnapshot.docs.map((variant) => variant.data());
};

export const getProductCategoryAttributesByProductId = async (
  productID: string
) => {
  const productSnapshot = await db
    .collection("products")
    .doc(productID)
    .collection("categoryAttributes")
    .get();
  return productSnapshot.docs.map((atributes) => {
    return atributes.data();
  });
};

/*
export const getAllProductList = async (clientId: string) => {
  console.log("client ID", createRef("clients", clientId));
  const productSnapshot = await db
    .collection("products")
    .where("client", "==", createRef("clients", clientId))
    .orderBy("createdAt", "desc")
    .withConverter(converter2<Product>())
    .get();

  const productList = await Promise.all(
    productSnapshot.docs.map(async (productDoc) => {
      const product = productDoc.data();
      const coverImageSnapshot = await db
        .collection("products")
        .doc(productDoc.id)
        .collection("photos")
        .where("isCover", "==", true)
        .limit(1)
        .get();

      const coverImg = coverImageSnapshot.docs[0]?.data().fileURL || "";

      product.id = productDoc.id;
      product.coverImg = coverImg;
      product.variantLength = (await getVariantLength(productDoc.id)) || 0;
      product.marketplaceCategory = await getProductMarketplaceCategoryById(productDoc.id);

      if (product.brand) {
        product.brandModel = await getBrandById(product.brand.id);
      }

      return product;
    })
  );

  console.log("PRODLIST Product List", productList);
  return productList;
};
*/

/**
 * Get total product of client
 * @param clientId 
 */
export const getProductCount = async (clientId:string) => {
  
  const coll = collection(newDB, "products")
  const q = query(coll, where("client", "==", createRef("clients", clientId)))
  const snapshot = await getCountFromServer(q)
  return snapshot.data().count
}

/**
 * 
 * @param clientId 
 * @param sort 
 * @param sortOrder 
 * @param limit if <=0 no limit 
 * @param startAfterDocId 
 * @returns 
 */
export const getProducts = async (clientId: string,  
  sort:string = "createdAt", sortOrder: "desc"|"asc" = "desc", limit:number = 0, startAfterDocId:string | undefined = undefined) => {
  Log.info(`getProducts for client ID ${clientId}`);
  
  let query = db
    .collection("products")
    .where("client", "==", createRef("clients", clientId))
    .orderBy(sort, sortOrder)

  if (limit>0) query = query.limit(limit)

  if (startAfterDocId) {
    const startDoc = await createRef("products",startAfterDocId).get()
    if (startDoc.exists)
      query = query.startAfter(startDoc)
    else {
      Log.error(`product ${startAfterDocId} not found`)
    }
  }

  const snapshot = await query
    .withConverter(converter2<Product>())
    .get()
    
  
  const productList = await Promise.all(snapshot.docs.map(async (snap)=> {
    const product = snap.data()
    product.id = snap.id;
  
    Log.debug(`product ${product.id} loaded`)

    const coverImageSnapshot = await snap.ref
      .collection("photos")
      .where("isCover", "==", true)
      .limit(1)
      .get();
    const coverImg = coverImageSnapshot.docs[0]?.data().fileURL ?? "";
    product.coverImg = coverImg;

    Log.debug(`product ${product.id} - coverImage loaded`)

    product.variantLength = (await getVariantLength(product.id)) || 0;
    Log.debug(`product ${product.id} - variant length ${product.variantLength} loaded`)
    if (product.brand) {
      product.brandModel = await getBrandById(product.brand.id);
    }
    product.marketplaceCategory = await getProductMarketplaceCategoryById(snap.id);
    return product
  }))
    
  Log.debug("PRODLIST Product List", productList);
  return productList;
};

const getVariantLength = async (id: string) => {
  const snapshot = await db
    .collection("products")
    .doc(id)
    .collection("variants")
    .get();

  const variantList = snapshot.docs
    .filter(
      (variant: any) =>
        variant.data().optionList && variant.data().optionList.length > 0
    )
    .map((variant: any) => variant.data().optionList.length);

  Log.debug("VARLIST", variantList);

  return variantList.length === 0
    ? 0
    : variantList.reduce((acc, cur) => acc * cur, 1);
};

export const getisHaveVideo = async (id: string) => {
  const snapshot = await db
    .collection("products")
    .doc(id)
    .collection("video")
    .get();
  return snapshot.size;
};

export const getProductMarketplaceCategoryById = async (id: string) => {
  return await db
    .collection("products")
    .doc(id)
    .collection("marketplaces")
    .withConverter(converter2<Marketplace>())
    .get()
    .then((snaps) => {

      Log.debug("products marketplace : ", snaps.docs.length);
      return snaps.docs.map((doc: any) => ({ ...doc.data(), id: doc.id } as Marketplace));
      
      
    })
    .catch((err) => {
      Log.error(
        "Error fething products marketplace category collection",
        err
      );
      return [] as Marketplace[]
    });
};

export const deleteProductById = async (id: string) => {
  await db
    .collection("products")
    .doc(id)
    .update({ isActive: false, updatedAt: new Date() });
};

export const getProductByStorefront = async (storefrontId: string) => {
  console.log("getProductByStorefront with storefront id : " + storefrontId);
  return new Promise<number>((resolve, reject) => {
    firebase
      .database()
      .ref(`/storefront/${storefrontId}/numberOfProducts`)
      .on("value", (snapshot) => {
        // let numberOfProducts = 0;
        
        const numberOfProducts = snapshot.val() as number;
        Log.info(`storefront ${storefrontId} numberOfProducts #${numberOfProducts}`);
        
        resolve(numberOfProducts);
      })
      

  })
  
  
  //return numberOfProducts;
};

export const updateIncrementNumberOfProductsStorefront = async (
  storefrontId: string
) => {
  console.log(
    "updateIncrementNumberOfProductsToStorefront with storefront id : " +
      storefrontId
  );
  await firebase
    .database()
    .ref("storefront")
    .child(storefrontId)
    .child("numberOfProducts")
    .set(firebase.database.ServerValue.increment(1));
};

export const updateDecrementNumberOfProductsToStorefront = async (
  storefrontId: string
) => {
  console.log(
    "updateDecrementNumberOfProductsToStorefront with storefront id : " +
      storefrontId
  );
  await firebase
    .database()
    .ref("storefront")
    .child(storefrontId)
    .child("numberOfProducts")
    .set(firebase.database.ServerValue.increment(-1));
};

export const getProductBySkuOrParentSkuOrVariantSku = async (sku: string) => {
  try {
    const withParentSku = db
      .collection("products")
      .where("parentSKU", "==", sku)
      .get();
    const withSku = db.collection("products").where("sku", "==", sku).get();
    const withVariantSku = db
      .collectionGroup("productvariants")
      .where("sku", "==", sku)
      .get();

    const [parentSkulSnap, skuSnap, variantSkuSnap] = await Promise.all([
      withParentSku,
      withSku,
      withVariantSku,
    ]);
    const parentData = parentSkulSnap.docs.length
      ? parentSkulSnap.docs.map((doc) => ({ ...doc.data() }))
      : [];
    const skuData = skuSnap.docs.length
      ? skuSnap.docs.map((doc) => ({ ...doc.data() }))
      : [];
    const variantData = variantSkuSnap.docs.length
      ? variantSkuSnap.docs.map((doc) => ({ ...doc.data() }))
      : [];

    return [...parentData, ...skuData, ...variantData];
  } catch (error: any) {
    console.log(error.toString ? error.toString() : error);
  }
};
