import { useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useQueryResponseLoading } from "../../core/QueryResponseProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { RootState } from "../../../../../../../setup/redux/store";
import * as ProductListRedux from "../../../../../../modules/product/productList/ProductListSlice";
import * as Log from "src/util/SDayslogger"
const ProductListPagination = () => {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const isLoading = useQueryResponseLoading();
  const { state, updateState } = useQueryRequest();
  const count = useSelector(
    (state: RootState) => state.ProductList.countProduct
  );
  const arrayProductPagination = useSelector(
    (state: RootState) => state.ProductList.arrayProductPagination
  );
  const [value, setValue] = useState(10);
  const [page, setPage] = useState(1);
  // const [arrPagination, setArrPagination] = useState<number[]>([]);
  const [totalItems, setTotalItems] = useState(10);
  const [defItemView, setDefItemView] = useState(10);
  const [minimumPage, setMinimumPage] = useState(1);
  const [pagination, setPagination] = useState<number[]>([1, 2, 3, 4, 5, 6, 7]);
  const [currentButton, setCurrentButton] = useState<string>("1");
  // const [maxPage, setMaxPage] = useState(10)

  useEffect(() => {
    setPage(1);
  }, [totalItems]);

  useEffect(() => {
    setPage(1);
  }, [state.sort, state.search]);

  let maxPage = Math.ceil(count / state.items_per_page);
  Log.debug("max page ==>> " + maxPage);

  useEffect(() => {
    maxPage = Math.ceil(count / state.items_per_page);
    const numberOfPages: any = [];
    for (let i = 1; i <= maxPage; i++) {
      numberOfPages.push(i + "");
    }

    Log.debug(`ProductListPagination - Page Clicked ${currentButton}`);

    let tempNumberOfPages: string[] = [...arrayProductPagination];

    let dotsInitial = "...";
    let dotsLeft = "... ";
    let dotsRight = " ...";

    if (numberOfPages.length < 6) {
      // [1,2,3,4,5,6]
      tempNumberOfPages = numberOfPages;
      MovePage(parseInt(currentButton));
    } else if (currentButton === dotsInitial) {
      //Action Selected Dot Initial
      
      // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
      // arrOfCurrButtons[3] = 4 + 1 = 5
      // or
      // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
      // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
      const value =
        parseInt(arrayProductPagination[arrayProductPagination.length - 3]) + 1;
      setCurrentButton(value + "");
      MovePage(value);
    } else if (currentButton === dotsRight) {
      //Action Selected Dot Right
      const value = parseInt(arrayProductPagination[3]) + 2;
      setCurrentButton(value + "");
      MovePage(value);
    } else if (currentButton === dotsLeft) {
      //Action Selected Dot Left
      const value = parseInt(arrayProductPagination[3]) - 2;
      setCurrentButton(value + "");
      MovePage(value);
    } else if (parseInt(currentButton) >= 1 && parseInt(currentButton) <= 3) {
      //[1, 2, 3, 4, "...", 10]
      tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numberOfPages.length];
      MovePage(parseInt(currentButton));
    }
    // else if (parseInt(currentButton)  === 4) {//[1, 2, 3, 4, 5"...", 10]
    //   const sliced = numberOfPages.slice(0, 5);
    //   tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length];
    //   MovePage(parseInt(currentButton));
    // }
    else if (
      parseInt(currentButton) >= 4 &&
      parseInt(currentButton) < numberOfPages.length - 2
    ) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = numberOfPages.slice(
        parseInt(currentButton) - 2,
        parseInt(currentButton)
      ); // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = numberOfPages.slice(
        parseInt(currentButton),
        parseInt(currentButton) + 1
      ); // sliced1 (5, 5+1) -> [6]
      tempNumberOfPages = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        numberOfPages.length,
      ]; // [1, '...', 4, 5, 6, '...', 10]
      MovePage(parseInt(currentButton));
    } else if (parseInt(currentButton) > numberOfPages.length - 3) {
      // > 7  // [1, '...', 6, 7, 8, 9, 10]
      const sliced = numberOfPages.slice(numberOfPages.length - 4); // slice(10-4)
      tempNumberOfPages = [1, dotsLeft, ...sliced];
      MovePage(parseInt(currentButton));
    }
    dispatch(ProductListRedux.setArrayProductPagination(tempNumberOfPages));
  }, [currentButton, count]);

  const updateTotalItem = (totalItem: number) => {
    Log.debug("update total item per page")
    setTotalItems(totalItem);
    if (count / totalItem <= 6) {
      let arr: string[] = [];
      Log.debug(
        `--->> Array round : ${count}/${totalItem} = ${Math.ceil(count / totalItem)} (${count / totalItem})`
      );
      for (let index = 1; index <= Math.ceil(count / totalItem); index++) {
        arr.push(index + "");
      }
      Log.debug("--->> Array Index : " + arr);
      dispatch(ProductListRedux.setArrayProductPagination(arr));
    } else {
      dispatch(
        ProductListRedux.setArrayProductPagination([
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.ceil(count / totalItem) + "",
        ])
      );
    }
    updateState({ items_per_page: totalItem, action: "noAction", page: 1 });
  };

  const PrevItemPage = () => {
    if (page <= 1) {
      return;
    }
    let pages = page - 1;
    setCurrentButton(pages + "");
    setPage(pages);
    updateState({ action: "prev", page: page, items_per_page: totalItems });
  };

  const NextItemPage = () => {
    if (page >= maxPage) {
      return;
    } else {
      let pages = page + 1;
      setCurrentButton(pages + "");
      setPage(pages);
      updateState({ action: "next", page: page, items_per_page: totalItems });
    }
  };

  const MovePage = (pageTo: number) => {
    setPage(pageTo);
    updateState({ page: pageTo, items_per_page: totalItems });
  };

  const updateCurrentButton = (index: string) => {
    Log.debug("Clicked Button :" + index);
    setCurrentButton(index);
  };

  return (
    <div
      className={clsx({
        "row align-items-center justify-content-center py-5": !isTabletOrMobile,
        "p-5 d-flex align-items-center justify-content-between": isTabletOrMobile,
      })}
    >
      <div
        className={clsx({
          "col-4 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start":
            !isTabletOrMobile,
            "pb-3": isTabletOrMobile
        })}
      >
        <div
          v-if="enableItemsPerPageDropdown"
          className="dataTables_length flex-left"
          id="kt_customers_table_length"
          data-testid="pagination"
        >
          <label>
            <select
              name="kt_customers_table_length"
              className="form-select form-select-sm form-select-solid"
              defaultValue={defItemView}
              onChange={(e) => {
                const selectedValue = e.target.value;
                updateTotalItem(parseInt(selectedValue));
                setCurrentButton("1");
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
              <option value={60}>60</option>
              <option value={70}>70</option>
              <option value={80}>80</option>
              <option value={90}>90</option>
              <option value={100}>100</option>
            </select>
          </label>
        </div>
      </div>
      <div
        className={clsx({
          "py-0 my-0 col-8 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end":
            !isTabletOrMobile,
        })}
      >
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            {/* Button Previous */}
            {page <= 1 ? (
              <li className="py-0 my-0 page-item disabled">
                <a className="py-0 my-0 page-link " style={{ cursor: "pointer" }}>
                  {isTabletOrMobile ? (
                    <span>«</span>
                  ) : (
                    <span>« {t("Contacts.Pages.Previous")}</span>
                  )}
                </a>
              </li>
            ) : (
              <li className="py-0 my-0 page-item ">
                <a
                  className="py-0 my-0 page-link "
                  style={{ cursor: "pointer" }}
                  onClick={PrevItemPage}
                >
                  {isTabletOrMobile ? (
                    <span>«</span>
                  ) : (
                    <span>« {t("Contacts.Pages.Previous")}</span>
                  )}
                </a>
              </li>
            )}
            {/* End Of Button Previous */}

            {/* Number Button */}
            {arrayProductPagination.map((i) => {
              if (i == page + "") {
                return (
                  <li key={i} className="py-0 my-0 page-item active">
                    <p
                      className={clsx("py-0 my-0 page-link", {
                        "me-3": !isTabletOrMobile,
                      })}
                      key={i}
                      onClick={() => setCurrentButton(i)}
                    >
                      {i}
                    </p>
                  </li>
                );
              }
              return (
                <li key={i} className="py-0 my-0 page-item">
                  <a
                    className={clsx("py-0 my-0 page-link", { "me-3": !isTabletOrMobile })}
                    style={{ cursor: "pointer" }}
                    onClick={() => updateCurrentButton(i)}
                  >
                    {i}
                  </a>
                </li>
              );
            })}
            {/* End of Number Button

            {/* Next Button */}
            {page === Math.ceil(maxPage) ? (
              <li className="py-0 my-0 page-item disabled">
                <a className="py-0 my-0 page-link " style={{ cursor: "pointer" }}>
                  {isTabletOrMobile ? (
                    <span>»</span>
                  ) : (
                    <span>{t("Contacts.Pages.Next")} »</span>
                  )}
                </a>
              </li>
            ) : (
              <li className="py-0 my-0 page-item ">
                <a
                  className="py-0 my-0 page-link "
                  style={{ cursor: "pointer" }}
                  onClick={NextItemPage}
                >
                  {isTabletOrMobile ? (
                    <span>»</span>
                  ) : (
                    <span>{t("Contacts.Pages.Next")} »</span>
                  )}
                </a>
              </li>
            )}
            {/* End Of Next Button */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { ProductListPagination };
