/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as ReduxTemplate from "../../../../../modules/template/redux/TemplateSlice";
import Template, { templateState } from "../../../../../models/Template";
import {
  templateStatus,
} from "../../../../../models/Language";
import PreviewTemplateModal from "../../components/modal/PreviewTemplateModal";

type Props = {
  template: Template;
  languages: any;
};

const TemplateInfoCell: FC<Props> = ({ template, languages }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const modalClose = () => setModalOpen(false);
  let templateName = "";
  let templateid = "";

  if (template.templateName !== undefined) {
    templateName = template.templateName;
  }

  const dataLanguage = languages;
  if (dataLanguage !== undefined && dataLanguage.length > 0) {
    for (let index = 0; index < languages.length; index++) {
      const element = languages[index];
      if (
        element.templateLanguage !== undefined &&
        element.templateLanguage !== "" &&
        element.templateStatus !== undefined &&
        element.templateStatus !== ""
      ) {
        if (element.templateStatus === templateStatus.rejected) {
          if (template.id !== undefined) {
            templateid = template.id;
          }
        }
      }
    }
  }

  return (
    <>
      <div
        className="d-flex align-items-center"
        onClick={() => {
          setModalOpen(!modalOpen);
        }}
      >
        {templateid && templateid !== "" && (
          <div className="d-flex flex-column">
            <Link
              to={"/template/update/" + templateid}
              state={{ id: templateid }}
              className="text-gray-600 text-hover-primary mb-1"
              onClick={() => {
                console.log("id dari template id: " + templateid);
                dispatch(ReduxTemplate.cleanReduxTemplate(true));
                dispatch(ReduxTemplate.setTemplateID(templateid));
                dispatch(ReduxTemplate.setTemplateState(templateState.update));
              }}
              // className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
            >
              {templateName}
            </Link>
          </div>
        )}
        {(!templateid ||
          templateid === "" ||
          templateid === undefined ||
          templateid === null) && (
          <div className="d-flex flex-column">{templateName}</div>
        )}
      </div>
      {modalOpen && (
        <PreviewTemplateModal
          isOpen={modalOpen}
          onClose={modalClose}
          idTemplate={template.id}
        />
      )}
    </>
  );
};

export { TemplateInfoCell };
