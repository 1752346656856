/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ID, KTSVG, QUERIES } from "../../../../../resources/helpers";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Chat from "../../../../../actions/chat";
import * as chat from "../../../../../app/modules/chat/redux/ChatSlice";
import * as CIQ from "../../../../../app/modules/ciq/redux/CIQSlice";
import { createNotificationAnotherUser } from "../../../../../db/serviceUser";
import { Link } from "react-router-dom";
import { RootState } from "../../../../../setup/redux/store";
import * as lc from "../../../../modules/localstorage/index";
import { removeFromNotificationsByCollabID } from "src/app/modules/notify/Notification/helper";
import * as collabsService from "../../../../../db/serviceCollaborations";
import * as roomService from "../../../../../db/serviceRoom";
import { ChatType } from "src/app/modules/chat/feature";

type Props = {
  id: ID;
  collaborationId: string;
  indexId: number;
};

export const handleCIQ = async (collaborationId: string, roomId: string) => {
    try {
      const userId = lc.getItemLC(lc.LCName.User)?.uid;
      removeFromNotificationsByCollabID(collaborationId);
      await collabsService.setStatusToHandled(collaborationId, userId);
      await roomService.setStatusToHandled(collaborationId, roomId, userId);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

const CIQActionsCell: FC<Props> = ({ id, collaborationId, indexId }) => {
  const dispatch = useDispatch();
  const roomId = id?.toString() ?? "";
  const { t } = useTranslation();

  const updateItem = useMutation(
    async () => {
      const handleCIQResult = await handleCIQ(collaborationId, roomId);
      const collab = await Chat.fetchCollaborationByID(collaborationId);
      if (!collab) {
        return;
      }
      if (handleCIQResult) {
        dispatch(CIQ.deleteItemListCIQ(roomId));
        dispatch(chat.updateChatList(collab));
        dispatch(chat.setSelectedChat(collab.id));
        dispatch(chat.setSelectedRoom(roomId));

        if (collab.company && collab.company?.id && collab.customer) {
          createNotificationAnotherUser(collab);
        }
      }
      else {
        dispatch(chat.updateAllChatChatList(collab));
      }
    },
  );

  return (
    <>
      <div className="d-flex flex-row">
        <Link
          className="btn btn-primary btn-sm p-2 py-1 mt-0"
          to={"/handled-customer"}
          onClick={async () => {
            await updateItem.mutateAsync();
            dispatch(chat.setCurrentChatState(ChatType.mychat));
          }}
          data-testid="button-handle"
        >
          {t("CIQ.Button.Handle")}
        </Link>
      </div>
    </>
  );
};

export { CIQActionsCell };
