import { requestSendEmailResetPassword } from "../../../../api/server/users";
import { createToken } from "../../../../db"

const sendEmailResetPassword = async (userName: string, userId: string, email: string) => {
  let token;
  let result= false;
  //generate token, save to realtime database
  await createToken(userId, "RP").then(async (res) => {
    token = res;
    console.log(token);
  
    //request sent email to server side with axios
    await requestSendEmailResetPassword(userName,email,token).then(async (response) => {
      let resp = response;
      console.log("response : " + resp)
      if(resp === 200 ){
        result= true
      }else{
        result=  false
      }
    })
  })
  return Promise.resolve(result);
}

export { sendEmailResetPassword }