import axios, { AxiosResponse } from "axios";
import firebase from "firebase/compat/app";
import { getProductByStorefrontID } from "../../../../../../actions/product";
import { deleteStorefrontData } from "../../../../../../actions/storefront";
import {
  getAllStorefront,
  getStorefrontById,
  getStorefrontMarketplaceCategoryById,
} from "../../../../../../db/serviceStorefront";
import { ID } from "../../../../../../resources/helpers";
import Storefront, { storefrontStatus, StorefrontQueryResponse } from "../../../../../models/Storefront";
import * as Log from "src/util/SDayslogger"

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`

const getStorefronts = async (
  sort: string | undefined,
  order: string | undefined,
  search: string | undefined,
  action: string | undefined,
  lastId: string | undefined,
  limit: number
): Promise<Array<Storefront>> => {
  let storefrontQueryResponse: StorefrontQueryResponse;
  console.log("item : " + limit);
  console.log("action : " + action);

  let sortBy: string = "name";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";

  if (sort !== undefined && sort !== null) {
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    if (order === "desc") orderBy = "desc";
  }

  if (search !== undefined && search !== null) {
    searchBy = search;
  }
  console.log("order =====>>" + orderBy);

  //TODO proses get data
  let dataStorefronts: Array<Storefront> = [];
  let dataStorefront = undefined;
  let marketplaceCategory: any;
  let numberOfProducts:number = 0;

  function countResponse(arrayData: any, value: boolean) {
    let count = 0;
    arrayData.forEach((data: any) => {
      console.log("check value : ", data);
      Object.keys(data).filter(function (key) {
        data[key] === value && count++;
      });
    });
    return count;
  }

  dataStorefront = await getAllStorefront("table");

  if (dataStorefront && dataStorefront.length > 0) {
    for (var i = 0; i < dataStorefront.length; i++) {
      let data = dataStorefront[i].data;
      data.id = dataStorefront[i].id;

      if (data.id) {
        marketplaceCategory = await getStorefrontMarketplaceCategoryById(
          data.id
        );
        if (marketplaceCategory) {
          data.marketplaceCategory = marketplaceCategory;
        }
        numberOfProducts = await getProductByStorefrontID(data.id);
        Log.debug("numberOfProducts : ", numberOfProducts);
        if (!data.numberOfProducts) {
          data.numberOfProducts = numberOfProducts;
        }
      }
      if (!data.numberOfProducts) {
        data.numberOfProducts = 0;
      }
      console.log("check data status -> ", data.status);
      console.log("data.marketplaceRegistered -> ", data.marketplacesRegistered);

      if (
        data.status === storefrontStatus.requested &&
        data.marketplacesRegistered &&
        data.marketplacesRegistered.length > 0
      ) {
        const dataStorefrontRegistered = [...data.marketplacesRegistered];
        const successResponse = countResponse(dataStorefrontRegistered, true);
        const failedResponse = countResponse(dataStorefrontRegistered, false);
        data.status = `${successResponse} Common.Modal.Column.Contents.Status.Success / ${failedResponse} Common.Modal.Column.Contents.Status.Failed`;
        console.log("update status : ", data.status);
      }
      console.log("data.marketplaceCategory : ", data.marketplaceCategory);
      console.log("data.numberOfProducts : ", data.numberOfProducts);
      console.log("data.status : ", data.status);

      dataStorefronts.push(data as Storefront);
    }
  }

  console.log("dataStorefronts ", dataStorefronts);

  if (dataStorefronts.length > 0) {
    console.log("Storefront data exist...");
  }

  return Promise.resolve(dataStorefronts);
};

const deleteStorefront = (storefrontId: ID): Promise<void> => {
  if (storefrontId === undefined || storefrontId === null) {
  } else {
    deleteStorefrontData(storefrontId.toString());
  }

  return Promise.resolve();
};

const getStorefrontMarketplaceDataById = (storefrontId: ID): Promise<void> => {
  if (storefrontId === undefined || storefrontId === null) {
  } else {
    getStorefrontMarketplaceCategoryById(storefrontId.toString());
  }
  return Promise.resolve();
};

const deleteSelectedStorefronts = (storefrontsId: Array<ID>): Promise<void> => {
  const requests = storefrontsId.map((id) => {
    if (id === undefined || id === null) {
    } else {
      console.log("Delete id : " + id);
      deleteStorefrontData(id.toString());
    }
  });

  return axios.all(requests).then(() => {});
};

export {
  deleteSelectedStorefronts,
  deleteStorefront,
  getStorefrontMarketplaceDataById,
  getStorefronts,
};
