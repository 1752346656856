import { FC, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Message from "../../../../models/Message";
import Account from "../../../../models/Account";
import Customer from "../../../../models/Customer";
import User from "../../../../models/User";
import { MenuComponent } from "../../../../../resources/assets/ts/components";
import * as Chat from "../../../../../actions/chat";
import * as lc from "../../../../modules/localstorage/index";
import { useNavigate } from "react-router-dom";
import * as Log from "../../../../../util/SDayslogger";
import * as actAccount from "../../../../../actions/account";
import { createRef } from "../../../../../db/connection";
import firebase from "firebase/compat/app";

interface MessageProps {
  failedMessage: Message;
  failedCustomer: Customer | undefined;
  failedUser: User | undefined;
  failedCollaborationId: string;
  failedUserLastInteraction?: firebase.firestore.Timestamp;
  companyID: string;
  // onDeleteMessage: (messageId: string) => void;
}

const ChatFailedMenu: FC<MessageProps> = (props) => {
  const { t } = useTranslation();
  const nav = useNavigate();
  // const message = props.failedMessage;
  const {
    failedMessage,
    failedUser,
    failedCollaborationId,
    failedCustomer,
    companyID,
    failedUserLastInteraction,
  } = props;
  // const failedCustomer = failedMessage.customerModel;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const resendButtonRef = useRef<HTMLButtonElement>(null);
  // const [companyID, setCompanyID] = useState<string>(lc.getItemLC(lc.LCName.CompanyID).toString());

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 50);
    // console.log("Date Now : ",firebase.firestore.Timestamp.now());
    console.log("ffaill Created Message : ", failedMessage.createdAt);
    console.log("ffaill Failed Code Message : ", failedMessage.errorCode);
    console.log(
      "ffaill Failed Code Error Detail : ",
      failedMessage.errorDetail
    );
    console.log("ffaill Failed Result Code : ", failedMessage.resultCode);
    console.log("ffaill Failed Customer : ", failedCustomer);
    console.log("ffaill Failed User : ", failedUser);
    console.log("ffaill Failed Colaboration : ", failedCollaborationId);
    console.log(
      "ffaill Failed User Last Interaction At : ",
      failedUserLastInteraction
    );
    // console.log("Deliver Message : ",failedMessage.deliveredAt);
    // console.log("Failed Message : ",failedMessage.failedAt);
  }, []);

  const onDeleteMessage = (messageId: string) => {
    console.log("Delete clicked");
    // document.getElementById(`message_retry_menu_${messageId}`)?.click();

    // Set Message isActive to False
    // MessageAction.updateIsActiveMessage(messageId);
  };

  // Retry Message
  const resendMessage = (toRetryMessage: Message) => {
    // console.log("Resend clicked");
    document
      .getElementById(`message_retry_menu_${toRetryMessage.id}`)
      ?.removeAttribute("data-kt-menu-trigger");

    // Re-Sending Message Start
    if (resendButtonRef) {
      resendButtonRef.current?.setAttribute("data-kt-indicator", "on");
    }

    // Re-Sending Process
    const onFetchAccountFinished = async (accountChat: Account | undefined) => {
      if (accountChat) {
        const client = lc.getItemLC(lc.LCName.Client);
        const clientID: string = client.id;
        const failedMessageToResend = { ...failedMessage };
        failedMessageToResend.client = createRef("clients", clientID);
        await Chat.createCollaborationMessage(
          failedMessageToResend,
          companyID,
          failedCollaborationId,
          accountChat,
          failedCustomer,
          (respMessage: Message, responseJson: string) => {
            //Callback message send
            // callback(respMessage, responseJson);
          },
          true
        ).catch((error) => {
          Log.errorWithNavigate(
            nav,
            "Failed to Create Collaboration message : " + failedCollaborationId, error
          );
        });
      } else {
        //severe
        console.log("No Data Account Chat");
      }
    };

    actAccount
      .fetchAccountByCompanyAndChannel(companyID, toRetryMessage.channel)
      .then(async (response) => {
        const temAccount = response.find((obj) => {
          return obj;
        });
        onFetchAccountFinished(temAccount);
      })
      .catch((error) => {
        Log.errorWithNavigate(
          nav,
          `Failed to Fetch Account Chat by Company And Channel : ${failedCollaborationId}`, error
        );
      });

    // Re-Sending Message Finished
    if (resendButtonRef) {
      resendButtonRef.current?.removeAttribute("data-kt-indicator");
      document
        .getElementById(`message_retry_menu_${toRetryMessage.id}`)
        ?.setAttribute("data-kt-menu-trigger", "click");
      document
        .getElementById(`message_retry_menu_${toRetryMessage.id}`)
        ?.click();
    }
  };

  //if created at is 24 hour from now return true
  const is24Hour = () => {
    if (!failedUserLastInteraction) return true;
    const now = firebase.firestore.Timestamp.now();
    const diff = now.seconds - failedUserLastInteraction.seconds;
    if (diff > 86400) {
      return true;
    }
    return false;
  };

  // if failedMessage.errorCode === 131047 then return true
  const is131047 = () => {
    if (failedMessage.errorCode) {
      if (failedMessage.errorCode.toString() === "131047") {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={clsx("retry-message")}>
      <div id={`message_retry_menu_${failedMessage.id}`}>
        <div
          id={`message_retry_menu_toggle_${failedMessage.id}`}
          className={clsx(
            "bi bi-exclamation-circle-fill cursor-pointer text-danger",
            isTabletOrMobile ? "fs-3" : "fs-2"
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        ></div>
        <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-auto"
          data-kt-menu="true"
        >
          {/* resend button */}
          {/* if created at is 24 hour from now */}

          {/* {failedMessage.createdAt} */}
          {(failedMessage.channel === "whatsapp" && is24Hour()) ||
          failedMessage.resultCode === "SM11" ? (
            <div
              id={`message_info_menu_${failedMessage.id}`}
              data-kt-menu="true"
              className="mb-2"
              // onMouseLeave={() => document.getElementById(`message_menu_${failedMessage.id}`)?.click()}
              // style={{inset: `${document.getElementById(`message_${failedMessage.id}`)?.clientHeight}px 0px auto auto`}}
            >
              <div className="menu-title message-menu-title d-flex flex-row">
                <div
                  className="bi bi-x-lg cursor-pointer"
                  data-kt-menu-dismiss="true"
                ></div>
                <div className="w-100 ps-2 pe-1">
                  {t("MI.Title.MessageInfo")}
                </div>
              </div>
              <div
                className="menu-item message-menu-item d-flex flex-row"
                style={{ maxWidth: "250px" }}
              >
                <div className="bi bi-exclamation-circle-fill cursor-pointer text-danger fs-3"></div>
                <div className="ps-2 pe-1">
                  {t("HC.WarningAlert.Cannot ResentInfo")}
                </div>
              </div>
            </div>
          ) : failedMessage.resultCode === "SM10" ? (
            <div
              id={`message_info_menu_${failedMessage.id}`}
              data-kt-menu="true"
              className="mb-2"
              // onMouseLeave={() => document.getElementById(`message_menu_${failedMessage.id}`)?.click()}
              // style={{inset: `${document.getElementById(`message_${failedMessage.id}`)?.clientHeight}px 0px auto auto`}}
            >
              <div className="menu-title message-menu-title d-flex flex-row">
                <div
                  className="bi bi-x-lg cursor-pointer"
                  data-kt-menu-dismiss="true"
                ></div>
                <div className="w-100 ps-2 pe-1">
                  {t("MI.Title.MessageInfo")}
                </div>
              </div>
              <div
                className="menu-item message-menu-item d-flex flex-row"
                style={{ maxWidth: "250px" }}
              >
                <div className="bi bi-exclamation-circle-fill cursor-pointer text-danger fs-3"></div>
                <div className="ps-2 pe-1">
                  {t("HC.WarningAlert.Cannot BalanceInfo")}
                </div>
              </div>
            </div>
          ) : failedMessage.channel === "tokopedia" ||
            failedMessage.channel === "shopee" ||
            failedMessage.channel === "lazada" ? (
            <div
              id={`message_info_menu_${failedMessage.id}`}
              data-kt-menu="true"
              className="mb-2"
              // onMouseLeave={() => document.getElementById(`message_menu_${failedMessage.id}`)?.click()}
              // style={{inset: `${document.getElementById(`message_${failedMessage.id}`)?.clientHeight}px 0px auto auto`}}
            >
              <div className="menu-title message-menu-title d-flex flex-row">
                <div
                  className="bi bi-x-lg cursor-pointer"
                  data-kt-menu-dismiss="true"
                ></div>
                <div className="w-100 ps-2 pe-1">
                  {t("MI.Title.MessageInfo")}
                </div>
              </div>
              <div
                className="menu-item message-menu-item d-flex flex-row"
                style={{ maxWidth: "250px" }}
              >
                <div className="bi bi-exclamation-circle-fill cursor-pointer text-danger fs-3"></div>
                <div className="ps-2 pe-1">
                  {failedMessage?.errorDetail &&
                  failedMessage?.errorDetail?.error?.message
                    ? failedMessage.errorDetail.error.message.replaceAll(
                        "_",
                        " "
                      )
                    : null}
                </div>
              </div>
              {/* <div className="menu-item message-menu-item d-flex flex-row p-0">
                <button
                  ref={resendButtonRef}
                  className="btn btn-icon-danger btn-text-primary message-menu-item-button"
                  style={{
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  onClick={() => resendMessage(failedMessage)}
                >
                  <span className="bi bi-send pe-1" />
                  <span className="indicator-label">{t("Retry.Info.Resend")}</span>
                  <span className="indicator-progress">
                    Sending... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div> */}
            </div>
          ) : (
            <div className="menu-item message-menu-item d-flex flex-row p-0">
              <button
                ref={resendButtonRef}
                className="btn btn-icon-danger btn-text-primary message-menu-item-button"
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
                onClick={() => resendMessage(failedMessage)}
              >
                <span className="bi bi-send pe-1" />
                <span className="indicator-label">
                  {t("Retry.Info.Resend")}
                </span>
                <span className="indicator-progress">
                  Sending...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          )}
          <hr />
          {/* delete button */}
          <div className="menu-item message-menu-item d-flex flex-row p-0">
            <button
              ref={deleteButtonRef}
              className="btn btn-icon-danger btn-text-danger message-menu-item-button"
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              onClick={() => onDeleteMessage(failedMessage.id!)}
            >
              <span className="bi bi-trash pe-1" />
              <span>{t("Retry.Info.Delete")}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatFailedMenu;
