/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Sentry from "@sentry/react";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import * as chatAct from "../../../../actions/chat";
import * as userAct from "../../../../actions/user";
import * as api from "../../../../api";
import { getEmailFromPhone } from "../../../../api/server/users";
import auth from "../../../../auth/firebase";
import db, { Timestamp, createRef, getUserByID } from "../../../../db";
import { saveUserSessionToken } from "../../../../db/session";
import { RootState } from "../../../../setup/redux/store";
import { useTheme } from "../../../../setup/theme/useTheme";
import Button from "../../../../styles/components/Button";
import TextInput from "../../../../styles/components/TextInput";
import {
  getCIQr,
  getCIQs,
} from "../../../layout/ciq-management/ciq-list/core/_requests";
import * as chat from "../../../modules/chat/redux/ChatSlice";
import * as ciqRedux from "../../../modules/ciq/redux/CIQSlice";
import * as lc from "../../localstorage/index";
import { phoneValidator } from "../../validators/InputValidator";
import { setAuth, setAuthUser } from "../redux/AuthSlice";
import * as RegisterRedux from "../redux/RegisterSlice";
import { Collaboration } from "../../../models/Chat";
import { toast } from "react-toastify";
import { resendEmail } from "../../../../api/server/registration";
import moment from "moment";
import { arrayUnion } from "firebase/firestore";

const loginSchema = Yup.object().shape({
  email: Yup.string().required("Login.Error.EmptyEmail"),
  password: Yup.string().required("Login.Error.EmptyPassword"),
});

const initialValues = {
  email: "",
  password: "",
  isrememberme: false,
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

// if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
//   auth.useEmulator("http://192.168.20.20:9099");
// }

export function Login() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const currentTheme = useTheme();

  let collablist = useSelector((state: RootState) => state.Chat.chatList);
  let allCollablist = useSelector(
    (state: RootState) => state.Chat.allChatChatList
  );
  let userCollablist = useSelector(
    (state: RootState) => state.Chat.userChatList
  );
  let listCIQ = useSelector((state: RootState) => state.CIQ.listCIQ);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        const formEmail = getEmailFromInput(
          values.email.trim(),
          (email, error) => {
            //TODO change login logic into firebase login logic
            if (!email) {
              setLoading(false);
              setSubmitting(false);
              setStatus(`${t("Login.Notif.IncorrectUser")}`);
              return;
            }
            console.log(
              `email : ${email} - rememberme : ${values.isrememberme}`
            );
            login(email, values.password, values.isrememberme)
              .then(async (response) => {
                if (!response) {
                  setLoading(false);
                  setSubmitting(false);
                  setStatus(`${t("Login.Notif.IncorrectUser")}`);
                  return;
                }

                setLoading(false);
                const currentuser = auth.currentUser;
                console.log(currentuser, "curruser");
                if (currentuser != null) {
                  // current user exists
                  currentuser.getIdToken().then(async (response) => {
                    if (!response) return;
                    const client = lc.getItemLC(lc.LCName.Client);
                    let data = await getUserByID(currentuser.uid);
                    console.log(`Client : ${client}`);
                    console.log(data, "data user");
                    if (!client || !client.id || client.id === "") {
                      console.warn("failed login, no client found in user");
                      api.logout().then(() => {
                        dispatch(setAuth(false));
                        lc.removeSession();
                      });
                      setSubmitting(false);
                      setStatus(`${t("Login.Error.InvalidAccount")}`);
                      if (data) {
                        console.log(`To Client Info or Email Verify`);
                        dispatch(
                          RegisterRedux.setUserFullname(
                            String(currentuser.displayName)
                          )
                        );
                        dispatch(
                          RegisterRedux.setUserEmail(String(currentuser.email))
                        );
                        dispatch(RegisterRedux.setUserID(currentuser.uid));
                        dispatch(
                          RegisterRedux.setUserPassword(values.password)
                        );
                        //if not verify to verify page
                        if (!data.verifyAt && data?.verifyExpiredAt) {
                          let timestampToDate = new Date(
                            data.verifyExpiredAt?.seconds * 1000 +
                              data.verifyExpiredAt?.nanoseconds / 1000000
                          );
                          let dateFormatted = moment(
                            new Date(timestampToDate)
                          ).format("DD MMMM YYYY, HH:mm:sss");
                          dispatch(
                            RegisterRedux.setVerifyExpired(dateFormatted)
                          );
                          //if expired auto resend verify email
                          if (
                            data.verifyExpiredAt &&
                            data.verifyExpiredAt < Timestamp.now()
                          ) {
                            await resendEmail(
                              currentuser.email!,
                              String(currentuser?.uid),
                              currentuser.displayName!,
                              async (responseCode: any, response: any) => {
                                console.log(response, "ini response resend");
                                if (response) {
                                  dispatch(
                                    RegisterRedux.setVerifyExpired(
                                      response?.dateFormatted
                                    )
                                  );
                                }
                              }
                            );
                          }
                          nav("/auth/email-verification");
                        } else {
                          //if verify to client info
                          nav("/auth/client-info");
                        }
                      }
                      return;
                    }
                    saveUserSessionToken(currentuser.uid, response);
                    dispatch(setAuth(true));
                    dispatch(setAuthUser(JSON.stringify(auth.currentUser)));

                    //"Login Get All Collaboration"
                    const user = {
                      company: lc.getItemLC(lc.LCName.CompanyID),
                      companies: lc.getItemLC(lc.LCName.CompanyList),
                      uid: currentuser.uid,
                      clientid: lc.getItemLC(lc.LCName.Client).id,
                    };
                    // if (!collablist || collablist.length <= 0) {
                    //   chatAct
                    //     .fetchCollaborations(user.uid, user.clientid)
                    //     .then((collabs) => {
                    //       dispatch(chat.setChatList(collabs));
                    //     });
                    // }
                    // if (!allCollablist || allCollablist.length <= 0) {
                    //   const companyRefs = userAct.getAccessibleCompanyRef();
                    //   chatAct
                    //     .fetchCollaborationsByAllCompany(companyRefs, user.uid)
                    //     .then((collabs) =>
                    //       dispatch(chat.setAllChatChatList(collabs))
                    //     );
                    // }
                    if (!userCollablist || userCollablist.length <= 0) {
                      chatAct
                        .fetchFriendCollaborations(user.uid)
                        .then((collabs) =>
                          dispatch(chat.setUserChatList(collabs))
                        );
                    }
                    // fetch Customer In Queue (CIQ)
                    // if (!listCIQ || listCIQ.length <= 0) {
                    //   getCIQs().then((ciqs: any) => {
                    //     console.log(ciqs, "ciqs ini amx");
                    //     dispatch(ciqRedux.setListCIQ(ciqs));
                    //   });
                    // }
                    // update toUserOnline rooms
                    const handleUserRef = createRef("users", currentuser.uid);
                    const querySnapshot = await db
                      .collectionGroup("rooms")
                      .where("toUser", "array-contains", handleUserRef)
                      .get();
                    querySnapshot.forEach(async (doc) => {
                      const roomData = doc.data();
                      if (roomData) {
                        await doc.ref.update({
                          toUserOnline: arrayUnion(handleUserRef),
                        });
                      }
                    });
                  });
                }
                console.log("success login");
                console.log(response);
                Sentry.setContext("User", {
                  name: formEmail,
                  // session: "test-session",
                });
                //call function to create session

                // nav("/dashboard");
              })
              .catch(() => {
                setLoading(false);
                setSubmitting(false);
                setStatus(`${t("Login.Notif.IncorrectUser")}`);
              });
          }
        );
      }, 1000);
    },
  });

  function getEmailFromInput(
    input: string,
    callback: (email: string | null, error: string | null) => void
  ) {
    const isEmail = input.match(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (isEmail) {
      callback(input, null);
      return Promise.resolve(input);
    }

    const email = getEmailFromPhoneNumber(
      input.replaceAll(/[^0-9]/gi, ""),
      callback
    );
    return email;
  }

  //dummy function, should be replaced with actual get from firebase
  function getEmailFromPhoneNumber(
    phone: string,
    callback: (email: string | null, error: string | null) => void
  ) {
    if (phone.startsWith("0")) {
      phone = "62" + phone.substring(1);
    }

    if (phoneValidator(phone)) {
      return getEmailFromPhone(phone)
        .then((email) => {
          callback(email, null);
          return email;
        })
        .catch(() => {
          callback(null, "");
          return "";
        });
    }

    callback(null, "");
    return "";
  }

  //dummy function, should be replaced with actual login from firebase
  function login(
    email: string,
    password: string,
    isRememberme: true | false
  ): Promise<string> {
    console.log(`login with email ${email} and password ${password}`);
    return api.login(email, password, isRememberme);
  }

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">
          {t("Login.Info.SignIn").replace(
            "<name>",
            currentTheme.loginSignInName
          )}
        </h1>
        <div className="text-gray-400 fw-bold fs-4">
          {t("Login.Info.Registration")}
          <Link to="/auth/registration" className="link-primary fw-bolder">
            {t("Login.Link.Registration")}
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger" data-testid="errorLogin">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">
          {t("Login.Input.User")}
        </label>
        <TextInput
          data-testid="email"
          placeholder=""
          {...formik.getFieldProps("email")}
          formcontrol={"solid"}
          name="email"
          autoComplete="off"
          id="login-email"
        />
        {formik.touched.email && formik.errors.email && (
          <div
            data-testid="emailerror"
            className="fv-plugins-message-container text-danger"
          >
            <span role="alert">{t(`${formik.errors.email}`)}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              {t("Login.Input.Password")}
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              {t("Login.Link.ForgotPassword")}
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <div className="input-group mb-3">
          <TextInput
            data-testid="password"
            type={passwordShown ? "text" : "password"}
            autoComplete="off"
            {...formik.getFieldProps("password")}
            formcontrol={"solid"}
            id="login-password"
          />
          <i
            onClick={togglePassword}
            className={`input-group-text border-0 bi ${
              passwordShown ? "bi-eye" : "bi-eye-slash"
            }`}
          ></i>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div
            data-testid="passworderror"
            className="fv-plugins-message-container text-danger"
          >
            <div>
              <span role="alert">{t(`${formik.errors.password}`)}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* start::Remember me checkbox group */}
      <div className="fv-row w-100 d-flex justify-content-between mb-10 mt-n5">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="checkbox"
            // value=""
            id="login-remember"
            {...formik.getFieldProps("isrememberme")}
          />
          <label className="form-check-label" htmlFor="login-remember">
            {t("Login.Checkbox.RememberMe")}
          </label>
        </div>
        <Link
          to="/auth/forgot-password"
          className="link-primary fs-6 fw-bolder"
          style={{ marginLeft: "5px" }}
        >
          {t("Login.Link.ForgotPassword")}
        </Link>
      </div>
      {/* end::Remember me checkbox group */}

      {/* begin::Action */}
      <div className="text-center">
        <Button
          data-testid="btnLogin"
          id="login-button"
          btnlg="primary"
          type="submit"
          disabled={formik.isSubmitting || !formik.isValid}
          cName="w-100 mb-5 gradient-animation"
        >
          {!loading && (
            <span className="indicator-label">{t("Login.Button.Login")}</span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {t("Login.Button.Loading")}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </div>
      {/* end::Action */}
    </form>
  );
}
