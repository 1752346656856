import * as Sentry from "@sentry/browser"; 
import { NavigateFunction } from "react-router-dom";
// import React from "react";

/**
 * Seven Days Logger Error Type
 */
const SDLOGGER_FATAL = "fatal";
const SDLOGGER_ERROR = "error";
const SDLOGGER_WARNING = "warning";
const SDLOGGER_LOG = "log";
const SDLOGGER_INFO = "info";
const SDLOGGER_DEBUG = "debug";

const logLevel = process.env.REACT_APP_LOG_LEVEL ?? SDLOGGER_INFO

const isLog = (level: string) => {
  if (level === "debug")
    return logLevel === SDLOGGER_DEBUG
  
  if (level === "info")
    return logLevel === SDLOGGER_INFO || logLevel === SDLOGGER_LOG || logLevel === SDLOGGER_DEBUG

  if (level === "warning")
    return logLevel === SDLOGGER_WARNING ||
      logLevel === SDLOGGER_INFO || logLevel === SDLOGGER_LOG || logLevel === SDLOGGER_DEBUG

  if (level === "error")
    return logLevel === SDLOGGER_ERROR ||
      logLevel === SDLOGGER_WARNING ||
      logLevel === SDLOGGER_INFO || logLevel === SDLOGGER_LOG || logLevel === SDLOGGER_DEBUG

  if (level === "fatal")
    return logLevel === SDLOGGER_FATAL ||
      logLevel === SDLOGGER_ERROR ||
      logLevel === SDLOGGER_WARNING ||
      logLevel === SDLOGGER_INFO || logLevel === SDLOGGER_LOG || logLevel === SDLOGGER_DEBUG

  return false;
}

export const debug = (...messages: any[]) => {

  const loggable = isLog("debug")
  const msg = messages?.join(" ")

  if (loggable) console.info(`[DEBUG] ${msg}`)
}

export const info = (...messages: any[]) => {

  const loggable = isLog("info")
  const msg = messages?.join(" ")

  if (loggable) console.info(`[INFO] ${msg}`)
}

export const warning = (...messages: any[]) => {

  const loggable = isLog("warning")
  const msg = messages?.join(" ")

  if (loggable) console.info(`[WARNING] ${msg}`)
}

// export const error = (...messages: any[]) => {

//   const loggable = isLog("error")
//   const msg = messages?.join(" ")

//   if (loggable) console.error(`[ERROR] ${msg}`)
// }


export const error = (...messages: any[]) => {

  const loggable = isLog("error")
  const msg = messages?.join(" ")

  if (loggable) console.error(`[ERROR] ${msg}`)
  Sentry.withScope(scope => {
    scope.setLevel(Sentry.Severity.Error);
    Sentry.captureMessage(messages[0])
  })  
}

/**
 * Navigate to 500 on error
 * @param navigate 
 * @param title 
 * @param messages 
 */

export const errorWithNavigate = (navigate: NavigateFunction, ...messages: any[]) => {

  const loggable = isLog("error")
  const msg = messages?.join(" ")

  if (loggable) console.error(`[ERROR] ${msg}`)
  Sentry.withScope(scope => {
    scope.setLevel(Sentry.Severity.Error);
    Sentry.captureMessage(messages[0])
  })  

  if (navigate){
    navigate("/error/500")
  }
}

export const fatal = (message: string, error?: any) => {
  const loggable = isLog("fatal")

  if (loggable) console.error(`[FATAL] ${message} ${error ?? 'no error exception'}`)
  if (error) {
    Sentry.captureException(error);
  } else {
    Sentry.captureMessage(message, Sentry.Severity.Fatal);
  }
  // error && Sentry.captureException(error);

}