import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageLink, PageTitle } from "../../core";
import { useMediaQuery } from "react-responsive";
import { ActionsCell } from "./components/ActionsCell";
import ListComponent from "src/app/components/list/NewList";
import "src/styles/css/product.css";

import ValuePack from "src/app/models/Prepacks";
import ScanLocatorModal from "./modal/ScanLocatorModal";
import ShippingMarkModal from "./modal/ShippingMarkModal";
import OrderIDModal from "./modal/OrderIDModal";
import { format } from "date-fns";
import Order from "src/app/models/Order";
import ShippingLimitLabel from "../shiporder-export/order-list/components/ShippingLimitLabel";

type Columns<T> = {
  title: string;
  field: keyof T;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

type Action<T> = {
  headerTitle: string;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

/**
 * Table Value Pack That Showing Product Name, Product SKU, Order Quantity
 *
 * @returns {*}
 */
const ValuePackPage = () => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });

  const [isScanLocatorModalOpen, setIsScanLocatorModalOpen] =
    useState<boolean>(false);
  const [isShippingMarkModalOpen, setIsShippingMarkModalOpen] =
    useState<boolean>(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
  const [order, setOrder] = useState<String[]>([]);

  /**
   * Breadcrumbs for Collection Prepack
   *
   * @type {Array<PageLink>}
   */
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Value.Pack.Breadcrumb"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  /**
   * Default Column For Collection Prepack
   * Displaying Product Name, Product SKU, Order Quantity
   * @type {Columns<ValuePack>[]}
   */
  const defaultColumns: Columns<Order>[] = [
    {
      field: ("itemList[0]?.itemName") as any,
      title: t("Value.Pack.ProductName"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder"
            }
          >
            {props.row?.original?.itemList[0]?.itemName}
          </div>
        );
      },
    },
    {
      field: ("itemList[0]?.itemSKU") as any,
      title: t("Value.Pack.ProductSKU"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder"
            }
          >
            {props.row?.original?.itemList[0]?.itemSKU}
          </div>
        );
      },
    },
    {
      field: "ordersn",
      title: t("OrderList.OrderNumber"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder"
            }
          >
            {props.row?.original?.ordersn}
          </div>
        );
      },
    },
    // {
    //   field: "itemCount",
    //   title: t("Value.Pack.TotalOrder"),
    //   value: (props: any) => {
    //     return (
    //       <div
    //         className={
    //           isMobileView
    //             ? "m-0 p-0 d-flex justify-content-end text-center"
    //             : "m-0 p-0 h-auto d-flex align-items-center  text-centerjustify-content-start fw-bolder"
    //         }
    //       >
    //         <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
    //           <p style={{ margin: 0 }}>
    //             {props.row?.original?.orderIds?.length}
    //           </p>
    //           <button
    //             className="btn btn-transparent"
    //             style={{ padding: 0, verticalAlign: "middle" }}
    //             onClick={() =>
    //               handleShowOrderModal(props.row?.original?.orderIds)
    //             }
    //           >
    //             <i className="bi bi-eye fs-3" />
    //           </button>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "shipByDate",
      title: t("OrderList.ShippingLimit"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                : "m-0 p-0 d-flex justify-content-start fw-bolder"
            }
          >
            <ShippingLimitLabel shippingTimestamp={props.value} />
          </div>
        );
      },
    },
  ];

  /**
   * Buttons For Reprint Label
   *
   * @type {Action<ValuePack>}
   */
  const defaultAction: Action<ValuePack> = {
    headerTitle: t("Common.Action"),
    value: (props: any) => {
      return (
        <div
          className={
            isMobileView
              ? "m-0 p-0 d-flex justify-content-end fw-bolder"
              : "m-0 p-0 d-flex justify-content-start fw-bolder"
          }
        >
          <ActionsCell data={props.row?.original} />
        </div>
      );
    },
  };

  const handleShowOrderModal = (orderId: String[]) => {
    setOrder(orderId);
    setIsOrderModalOpen(true);
  };

  useEffect(() => {
    window.document.getElementById("button-refetch")?.click();
  }, [isScanLocatorModalOpen]);

  return (
    <>
      <PageTitle
        breadcrumbs={usersBreadcrumbs}
        data-testid="value-pack-page-title"
      >
        {t("Value.Pack.Title")}
      </PageTitle>
      <ListComponent<Order>
        collection="orders"
        collectionGroup={false}
        columns={defaultColumns}
        selection={{
          headerSelection: false,
          rowSelection: false,
        }}
        defaultFilters={[
          {
            label: "isPrepack",
            field: "isPrepack",
            type: "boolean",
            value: true,
          },
          {
            label: "printedLabel",
            field: "printedLabel",
            type: "null",
          },
          {
            label: "status",
            field: "status",
            type: "text",
            value: "READY_TO_SHIP",
          }
        ]}
        defaultSorting={[
          {
            field: "shipByDate",
            label: "shipByDate",
            direction: "asc",
          },
        ]}
        useFilter={[]}
        searching={{
          field: "ordersn",
          useFirestore: true,
          value: "",
          caseSensitive: false,
        }}
        extra={[
          {
            label: "scan_locator",
            value: (props: any) => {
              return (
                <>
                  <button
                    type="button"
                    data-testid="btnScanLocator"
                    className="btn btn-primary"
                    onClick={() => setIsScanLocatorModalOpen(true)}
                  >
                    {t("Value.Pack.Scan.Locator")}
                  </button>
                </>
              );
            },
          },
          {
            label: "create_shipping",
            value: (props: any) => {
              return (
                <>
                  <button
                    type="button"
                    data-testid="btnCreateShipping"
                    className="btn btn-light-primary"
                    onClick={() => setIsShippingMarkModalOpen(true)}
                  >
                    {t("Shipping.Handover.Mark.Add")}
                  </button>
                </>
              );
            },
          },
        ]}
        action={defaultAction}
      />

      {isScanLocatorModalOpen && (
        <ScanLocatorModal onClose={() => setIsScanLocatorModalOpen(false)} />
      )}
      {isShippingMarkModalOpen && (
        <ShippingMarkModal onClose={() => setIsShippingMarkModalOpen(false)} />
      )}
      {order && isOrderModalOpen && (
        <OrderIDModal
          orderIDs={order}
          onClose={() => setIsOrderModalOpen(false)}
        />
      )}
    </>
  );
};

export default ValuePackPage;
